import React from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react"
import ReactGA from "react-ga"

import appState from './CR-Components/CoreFiles/state';
import { getObjectKeyPath } from './CR-Components/utils.js'
import App from "./component/App/App.jsx";
import {scene} from "./scene";

//Google analytics for react
ReactGA.initialize("UA-185958838-1")

window.scene = scene;

const getArchetypeHierarchy = async () => {
    const response = await jQuery.ajax({
        url: `${crConstants.acrobaseHost}/v1/archetype_hierarchy`,
    });
    return response
}
getArchetypeHierarchy().then((x) => appState.archetypePaths = getObjectKeyPath(x, "name"))


@observer
class StateObserver extends React.Component {
    render() {
        return (
            <App appState={this.props.appState} />
        )
    }
}

ReactDOM.render(<StateObserver appState={appState} />, document.getElementById("react-root"));
