import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { scene } from "../../scene";

export const ToolbarAreaOptionsParameter = ({ callback, id, options, title }) => {
    const classes = classNames(
        "toolbar__area-options-parameter",
        `toolbar__area-options-parameter--${id}`,
        "cesium-button"
    );

    /**
     * Updates the scene based on parameter change.
     *
     * @param {*} param0 Change event
     */
    function onSelectChange({ target: { value }}) {
        scene.onCameraMoved();

        if (callback) {
            callback(value);
        }
    }

    return <select id={`parameter-${id}`} className={classes} onChange={onSelectChange} title={title}>
        {options.map((option, index) => (
            <option
                key={index}
                value={option.value}
            >{option.text}</option>
        ))}
    </select>;
}

ToolbarAreaOptionsParameter.propTypes = {
    callback: PropTypes.func,
    id: PropTypes.string,
    options: PropTypes.array,
    title: PropTypes.string,
};