export const scenario45Adjustment = {
    1990: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1991: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1992: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1993: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1994: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1995: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1996: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1997: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1998: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1999: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2000: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2001: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2002: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2003: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2004: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2005: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2006: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2007: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2008: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2009: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2010: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 0.99917,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2011: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 0.99804,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2012: {
        "Riverine Flooding_factor": 0.99994,
        "Coastal Inundation_factor": 0.99556,
        "Extreme Heat_factor": 0.99994,
        "Forest Fire_factor": 0.99994,
        "Extreme Wind_factor": 0.99994,
        "Soil Subsidence_factor": 0.99994,
        "Freeze-Thaw_factor": 0.99994,
        "Surface Water Flooding_factor": 0.99994,
        "Precipitation_factor": 0.99994,
        "Drought_factor": 0.99994,
        "Cyclone Wind_factor": 0.99994
    },
    2013: {
        "Riverine Flooding_factor": 0.99974,
        "Coastal Inundation_factor": 0.99221,
        "Extreme Heat_factor": 0.99974,
        "Forest Fire_factor": 0.99974,
        "Extreme Wind_factor": 0.99974,
        "Soil Subsidence_factor": 0.99974,
        "Freeze-Thaw_factor": 0.99974,
        "Surface Water Flooding_factor": 0.99974,
        "Precipitation_factor": 0.99974,
        "Drought_factor": 0.99974,
        "Cyclone Wind_factor": 0.99974
    },
    2014: {
        "Riverine Flooding_factor": 0.99937,
        "Coastal Inundation_factor": 0.98847,
        "Extreme Heat_factor": 0.99937,
        "Forest Fire_factor": 0.99937,
        "Extreme Wind_factor": 0.99937,
        "Soil Subsidence_factor": 0.99937,
        "Freeze-Thaw_factor": 0.99937,
        "Surface Water Flooding_factor": 0.99937,
        "Precipitation_factor": 0.99937,
        "Drought_factor": 0.99937,
        "Cyclone Wind_factor": 0.99937
    },
    2015: {
        "Riverine Flooding_factor": 0.99877,
        "Coastal Inundation_factor": 0.98348,
        "Extreme Heat_factor": 0.99877,
        "Forest Fire_factor": 0.99877,
        "Extreme Wind_factor": 0.99877,
        "Soil Subsidence_factor": 0.99877,
        "Freeze-Thaw_factor": 0.99877,
        "Surface Water Flooding_factor": 0.99877,
        "Precipitation_factor": 0.99877,
        "Drought_factor": 0.99877,
        "Cyclone Wind_factor": 0.99877
    },
    2016: {
        "Riverine Flooding_factor": 0.99789,
        "Coastal Inundation_factor": 0.97661,
        "Extreme Heat_factor": 0.99789,
        "Forest Fire_factor": 0.99789,
        "Extreme Wind_factor": 0.99789,
        "Soil Subsidence_factor": 0.99789,
        "Freeze-Thaw_factor": 0.99789,
        "Surface Water Flooding_factor": 0.99789,
        "Precipitation_factor": 0.99789,
        "Drought_factor": 0.99789,
        "Cyclone Wind_factor": 0.99789
    },
    2017: {
        "Riverine Flooding_factor": 0.99668,
        "Coastal Inundation_factor": 0.96826,
        "Extreme Heat_factor": 0.99668,
        "Forest Fire_factor": 0.99668,
        "Extreme Wind_factor": 0.99668,
        "Soil Subsidence_factor": 0.99668,
        "Freeze-Thaw_factor": 0.99668,
        "Surface Water Flooding_factor": 0.99668,
        "Precipitation_factor": 0.99668,
        "Drought_factor": 0.99668,
        "Cyclone Wind_factor": 0.99668
    },
    2018: {
        "Riverine Flooding_factor": 0.99509,
        "Coastal Inundation_factor": 0.95789,
        "Extreme Heat_factor": 0.99509,
        "Forest Fire_factor": 0.99509,
        "Extreme Wind_factor": 0.99509,
        "Soil Subsidence_factor": 0.99509,
        "Freeze-Thaw_factor": 0.99509,
        "Surface Water Flooding_factor": 0.99509,
        "Precipitation_factor": 0.99509,
        "Drought_factor": 0.99509,
        "Cyclone Wind_factor": 0.99509
    },
    2019: {
        "Riverine Flooding_factor": 0.99308,
        "Coastal Inundation_factor": 0.9459,
        "Extreme Heat_factor": 0.99308,
        "Forest Fire_factor": 0.99308,
        "Extreme Wind_factor": 0.99308,
        "Soil Subsidence_factor": 0.99308,
        "Freeze-Thaw_factor": 0.99308,
        "Surface Water Flooding_factor": 0.99308,
        "Precipitation_factor": 0.99308,
        "Drought_factor": 0.99308,
        "Cyclone Wind_factor": 0.99308
    },
    2020: {
        "Riverine Flooding_factor": 0.99061,
        "Coastal Inundation_factor": 0.93348,
        "Extreme Heat_factor": 0.99061,
        "Forest Fire_factor": 0.99061,
        "Extreme Wind_factor": 0.99061,
        "Soil Subsidence_factor": 0.99061,
        "Freeze-Thaw_factor": 0.99061,
        "Surface Water Flooding_factor": 0.99061,
        "Precipitation_factor": 0.99061,
        "Drought_factor": 0.99061,
        "Cyclone Wind_factor": 0.99061
    },
    2021: {
        "Riverine Flooding_factor": 0.98765,
        "Coastal Inundation_factor": 0.91995,
        "Extreme Heat_factor": 0.98765,
        "Forest Fire_factor": 0.98765,
        "Extreme Wind_factor": 0.98765,
        "Soil Subsidence_factor": 0.98765,
        "Freeze-Thaw_factor": 0.98765,
        "Surface Water Flooding_factor": 0.98765,
        "Precipitation_factor": 0.98765,
        "Drought_factor": 0.98765,
        "Cyclone Wind_factor": 0.98765
    },
    2022: {
        "Riverine Flooding_factor": 0.9843,
        "Coastal Inundation_factor": 0.90777,
        "Extreme Heat_factor": 0.9843,
        "Forest Fire_factor": 0.9843,
        "Extreme Wind_factor": 0.9843,
        "Soil Subsidence_factor": 0.9843,
        "Freeze-Thaw_factor": 0.9843,
        "Surface Water Flooding_factor": 0.9843,
        "Precipitation_factor": 0.9843,
        "Drought_factor": 0.9843,
        "Cyclone Wind_factor": 0.9843
    },
    2023: {
        "Riverine Flooding_factor": 0.98063,
        "Coastal Inundation_factor": 0.89558,
        "Extreme Heat_factor": 0.98063,
        "Forest Fire_factor": 0.98063,
        "Extreme Wind_factor": 0.98063,
        "Soil Subsidence_factor": 0.98063,
        "Freeze-Thaw_factor": 0.98063,
        "Surface Water Flooding_factor": 0.98063,
        "Precipitation_factor": 0.98063,
        "Drought_factor": 0.98063,
        "Cyclone Wind_factor": 0.98063
    },
    2024: {
        "Riverine Flooding_factor": 0.97668,
        "Coastal Inundation_factor": 0.88207,
        "Extreme Heat_factor": 0.97668,
        "Forest Fire_factor": 0.97668,
        "Extreme Wind_factor": 0.97668,
        "Soil Subsidence_factor": 0.97668,
        "Freeze-Thaw_factor": 0.97668,
        "Surface Water Flooding_factor": 0.97668,
        "Precipitation_factor": 0.97668,
        "Drought_factor": 0.97668,
        "Cyclone Wind_factor": 0.97668
    },
    2025: {
        "Riverine Flooding_factor": 0.97249,
        "Coastal Inundation_factor": 0.8693,
        "Extreme Heat_factor": 0.97249,
        "Forest Fire_factor": 0.97249,
        "Extreme Wind_factor": 0.97249,
        "Soil Subsidence_factor": 0.97249,
        "Freeze-Thaw_factor": 0.97249,
        "Surface Water Flooding_factor": 0.97249,
        "Precipitation_factor": 0.97249,
        "Drought_factor": 0.97249,
        "Cyclone Wind_factor": 0.97249
    },
    2026: {
        "Riverine Flooding_factor": 0.96812,
        "Coastal Inundation_factor": 0.85824,
        "Extreme Heat_factor": 0.96812,
        "Forest Fire_factor": 0.96812,
        "Extreme Wind_factor": 0.96812,
        "Soil Subsidence_factor": 0.96812,
        "Freeze-Thaw_factor": 0.96812,
        "Surface Water Flooding_factor": 0.96812,
        "Precipitation_factor": 0.96812,
        "Drought_factor": 0.96812,
        "Cyclone Wind_factor": 0.96812
    },
    2027: {
        "Riverine Flooding_factor": 0.96359,
        "Coastal Inundation_factor": 0.84841,
        "Extreme Heat_factor": 0.96359,
        "Forest Fire_factor": 0.96359,
        "Extreme Wind_factor": 0.96359,
        "Soil Subsidence_factor": 0.96359,
        "Freeze-Thaw_factor": 0.96359,
        "Surface Water Flooding_factor": 0.96359,
        "Precipitation_factor": 0.96359,
        "Drought_factor": 0.96359,
        "Cyclone Wind_factor": 0.96359
    },
    2028: {
        "Riverine Flooding_factor": 0.95895,
        "Coastal Inundation_factor": 0.8412,
        "Extreme Heat_factor": 0.95895,
        "Forest Fire_factor": 0.95895,
        "Extreme Wind_factor": 0.95895,
        "Soil Subsidence_factor": 0.95895,
        "Freeze-Thaw_factor": 0.95895,
        "Surface Water Flooding_factor": 0.95895,
        "Precipitation_factor": 0.95895,
        "Drought_factor": 0.95895,
        "Cyclone Wind_factor": 0.95895
    },
    2029: {
        "Riverine Flooding_factor": 0.95426,
        "Coastal Inundation_factor": 0.83502,
        "Extreme Heat_factor": 0.95426,
        "Forest Fire_factor": 0.95426,
        "Extreme Wind_factor": 0.95426,
        "Soil Subsidence_factor": 0.95426,
        "Freeze-Thaw_factor": 0.95426,
        "Surface Water Flooding_factor": 0.95426,
        "Precipitation_factor": 0.95426,
        "Drought_factor": 0.95426,
        "Cyclone Wind_factor": 0.95426
    },
    2030: {
        "Riverine Flooding_factor": 0.94951,
        "Coastal Inundation_factor": 0.83025,
        "Extreme Heat_factor": 0.94951,
        "Forest Fire_factor": 0.94951,
        "Extreme Wind_factor": 0.94951,
        "Soil Subsidence_factor": 0.94951,
        "Freeze-Thaw_factor": 0.94951,
        "Surface Water Flooding_factor": 0.94951,
        "Precipitation_factor": 0.94951,
        "Drought_factor": 0.94951,
        "Cyclone Wind_factor": 0.94951
    },
    2031: {
        "Riverine Flooding_factor": 0.94473,
        "Coastal Inundation_factor": 0.82648,
        "Extreme Heat_factor": 0.94473,
        "Forest Fire_factor": 0.94473,
        "Extreme Wind_factor": 0.94473,
        "Soil Subsidence_factor": 0.94473,
        "Freeze-Thaw_factor": 0.94473,
        "Surface Water Flooding_factor": 0.94473,
        "Precipitation_factor": 0.94473,
        "Drought_factor": 0.94473,
        "Cyclone Wind_factor": 0.94473
    },
    2032: {
        "Riverine Flooding_factor": 0.93986,
        "Coastal Inundation_factor": 0.82222,
        "Extreme Heat_factor": 0.93986,
        "Forest Fire_factor": 0.93986,
        "Extreme Wind_factor": 0.93986,
        "Soil Subsidence_factor": 0.93986,
        "Freeze-Thaw_factor": 0.93986,
        "Surface Water Flooding_factor": 0.93986,
        "Precipitation_factor": 0.93986,
        "Drought_factor": 0.93986,
        "Cyclone Wind_factor": 0.93986
    },
    2033: {
        "Riverine Flooding_factor": 0.93487,
        "Coastal Inundation_factor": 0.81856,
        "Extreme Heat_factor": 0.93487,
        "Forest Fire_factor": 0.93487,
        "Extreme Wind_factor": 0.93487,
        "Soil Subsidence_factor": 0.93487,
        "Freeze-Thaw_factor": 0.93487,
        "Surface Water Flooding_factor": 0.93487,
        "Precipitation_factor": 0.93487,
        "Drought_factor": 0.93487,
        "Cyclone Wind_factor": 0.93487
    },
    2034: {
        "Riverine Flooding_factor": 0.92976,
        "Coastal Inundation_factor": 0.81651,
        "Extreme Heat_factor": 0.92976,
        "Forest Fire_factor": 0.92976,
        "Extreme Wind_factor": 0.92976,
        "Soil Subsidence_factor": 0.92976,
        "Freeze-Thaw_factor": 0.92976,
        "Surface Water Flooding_factor": 0.92976,
        "Precipitation_factor": 0.92976,
        "Drought_factor": 0.92976,
        "Cyclone Wind_factor": 0.92976
    },
    2035: {
        "Riverine Flooding_factor": 0.92452,
        "Coastal Inundation_factor": 0.81482,
        "Extreme Heat_factor": 0.92452,
        "Forest Fire_factor": 0.92452,
        "Extreme Wind_factor": 0.92452,
        "Soil Subsidence_factor": 0.92452,
        "Freeze-Thaw_factor": 0.92452,
        "Surface Water Flooding_factor": 0.92452,
        "Precipitation_factor": 0.92452,
        "Drought_factor": 0.92452,
        "Cyclone Wind_factor": 0.92452
    },
    2036: {
        "Riverine Flooding_factor": 0.91917,
        "Coastal Inundation_factor": 0.81329,
        "Extreme Heat_factor": 0.91917,
        "Forest Fire_factor": 0.91917,
        "Extreme Wind_factor": 0.91917,
        "Soil Subsidence_factor": 0.91917,
        "Freeze-Thaw_factor": 0.91917,
        "Surface Water Flooding_factor": 0.91917,
        "Precipitation_factor": 0.91917,
        "Drought_factor": 0.91917,
        "Cyclone Wind_factor": 0.91917
    },
    2037: {
        "Riverine Flooding_factor": 0.91369,
        "Coastal Inundation_factor": 0.81196,
        "Extreme Heat_factor": 0.91369,
        "Forest Fire_factor": 0.91369,
        "Extreme Wind_factor": 0.91369,
        "Soil Subsidence_factor": 0.91369,
        "Freeze-Thaw_factor": 0.91369,
        "Surface Water Flooding_factor": 0.91369,
        "Precipitation_factor": 0.91369,
        "Drought_factor": 0.91369,
        "Cyclone Wind_factor": 0.91369
    },
    2038: {
        "Riverine Flooding_factor": 0.90808,
        "Coastal Inundation_factor": 0.80949,
        "Extreme Heat_factor": 0.90808,
        "Forest Fire_factor": 0.90808,
        "Extreme Wind_factor": 0.90808,
        "Soil Subsidence_factor": 0.90808,
        "Freeze-Thaw_factor": 0.90808,
        "Surface Water Flooding_factor": 0.90808,
        "Precipitation_factor": 0.90808,
        "Drought_factor": 0.90808,
        "Cyclone Wind_factor": 0.90808
    },
    2039: {
        "Riverine Flooding_factor": 0.90231,
        "Coastal Inundation_factor": 0.80778,
        "Extreme Heat_factor": 0.90231,
        "Forest Fire_factor": 0.90231,
        "Extreme Wind_factor": 0.90231,
        "Soil Subsidence_factor": 0.90231,
        "Freeze-Thaw_factor": 0.90231,
        "Surface Water Flooding_factor": 0.90231,
        "Precipitation_factor": 0.90231,
        "Drought_factor": 0.90231,
        "Cyclone Wind_factor": 0.90231
    },
    2040: {
        "Riverine Flooding_factor": 0.89639,
        "Coastal Inundation_factor": 0.80604,
        "Extreme Heat_factor": 0.89639,
        "Forest Fire_factor": 0.89639,
        "Extreme Wind_factor": 0.89639,
        "Soil Subsidence_factor": 0.89639,
        "Freeze-Thaw_factor": 0.89639,
        "Surface Water Flooding_factor": 0.89639,
        "Precipitation_factor": 0.89639,
        "Drought_factor": 0.89639,
        "Cyclone Wind_factor": 0.89639
    },
    2041: {
        "Riverine Flooding_factor": 0.89034,
        "Coastal Inundation_factor": 0.80494,
        "Extreme Heat_factor": 0.89034,
        "Forest Fire_factor": 0.89034,
        "Extreme Wind_factor": 0.89034,
        "Soil Subsidence_factor": 0.89034,
        "Freeze-Thaw_factor": 0.89034,
        "Surface Water Flooding_factor": 0.89034,
        "Precipitation_factor": 0.89034,
        "Drought_factor": 0.89034,
        "Cyclone Wind_factor": 0.89034
    },
    2042: {
        "Riverine Flooding_factor": 0.88417,
        "Coastal Inundation_factor": 0.80416,
        "Extreme Heat_factor": 0.88417,
        "Forest Fire_factor": 0.88417,
        "Extreme Wind_factor": 0.88417,
        "Soil Subsidence_factor": 0.88417,
        "Freeze-Thaw_factor": 0.88417,
        "Surface Water Flooding_factor": 0.88417,
        "Precipitation_factor": 0.88417,
        "Drought_factor": 0.88417,
        "Cyclone Wind_factor": 0.88417
    },
    2043: {
        "Riverine Flooding_factor": 0.87788,
        "Coastal Inundation_factor": 0.80358,
        "Extreme Heat_factor": 0.87788,
        "Forest Fire_factor": 0.87788,
        "Extreme Wind_factor": 0.87788,
        "Soil Subsidence_factor": 0.87788,
        "Freeze-Thaw_factor": 0.87788,
        "Surface Water Flooding_factor": 0.87788,
        "Precipitation_factor": 0.87788,
        "Drought_factor": 0.87788,
        "Cyclone Wind_factor": 0.87788
    },
    2044: {
        "Riverine Flooding_factor": 0.87148,
        "Coastal Inundation_factor": 0.8031,
        "Extreme Heat_factor": 0.87148,
        "Forest Fire_factor": 0.87148,
        "Extreme Wind_factor": 0.87148,
        "Soil Subsidence_factor": 0.87148,
        "Freeze-Thaw_factor": 0.87148,
        "Surface Water Flooding_factor": 0.87148,
        "Precipitation_factor": 0.87148,
        "Drought_factor": 0.87148,
        "Cyclone Wind_factor": 0.87148
    },
    2045: {
        "Riverine Flooding_factor": 0.86494,
        "Coastal Inundation_factor": 0.80274,
        "Extreme Heat_factor": 0.86494,
        "Forest Fire_factor": 0.86494,
        "Extreme Wind_factor": 0.86494,
        "Soil Subsidence_factor": 0.86494,
        "Freeze-Thaw_factor": 0.86494,
        "Surface Water Flooding_factor": 0.86494,
        "Precipitation_factor": 0.86494,
        "Drought_factor": 0.86494,
        "Cyclone Wind_factor": 0.86494
    },
    2046: {
        "Riverine Flooding_factor": 0.85825,
        "Coastal Inundation_factor": 0.80244,
        "Extreme Heat_factor": 0.85825,
        "Forest Fire_factor": 0.85825,
        "Extreme Wind_factor": 0.85825,
        "Soil Subsidence_factor": 0.85825,
        "Freeze-Thaw_factor": 0.85825,
        "Surface Water Flooding_factor": 0.85825,
        "Precipitation_factor": 0.85825,
        "Drought_factor": 0.85825,
        "Cyclone Wind_factor": 0.85825
    },
    2047: {
        "Riverine Flooding_factor": 0.85142,
        "Coastal Inundation_factor": 0.8019,
        "Extreme Heat_factor": 0.85142,
        "Forest Fire_factor": 0.85142,
        "Extreme Wind_factor": 0.85142,
        "Soil Subsidence_factor": 0.85142,
        "Freeze-Thaw_factor": 0.85142,
        "Surface Water Flooding_factor": 0.85142,
        "Precipitation_factor": 0.85142,
        "Drought_factor": 0.85142,
        "Cyclone Wind_factor": 0.85142
    },
    2048: {
        "Riverine Flooding_factor": 0.84444,
        "Coastal Inundation_factor": 0.80196,
        "Extreme Heat_factor": 0.84444,
        "Forest Fire_factor": 0.84444,
        "Extreme Wind_factor": 0.84444,
        "Soil Subsidence_factor": 0.84444,
        "Freeze-Thaw_factor": 0.84444,
        "Surface Water Flooding_factor": 0.84444,
        "Precipitation_factor": 0.84444,
        "Drought_factor": 0.84444,
        "Cyclone Wind_factor": 0.84444
    },
    2049: {
        "Riverine Flooding_factor": 0.83732,
        "Coastal Inundation_factor": 0.802,
        "Extreme Heat_factor": 0.83732,
        "Forest Fire_factor": 0.83732,
        "Extreme Wind_factor": 0.83732,
        "Soil Subsidence_factor": 0.83732,
        "Freeze-Thaw_factor": 0.83732,
        "Surface Water Flooding_factor": 0.83732,
        "Precipitation_factor": 0.83732,
        "Drought_factor": 0.83732,
        "Cyclone Wind_factor": 0.83732
    },
    2050: {
        "Riverine Flooding_factor": 0.83005,
        "Coastal Inundation_factor": 0.80173,
        "Extreme Heat_factor": 0.83005,
        "Forest Fire_factor": 0.83005,
        "Extreme Wind_factor": 0.83005,
        "Soil Subsidence_factor": 0.83005,
        "Freeze-Thaw_factor": 0.83005,
        "Surface Water Flooding_factor": 0.83005,
        "Precipitation_factor": 0.83005,
        "Drought_factor": 0.83005,
        "Cyclone Wind_factor": 0.83005
    },
    2051: {
        "Riverine Flooding_factor": 0.82265,
        "Coastal Inundation_factor": 0.80112,
        "Extreme Heat_factor": 0.82265,
        "Forest Fire_factor": 0.82265,
        "Extreme Wind_factor": 0.82265,
        "Soil Subsidence_factor": 0.82265,
        "Freeze-Thaw_factor": 0.82265,
        "Surface Water Flooding_factor": 0.82265,
        "Precipitation_factor": 0.82265,
        "Drought_factor": 0.82265,
        "Cyclone Wind_factor": 0.82265
    },
    2052: {
        "Riverine Flooding_factor": 0.8151,
        "Coastal Inundation_factor": 0.80064,
        "Extreme Heat_factor": 0.8151,
        "Forest Fire_factor": 0.8151,
        "Extreme Wind_factor": 0.8151,
        "Soil Subsidence_factor": 0.8151,
        "Freeze-Thaw_factor": 0.8151,
        "Surface Water Flooding_factor": 0.8151,
        "Precipitation_factor": 0.8151,
        "Drought_factor": 0.8151,
        "Cyclone Wind_factor": 0.8151
    },
    2053: {
        "Riverine Flooding_factor": 0.80745,
        "Coastal Inundation_factor": 0.80023,
        "Extreme Heat_factor": 0.80745,
        "Forest Fire_factor": 0.80745,
        "Extreme Wind_factor": 0.80745,
        "Soil Subsidence_factor": 0.80745,
        "Freeze-Thaw_factor": 0.80745,
        "Surface Water Flooding_factor": 0.80745,
        "Precipitation_factor": 0.80745,
        "Drought_factor": 0.80745,
        "Cyclone Wind_factor": 0.80745
    },
    2054: {
        "Riverine Flooding_factor": 0.79964,
        "Coastal Inundation_factor": 0.79987,
        "Extreme Heat_factor": 0.79964,
        "Forest Fire_factor": 0.79964,
        "Extreme Wind_factor": 0.79964,
        "Soil Subsidence_factor": 0.79964,
        "Freeze-Thaw_factor": 0.79964,
        "Surface Water Flooding_factor": 0.79964,
        "Precipitation_factor": 0.79964,
        "Drought_factor": 0.79964,
        "Cyclone Wind_factor": 0.79964
    },
    2055: {
        "Riverine Flooding_factor": 0.79172,
        "Coastal Inundation_factor": 0.79931,
        "Extreme Heat_factor": 0.79172,
        "Forest Fire_factor": 0.79172,
        "Extreme Wind_factor": 0.79172,
        "Soil Subsidence_factor": 0.79172,
        "Freeze-Thaw_factor": 0.79172,
        "Surface Water Flooding_factor": 0.79172,
        "Precipitation_factor": 0.79172,
        "Drought_factor": 0.79172,
        "Cyclone Wind_factor": 0.79172
    },
    2056: {
        "Riverine Flooding_factor": 0.78372,
        "Coastal Inundation_factor": 0.79855,
        "Extreme Heat_factor": 0.78372,
        "Forest Fire_factor": 0.78372,
        "Extreme Wind_factor": 0.78372,
        "Soil Subsidence_factor": 0.78372,
        "Freeze-Thaw_factor": 0.78372,
        "Surface Water Flooding_factor": 0.78372,
        "Precipitation_factor": 0.78372,
        "Drought_factor": 0.78372,
        "Cyclone Wind_factor": 0.78372
    },
    2057: {
        "Riverine Flooding_factor": 0.77564,
        "Coastal Inundation_factor": 0.79776,
        "Extreme Heat_factor": 0.77564,
        "Forest Fire_factor": 0.77564,
        "Extreme Wind_factor": 0.77564,
        "Soil Subsidence_factor": 0.77564,
        "Freeze-Thaw_factor": 0.77564,
        "Surface Water Flooding_factor": 0.77564,
        "Precipitation_factor": 0.77564,
        "Drought_factor": 0.77564,
        "Cyclone Wind_factor": 0.77564
    },
    2058: {
        "Riverine Flooding_factor": 0.76752,
        "Coastal Inundation_factor": 0.79652,
        "Extreme Heat_factor": 0.76752,
        "Forest Fire_factor": 0.76752,
        "Extreme Wind_factor": 0.76752,
        "Soil Subsidence_factor": 0.76752,
        "Freeze-Thaw_factor": 0.76752,
        "Surface Water Flooding_factor": 0.76752,
        "Precipitation_factor": 0.76752,
        "Drought_factor": 0.76752,
        "Cyclone Wind_factor": 0.76752
    },
    2059: {
        "Riverine Flooding_factor": 0.75936,
        "Coastal Inundation_factor": 0.79483,
        "Extreme Heat_factor": 0.75936,
        "Forest Fire_factor": 0.75936,
        "Extreme Wind_factor": 0.75936,
        "Soil Subsidence_factor": 0.75936,
        "Freeze-Thaw_factor": 0.75936,
        "Surface Water Flooding_factor": 0.75936,
        "Precipitation_factor": 0.75936,
        "Drought_factor": 0.75936,
        "Cyclone Wind_factor": 0.75936
    },
    2060: {
        "Riverine Flooding_factor": 0.75118,
        "Coastal Inundation_factor": 0.79305,
        "Extreme Heat_factor": 0.75118,
        "Forest Fire_factor": 0.75118,
        "Extreme Wind_factor": 0.75118,
        "Soil Subsidence_factor": 0.75118,
        "Freeze-Thaw_factor": 0.75118,
        "Surface Water Flooding_factor": 0.75118,
        "Precipitation_factor": 0.75118,
        "Drought_factor": 0.75118,
        "Cyclone Wind_factor": 0.75118
    },
    2061: {
        "Riverine Flooding_factor": 0.74297,
        "Coastal Inundation_factor": 0.79116,
        "Extreme Heat_factor": 0.74297,
        "Forest Fire_factor": 0.74297,
        "Extreme Wind_factor": 0.74297,
        "Soil Subsidence_factor": 0.74297,
        "Freeze-Thaw_factor": 0.74297,
        "Surface Water Flooding_factor": 0.74297,
        "Precipitation_factor": 0.74297,
        "Drought_factor": 0.74297,
        "Cyclone Wind_factor": 0.74297
    },
    2062: {
        "Riverine Flooding_factor": 0.73475,
        "Coastal Inundation_factor": 0.78912,
        "Extreme Heat_factor": 0.73475,
        "Forest Fire_factor": 0.73475,
        "Extreme Wind_factor": 0.73475,
        "Soil Subsidence_factor": 0.73475,
        "Freeze-Thaw_factor": 0.73475,
        "Surface Water Flooding_factor": 0.73475,
        "Precipitation_factor": 0.73475,
        "Drought_factor": 0.73475,
        "Cyclone Wind_factor": 0.73475
    },
    2063: {
        "Riverine Flooding_factor": 0.72646,
        "Coastal Inundation_factor": 0.78694,
        "Extreme Heat_factor": 0.72646,
        "Forest Fire_factor": 0.72646,
        "Extreme Wind_factor": 0.72646,
        "Soil Subsidence_factor": 0.72646,
        "Freeze-Thaw_factor": 0.72646,
        "Surface Water Flooding_factor": 0.72646,
        "Precipitation_factor": 0.72646,
        "Drought_factor": 0.72646,
        "Cyclone Wind_factor": 0.72646
    },
    2064: {
        "Riverine Flooding_factor": 0.71825,
        "Coastal Inundation_factor": 0.78441,
        "Extreme Heat_factor": 0.71825,
        "Forest Fire_factor": 0.71825,
        "Extreme Wind_factor": 0.71825,
        "Soil Subsidence_factor": 0.71825,
        "Freeze-Thaw_factor": 0.71825,
        "Surface Water Flooding_factor": 0.71825,
        "Precipitation_factor": 0.71825,
        "Drought_factor": 0.71825,
        "Cyclone Wind_factor": 0.71825
    },
    2065: {
        "Riverine Flooding_factor": 0.7101,
        "Coastal Inundation_factor": 0.78183,
        "Extreme Heat_factor": 0.7101,
        "Forest Fire_factor": 0.7101,
        "Extreme Wind_factor": 0.7101,
        "Soil Subsidence_factor": 0.7101,
        "Freeze-Thaw_factor": 0.7101,
        "Surface Water Flooding_factor": 0.7101,
        "Precipitation_factor": 0.7101,
        "Drought_factor": 0.7101,
        "Cyclone Wind_factor": 0.7101
    },
    2066: {
        "Riverine Flooding_factor": 0.70198,
        "Coastal Inundation_factor": 0.77919,
        "Extreme Heat_factor": 0.70198,
        "Forest Fire_factor": 0.70198,
        "Extreme Wind_factor": 0.70198,
        "Soil Subsidence_factor": 0.70198,
        "Freeze-Thaw_factor": 0.70198,
        "Surface Water Flooding_factor": 0.70198,
        "Precipitation_factor": 0.70198,
        "Drought_factor": 0.70198,
        "Cyclone Wind_factor": 0.70198
    },
    2067: {
        "Riverine Flooding_factor": 0.6939,
        "Coastal Inundation_factor": 0.77687,
        "Extreme Heat_factor": 0.6939,
        "Forest Fire_factor": 0.6939,
        "Extreme Wind_factor": 0.6939,
        "Soil Subsidence_factor": 0.6939,
        "Freeze-Thaw_factor": 0.6939,
        "Surface Water Flooding_factor": 0.6939,
        "Precipitation_factor": 0.6939,
        "Drought_factor": 0.6939,
        "Cyclone Wind_factor": 0.6939
    },
    2068: {
        "Riverine Flooding_factor": 0.68585,
        "Coastal Inundation_factor": 0.77491,
        "Extreme Heat_factor": 0.68585,
        "Forest Fire_factor": 0.68585,
        "Extreme Wind_factor": 0.68585,
        "Soil Subsidence_factor": 0.68585,
        "Freeze-Thaw_factor": 0.68585,
        "Surface Water Flooding_factor": 0.68585,
        "Precipitation_factor": 0.68585,
        "Drought_factor": 0.68585,
        "Cyclone Wind_factor": 0.68585
    },
    2069: {
        "Riverine Flooding_factor": 0.67785,
        "Coastal Inundation_factor": 0.77326,
        "Extreme Heat_factor": 0.67785,
        "Forest Fire_factor": 0.67785,
        "Extreme Wind_factor": 0.67785,
        "Soil Subsidence_factor": 0.67785,
        "Freeze-Thaw_factor": 0.67785,
        "Surface Water Flooding_factor": 0.67785,
        "Precipitation_factor": 0.67785,
        "Drought_factor": 0.67785,
        "Cyclone Wind_factor": 0.67785
    },
    2070: {
        "Riverine Flooding_factor": 0.6699,
        "Coastal Inundation_factor": 0.77176,
        "Extreme Heat_factor": 0.6699,
        "Forest Fire_factor": 0.6699,
        "Extreme Wind_factor": 0.6699,
        "Soil Subsidence_factor": 0.6699,
        "Freeze-Thaw_factor": 0.6699,
        "Surface Water Flooding_factor": 0.6699,
        "Precipitation_factor": 0.6699,
        "Drought_factor": 0.6699,
        "Cyclone Wind_factor": 0.6699
    },
    2071: {
        "Riverine Flooding_factor": 0.662,
        "Coastal Inundation_factor": 0.77052,
        "Extreme Heat_factor": 0.662,
        "Forest Fire_factor": 0.662,
        "Extreme Wind_factor": 0.662,
        "Soil Subsidence_factor": 0.662,
        "Freeze-Thaw_factor": 0.662,
        "Surface Water Flooding_factor": 0.662,
        "Precipitation_factor": 0.662,
        "Drought_factor": 0.662,
        "Cyclone Wind_factor": 0.662
    },
    2072: {
        "Riverine Flooding_factor": 0.65416,
        "Coastal Inundation_factor": 0.76954,
        "Extreme Heat_factor": 0.65416,
        "Forest Fire_factor": 0.65416,
        "Extreme Wind_factor": 0.65416,
        "Soil Subsidence_factor": 0.65416,
        "Freeze-Thaw_factor": 0.65416,
        "Surface Water Flooding_factor": 0.65416,
        "Precipitation_factor": 0.65416,
        "Drought_factor": 0.65416,
        "Cyclone Wind_factor": 0.65416
    },
    2073: {
        "Riverine Flooding_factor": 0.64639,
        "Coastal Inundation_factor": 0.7688,
        "Extreme Heat_factor": 0.64639,
        "Forest Fire_factor": 0.64639,
        "Extreme Wind_factor": 0.64639,
        "Soil Subsidence_factor": 0.64639,
        "Freeze-Thaw_factor": 0.64639,
        "Surface Water Flooding_factor": 0.64639,
        "Precipitation_factor": 0.64639,
        "Drought_factor": 0.64639,
        "Cyclone Wind_factor": 0.64639
    },
    2074: {
        "Riverine Flooding_factor": 0.63863,
        "Coastal Inundation_factor": 0.76862,
        "Extreme Heat_factor": 0.63863,
        "Forest Fire_factor": 0.63863,
        "Extreme Wind_factor": 0.63863,
        "Soil Subsidence_factor": 0.63863,
        "Freeze-Thaw_factor": 0.63863,
        "Surface Water Flooding_factor": 0.63863,
        "Precipitation_factor": 0.63863,
        "Drought_factor": 0.63863,
        "Cyclone Wind_factor": 0.63863
    },
    2075: {
        "Riverine Flooding_factor": 0.63088,
        "Coastal Inundation_factor": 0.76885,
        "Extreme Heat_factor": 0.63088,
        "Forest Fire_factor": 0.63088,
        "Extreme Wind_factor": 0.63088,
        "Soil Subsidence_factor": 0.63088,
        "Freeze-Thaw_factor": 0.63088,
        "Surface Water Flooding_factor": 0.63088,
        "Precipitation_factor": 0.63088,
        "Drought_factor": 0.63088,
        "Cyclone Wind_factor": 0.63088
    },
    2076: {
        "Riverine Flooding_factor": 0.62315,
        "Coastal Inundation_factor": 0.76946,
        "Extreme Heat_factor": 0.62315,
        "Forest Fire_factor": 0.62315,
        "Extreme Wind_factor": 0.62315,
        "Soil Subsidence_factor": 0.62315,
        "Freeze-Thaw_factor": 0.62315,
        "Surface Water Flooding_factor": 0.62315,
        "Precipitation_factor": 0.62315,
        "Drought_factor": 0.62315,
        "Cyclone Wind_factor": 0.62315
    },
    2077: {
        "Riverine Flooding_factor": 0.61543,
        "Coastal Inundation_factor": 0.77024,
        "Extreme Heat_factor": 0.61543,
        "Forest Fire_factor": 0.61543,
        "Extreme Wind_factor": 0.61543,
        "Soil Subsidence_factor": 0.61543,
        "Freeze-Thaw_factor": 0.61543,
        "Surface Water Flooding_factor": 0.61543,
        "Precipitation_factor": 0.61543,
        "Drought_factor": 0.61543,
        "Cyclone Wind_factor": 0.61543
    },
    2078: {
        "Riverine Flooding_factor": 0.60771,
        "Coastal Inundation_factor": 0.77091,
        "Extreme Heat_factor": 0.60771,
        "Forest Fire_factor": 0.60771,
        "Extreme Wind_factor": 0.60771,
        "Soil Subsidence_factor": 0.60771,
        "Freeze-Thaw_factor": 0.60771,
        "Surface Water Flooding_factor": 0.60771,
        "Precipitation_factor": 0.60771,
        "Drought_factor": 0.60771,
        "Cyclone Wind_factor": 0.60771
    },
    2079: {
        "Riverine Flooding_factor": 0.59999,
        "Coastal Inundation_factor": 0.77148,
        "Extreme Heat_factor": 0.59999,
        "Forest Fire_factor": 0.59999,
        "Extreme Wind_factor": 0.59999,
        "Soil Subsidence_factor": 0.59999,
        "Freeze-Thaw_factor": 0.59999,
        "Surface Water Flooding_factor": 0.59999,
        "Precipitation_factor": 0.59999,
        "Drought_factor": 0.59999,
        "Cyclone Wind_factor": 0.59999
    },
    2080: {
        "Riverine Flooding_factor": 0.59226,
        "Coastal Inundation_factor": 0.77224,
        "Extreme Heat_factor": 0.59226,
        "Forest Fire_factor": 0.59226,
        "Extreme Wind_factor": 0.59226,
        "Soil Subsidence_factor": 0.59226,
        "Freeze-Thaw_factor": 0.59226,
        "Surface Water Flooding_factor": 0.59226,
        "Precipitation_factor": 0.59226,
        "Drought_factor": 0.59226,
        "Cyclone Wind_factor": 0.59226
    },
    2081: {
        "Riverine Flooding_factor": 0.58452,
        "Coastal Inundation_factor": 0.77288,
        "Extreme Heat_factor": 0.58452,
        "Forest Fire_factor": 0.58452,
        "Extreme Wind_factor": 0.58452,
        "Soil Subsidence_factor": 0.58452,
        "Freeze-Thaw_factor": 0.58452,
        "Surface Water Flooding_factor": 0.58452,
        "Precipitation_factor": 0.58452,
        "Drought_factor": 0.58452,
        "Cyclone Wind_factor": 0.58452
    },
    2082: {
        "Riverine Flooding_factor": 0.57678,
        "Coastal Inundation_factor": 0.77329,
        "Extreme Heat_factor": 0.57678,
        "Forest Fire_factor": 0.57678,
        "Extreme Wind_factor": 0.57678,
        "Soil Subsidence_factor": 0.57678,
        "Freeze-Thaw_factor": 0.57678,
        "Surface Water Flooding_factor": 0.57678,
        "Precipitation_factor": 0.57678,
        "Drought_factor": 0.57678,
        "Cyclone Wind_factor": 0.57678
    },
    2083: {
        "Riverine Flooding_factor": 0.56905,
        "Coastal Inundation_factor": 0.77344,
        "Extreme Heat_factor": 0.56905,
        "Forest Fire_factor": 0.56905,
        "Extreme Wind_factor": 0.56905,
        "Soil Subsidence_factor": 0.56905,
        "Freeze-Thaw_factor": 0.56905,
        "Surface Water Flooding_factor": 0.56905,
        "Precipitation_factor": 0.56905,
        "Drought_factor": 0.56905,
        "Cyclone Wind_factor": 0.56905
    },
    2084: {
        "Riverine Flooding_factor": 0.56135,
        "Coastal Inundation_factor": 0.77317,
        "Extreme Heat_factor": 0.56135,
        "Forest Fire_factor": 0.56135,
        "Extreme Wind_factor": 0.56135,
        "Soil Subsidence_factor": 0.56135,
        "Freeze-Thaw_factor": 0.56135,
        "Surface Water Flooding_factor": 0.56135,
        "Precipitation_factor": 0.56135,
        "Drought_factor": 0.56135,
        "Cyclone Wind_factor": 0.56135
    },
    2085: {
        "Riverine Flooding_factor": 0.5537,
        "Coastal Inundation_factor": 0.77276,
        "Extreme Heat_factor": 0.5537,
        "Forest Fire_factor": 0.5537,
        "Extreme Wind_factor": 0.5537,
        "Soil Subsidence_factor": 0.5537,
        "Freeze-Thaw_factor": 0.5537,
        "Surface Water Flooding_factor": 0.5537,
        "Precipitation_factor": 0.5537,
        "Drought_factor": 0.5537,
        "Cyclone Wind_factor": 0.5537
    },
    2086: {
        "Riverine Flooding_factor": 0.54614,
        "Coastal Inundation_factor": 0.77222,
        "Extreme Heat_factor": 0.54614,
        "Forest Fire_factor": 0.54614,
        "Extreme Wind_factor": 0.54614,
        "Soil Subsidence_factor": 0.54614,
        "Freeze-Thaw_factor": 0.54614,
        "Surface Water Flooding_factor": 0.54614,
        "Precipitation_factor": 0.54614,
        "Drought_factor": 0.54614,
        "Cyclone Wind_factor": 0.54614
    },
    2087: {
        "Riverine Flooding_factor": 0.53869,
        "Coastal Inundation_factor": 0.77153,
        "Extreme Heat_factor": 0.53869,
        "Forest Fire_factor": 0.53869,
        "Extreme Wind_factor": 0.53869,
        "Soil Subsidence_factor": 0.53869,
        "Freeze-Thaw_factor": 0.53869,
        "Surface Water Flooding_factor": 0.53869,
        "Precipitation_factor": 0.53869,
        "Drought_factor": 0.53869,
        "Cyclone Wind_factor": 0.53869
    },
    2088: {
        "Riverine Flooding_factor": 0.53136,
        "Coastal Inundation_factor": 0.771,
        "Extreme Heat_factor": 0.53136,
        "Forest Fire_factor": 0.53136,
        "Extreme Wind_factor": 0.53136,
        "Soil Subsidence_factor": 0.53136,
        "Freeze-Thaw_factor": 0.53136,
        "Surface Water Flooding_factor": 0.53136,
        "Precipitation_factor": 0.53136,
        "Drought_factor": 0.53136,
        "Cyclone Wind_factor": 0.53136
    },
    2089: {
        "Riverine Flooding_factor": 0.52418,
        "Coastal Inundation_factor": 0.77064,
        "Extreme Heat_factor": 0.52418,
        "Forest Fire_factor": 0.52418,
        "Extreme Wind_factor": 0.52418,
        "Soil Subsidence_factor": 0.52418,
        "Freeze-Thaw_factor": 0.52418,
        "Surface Water Flooding_factor": 0.52418,
        "Precipitation_factor": 0.52418,
        "Drought_factor": 0.52418,
        "Cyclone Wind_factor": 0.52418
    },
    2090: {
        "Riverine Flooding_factor": 0.51716,
        "Coastal Inundation_factor": 0.77018,
        "Extreme Heat_factor": 0.51716,
        "Forest Fire_factor": 0.51716,
        "Extreme Wind_factor": 0.51716,
        "Soil Subsidence_factor": 0.51716,
        "Freeze-Thaw_factor": 0.51716,
        "Surface Water Flooding_factor": 0.51716,
        "Precipitation_factor": 0.51716,
        "Drought_factor": 0.51716,
        "Cyclone Wind_factor": 0.51716
    },
    2091: {
        "Riverine Flooding_factor": 0.51033,
        "Coastal Inundation_factor": 0.76985,
        "Extreme Heat_factor": 0.51033,
        "Forest Fire_factor": 0.51033,
        "Extreme Wind_factor": 0.51033,
        "Soil Subsidence_factor": 0.51033,
        "Freeze-Thaw_factor": 0.51033,
        "Surface Water Flooding_factor": 0.51033,
        "Precipitation_factor": 0.51033,
        "Drought_factor": 0.51033,
        "Cyclone Wind_factor": 0.51033
    },
    2092: {
        "Riverine Flooding_factor": 0.5037,
        "Coastal Inundation_factor": 0.76995,
        "Extreme Heat_factor": 0.5037,
        "Forest Fire_factor": 0.5037,
        "Extreme Wind_factor": 0.5037,
        "Soil Subsidence_factor": 0.5037,
        "Freeze-Thaw_factor": 0.5037,
        "Surface Water Flooding_factor": 0.5037,
        "Precipitation_factor": 0.5037,
        "Drought_factor": 0.5037,
        "Cyclone Wind_factor": 0.5037
    },
    2093: {
        "Riverine Flooding_factor": 0.49753,
        "Coastal Inundation_factor": 0.7703,
        "Extreme Heat_factor": 0.49753,
        "Forest Fire_factor": 0.49753,
        "Extreme Wind_factor": 0.49753,
        "Soil Subsidence_factor": 0.49753,
        "Freeze-Thaw_factor": 0.49753,
        "Surface Water Flooding_factor": 0.49753,
        "Precipitation_factor": 0.49753,
        "Drought_factor": 0.49753,
        "Cyclone Wind_factor": 0.49753
    },
    2094: {
        "Riverine Flooding_factor": 0.49181,
        "Coastal Inundation_factor": 0.77088,
        "Extreme Heat_factor": 0.49181,
        "Forest Fire_factor": 0.49181,
        "Extreme Wind_factor": 0.49181,
        "Soil Subsidence_factor": 0.49181,
        "Freeze-Thaw_factor": 0.49181,
        "Surface Water Flooding_factor": 0.49181,
        "Precipitation_factor": 0.49181,
        "Drought_factor": 0.49181,
        "Cyclone Wind_factor": 0.49181
    },
    2095: {
        "Riverine Flooding_factor": 0.48654,
        "Coastal Inundation_factor": 0.7715,
        "Extreme Heat_factor": 0.48654,
        "Forest Fire_factor": 0.48654,
        "Extreme Wind_factor": 0.48654,
        "Soil Subsidence_factor": 0.48654,
        "Freeze-Thaw_factor": 0.48654,
        "Surface Water Flooding_factor": 0.48654,
        "Precipitation_factor": 0.48654,
        "Drought_factor": 0.48654,
        "Cyclone Wind_factor": 0.48654
    },
    2096: {
        "Riverine Flooding_factor": 0.48167,
        "Coastal Inundation_factor": 0.77212,
        "Extreme Heat_factor": 0.48167,
        "Forest Fire_factor": 0.48167,
        "Extreme Wind_factor": 0.48167,
        "Soil Subsidence_factor": 0.48167,
        "Freeze-Thaw_factor": 0.48167,
        "Surface Water Flooding_factor": 0.48167,
        "Precipitation_factor": 0.48167,
        "Drought_factor": 0.48167,
        "Cyclone Wind_factor": 0.48167
    },
    2097: {
        "Riverine Flooding_factor": 0.47894,
        "Coastal Inundation_factor": 0.77245,
        "Extreme Heat_factor": 0.47894,
        "Forest Fire_factor": 0.47894,
        "Extreme Wind_factor": 0.47894,
        "Soil Subsidence_factor": 0.47894,
        "Freeze-Thaw_factor": 0.47894,
        "Surface Water Flooding_factor": 0.47894,
        "Precipitation_factor": 0.47894,
        "Drought_factor": 0.47894,
        "Cyclone Wind_factor": 0.47894
    },
    2098: {
        "Riverine Flooding_factor": 0.47635,
        "Coastal Inundation_factor": 0.77279,
        "Extreme Heat_factor": 0.47635,
        "Forest Fire_factor": 0.47635,
        "Extreme Wind_factor": 0.47635,
        "Soil Subsidence_factor": 0.47635,
        "Freeze-Thaw_factor": 0.47635,
        "Surface Water Flooding_factor": 0.47635,
        "Precipitation_factor": 0.47635,
        "Drought_factor": 0.47635,
        "Cyclone Wind_factor": 0.47635
    },
    2099: {
        "Riverine Flooding_factor": 0.47392,
        "Coastal Inundation_factor": 0.77315,
        "Extreme Heat_factor": 0.47392,
        "Forest Fire_factor": 0.47392,
        "Extreme Wind_factor": 0.47392,
        "Soil Subsidence_factor": 0.47392,
        "Freeze-Thaw_factor": 0.47392,
        "Surface Water Flooding_factor": 0.47392,
        "Precipitation_factor": 0.47392,
        "Drought_factor": 0.47392,
        "Cyclone Wind_factor": 0.47392
    },
    2100: {
        "Riverine Flooding_factor": 0.4717,
        "Coastal Inundation_factor": 0.7737,
        "Extreme Heat_factor": 0.4717,
        "Forest Fire_factor": 0.4717,
        "Extreme Wind_factor": 0.4717,
        "Soil Subsidence_factor": 0.4717,
        "Freeze-Thaw_factor": 0.4717,
        "Surface Water Flooding_factor": 0.4717,
        "Precipitation_factor": 0.4717,
        "Drought_factor": 0.4717,
        "Cyclone Wind_factor": 0.4717
    }
};
