import _ from "lodash";
import { scenario26Adjustment } from "./scenario26Adjustment";
import { scenario45Adjustment } from "./scenario45Adjustment";
import { scenario60Adjustment } from "./scenario60Adjustment";

const hazardMap = {
    flood_riverine: "Riverine Flooding_factor",
    inundation: "Coastal Inundation_factor",
    heat: "Extreme Heat_factor",
    forest_fire: "Forest Fire_factor",
    wind: "Extreme Wind_factor",
    soil_movement: "Soil Subsidence_factor",
    "freeze-thaw": "Freeze-Thaw_factor",
    flood_surfacewater: "Surface Water Flooding_factor",
    cyclone_wind: "Cyclone Wind_factor",
};

export const adjustRCP = (analysis, rcp) => {
    if (!["2.6", "4.5", "6.0"].includes(rcp)) return;

    const { properties } = analysis;
    const { failureHazardsElements, riskHazardsElements } = properties;
    const hazards = _.get(analysis.inputs.scenario, "hazards")

    const scenario = getScenarioAdjustment(rcp);
    const adjustYears = getScenarioYears(analysis.inputs.scenario);

    if (adjustYears.length !== properties.totalRisk.length) {
        throw new Error("Cannot update due to mismatch between given number of years and output.");
    }

    const adjustedFailureHazardsElements = rcpAdjust(failureHazardsElements, hazards, adjustYears, scenario);
    const adjustedRiskHazardsElements = rcpAdjust(riskHazardsElements, hazards, adjustYears, scenario);

    const adjustedRiskHazards = adjustedRiskHazardsElements.map(haz => haz.map(sum));
    const adjustedFailureHazards = adjustedFailureHazardsElements.map(haz => haz.map(atLeastOne));

    let totalRisk, totalFailure;
    if (hazards.includes("flood_riverine") && hazards.includes("flood_surfacewater")) {
        const [riskHz, failHz] = aggregateFlood(hazards, adjustedRiskHazards, adjustedFailureHazards);

        totalRisk = riskHz.map(sum);
        totalFailure = failHz.map(atLeastOne);
    } else {
        totalRisk = adjustedRiskHazards.map(sum);
        totalFailure = adjustedFailureHazards.map(atLeastOne);
    }

    const totalRiskCost = aggregateTotalRiskCost(totalRisk, properties.replacementCost);

    const newProperties = {
        failureHazards: adjustedFailureHazards,
        riskHazards: adjustedRiskHazards,
        failureHazardsElements: adjustedFailureHazardsElements,
        riskHazardsElements: adjustedRiskHazardsElements,
        totalRiskCost: totalRiskCost,
        totalRisk: totalRisk,
        totalFailure: totalFailure,
    };

    return {
        ...analysis,
        properties: {
            ...analysis.properties,
            ...newProperties,
        },
    };
};

export const preValidateAnalysis = (analysis) => {
    if (!("failureHazardsElements" in analysis.properties) && !("riskHazardsElements" in analysis.properties)) {
        throw new Error("Cannot change RCP due to insufficient data.");
    }
};

const rcpAdjust = (data, hazards, adjustYears, scenario) => {
    const BASELINE_INDEX = 0;

    return data.map((hazValues, idx) => {
        const currentYear = adjustYears[idx];

        return hazValues.map((elements, hazIndex) => {
            const currentHazard = hazardMap[hazards[hazIndex]];
            const scalingFactor = scenario[currentYear][currentHazard];

            return elements.map((element, elIndex) => {
                const base = data[BASELINE_INDEX][hazIndex][elIndex];
                const adjusted = (element - base) * scalingFactor + base;

                return adjusted;
            });
        });
    });
};

const aggregateTotalRiskCost = (totalRisk, replacementCost) => {
    return totalRisk.map((t) => t * replacementCost);
};

const getScenarioYears = (scenario) => {
    if ("years" in scenario) {
        if (typeof scenario.years === "string") {
            return scenario.years.split(",");
        }
        if (Array.isArray(scenario.years)) {
            return scenario.years
        }
    }

    if ("startYear" in scenario) {
        const yearLength = parseInt(scenario.endYear) - parseInt(scenario.startYear) + 1;
        return Array.from(Array(yearLength), (_, i) => scenario.startYear + i);
    }

    return [];
};

const aggregateFlood = (hazards, adjustedRiskHazards, adjustedFailureHazards) => {
    const riverineIndex = hazards.indexOf("flood_riverine");
    const surfacewaterIndex = hazards.indexOf("flood_surfacewater");
    const floodIndexes = [riverineIndex, surfacewaterIndex];

    const riverineRiskList = adjustedRiskHazards.map((hz) => hz[riverineIndex]);
    const riverineFailList = adjustedFailureHazards.map((hz) => hz[riverineIndex]);

    const surfacewaterRiskList = adjustedRiskHazards.map((hz) => hz[surfacewaterIndex]);
    const surfacewaterFailList = adjustedFailureHazards.map((hz) => hz[surfacewaterIndex]);

    const floodRisk = fmax(riverineRiskList, surfacewaterRiskList);
    const floodFail = fmax(riverineFailList, surfacewaterFailList);

    const riskWithoutFlood = adjustedRiskHazards.map((year) => year.filter((_, i) => !floodIndexes.includes(i)));
    const failWithoutFlood = adjustedFailureHazards.map((year) => year.filter((_, i) => !floodIndexes.includes(i)));

    const riskHz = riskWithoutFlood.map((year, i) => year.concat(floodRisk[i]));
    const failHz = failWithoutFlood.map((year, i) => year.concat(floodFail[i]));

    return [riskHz, failHz];
};

const getScenarioAdjustment = (rcpValue) => {
    switch (rcpValue) {
        case "2.6":
            return scenario26Adjustment;
        case "4.5":
            return scenario45Adjustment;
        case "6.0":
            return scenario60Adjustment;
    }
};

// probability of at least one failure [https://math.stackexchange.com/a/85852]
// 1 - ( (1 - P1) * (1 - P2) * ... * (1 - Pn) )
const atLeastOne = xs => 1 - xs.reduce((x1, x2) => x1 * (1 - x2), 1);

export const fmax = (xs, ys) => xs.map((x, i) => x >= ys[i] ? x : ys[i]);

const sum = xs => xs.reduce((x1, x2) => x1 + x2);
