import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const ToolbarInfoAlert = ({ info }) => {
    const classes = classNames("toolbar__info-alert", "alert", "alert-warning", {
        "toolbar__info-alert--hidden": info === ""
    });

    return <div id="toolbar-info-alert" className={classes}>{info}</div>;
}

ToolbarInfoAlert.propTypes = {
    info: PropTypes.string,
}