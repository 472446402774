import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const ToolbarButton = ({ active, children, fullWidth, title, onClick }) => {
    const classes = classNames("toolbar__button", "cesium-button", {
        "cesium-toolbar-button": !fullWidth,
        "toolbar__button--active": !!active,
        "toolbar__button--full-width": !!fullWidth
    });

    return <button className={classes} onClick={onClick} title={title} type="button">
        {children}
    </button>;
}

ToolbarButton.propTypes = {
    children: PropTypes.any,
    active: PropTypes.bool,
    fullWidth: PropTypes.bool,
    title: PropTypes.string,
    onClick: PropTypes.func,
};