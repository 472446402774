import React from "react"
import { observer } from "mobx-react"
import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"
import Table from "react-bootstrap/Table"
import { openAnalysisModal } from "../../utils.js"
import { getRGB } from "../../scene.js"

import "./StackSelectModal.scss"

@observer
class StackSelectModal extends React.Component {
    static propTypes = {
        dependencies: PropTypes.array,
    }

    constructor(props) {
        super(props)

        this.state = {
        }
    }

    handleOpenAsset(assetId) {
        openAnalysisModal(assetId)
        this.props.handleClose()
    }

    getStyle(totalRisk) {
        const colAr = getRGB(totalRisk)
        let color = `rgba(${colAr[0]}, ${colAr[1]}, ${colAr[2]}, 1)`
        return { background: color }
    }

    render() {
        const { handleClose, assetList } = this.props

        return (
            <Modal show={true} variant="sm" id="selectModal" onHide={handleClose}>
                <Modal.Header closeButton>
                    <h5 className="modal-title">Select Desired Asset</h5>
                </Modal.Header>
                <Modal.Body>
                    <Table striped={true} bordered={true} hover={true} size="sm">
                        <tbody>
                            {assetList
                                .slice().sort(function (a, b) {
                                    return a._id - b._id
                                })
                                .map((asset, key) => {
                                    return (
                                        <tr
                                            key={key}
                                            onClick={() => this.handleOpenAsset(asset._id)}>
                                            <td>
                                                <div
                                                    className="colorBox"
                                                    style={this.getStyle(asset._total_risk)}
                                                />
                                            </td>
                                            <td>{asset._id}</td>
                                            <td>{asset._address}</td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        )
    }
}

export default StackSelectModal
