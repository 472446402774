import React, { useState } from "react";

import { ToolbarAreaOptions } from "./ToolbarAreaOptions.jsx";
import { ToolbarButton } from "./ToolbarButton.jsx";
import { ToolbarInfoAlert } from "./ToolbarInfoAlert.jsx";
import { ToolbarSelector } from "./ToolbarSelector.jsx";

import { AREA_OPTIONS, BUTTON_LIST, SELECTORS } from "./ToolbarConstants.js";
import "./Toolbar.scss";

export const Toolbar = () => {
    const [autoloaderActive, setAutoloaderActive] = useState(true);
    const [autozoomerActive, setAutozoomerActive] = useState(true);
    const [info] = useState("");
    const [openSelector, setOpenSelector] = useState(null);

    const buttons = [
        ...BUTTON_LIST,
        {
            active: autozoomerActive,
            icon: "center_focus_strong",
            title: "Autozoomer",
            callback: () => {
                setAutozoomerActive(!autozoomerActive);
            }
        },
        {
            active: autoloaderActive,
            icon: "cached",
            title: "Autoloader",
            callback: () => {
                setAutoloaderActive(!autoloaderActive);
            }
        }
    ];

    /**
     * @param {string} id ID of selector in which button which was clicked.
     */
    function onSelectorButtonClick(id) {
        setOpenSelector(id === openSelector ? null : id);
        onSelectorChange(id);
    }

    /**
     *
     * @param {string} id ID of selector changed.
     * @param {string} value Value of selector changed.
     */
    function onSelectorChange(id, value) {
        window.scene.viewSelector.onChange(
            id === "collection-point" ? "Point" : "Polygon",
            value ? value.join(",") : null,
            autozoomerActive
        );
    }

    return <div className="toolbar">
        <div className="toolbar__button-list">
            {buttons.map(({ active = false, icon, title, callback }) => {
                return <ToolbarButton key={icon} active={active} title={title} onClick={callback}>
                    <i className="material-icons">{icon}</i>
                </ToolbarButton>;
            })}
            <div id="toolbar-message" className="alert alert-secondary toolbar__message">
                Please report any software bugs or provide usability feedback to:<br />
                <a href="mailto:feedback@xdi.systems">feedback@xdi.systems</a>
            </div>
        </div>
        <div className="toolbar__selector-list">
            {SELECTORS.map(({ buttonTitle, selectId, selectText, selectTitle, options }, index) => (
                <ToolbarSelector
                    key={index}
                    buttonOnClick={() => onSelectorButtonClick(selectId)}
                    buttonTitle={buttonTitle}
                    isOpen={openSelector === selectId}
                    onChange={(options) => onSelectorChange(selectId, options)}
                    options={options}
                    selectId={selectId}
                    selectTitle={selectTitle}
                >{selectText}</ToolbarSelector>
            ))}
        </div>
        <ToolbarAreaOptions options={AREA_OPTIONS} />
        <ToolbarInfoAlert info={info} />
    </div>;
}