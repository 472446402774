/**
 * Builds a list of years from a string.
 *
 * @param {string} yearString - String to convert
 *
 * @returns {Array} - List of years
 */
export function buildYearList(yearString) {
    const years = [];

    try {
        const yearSplit = yearString.split(",");

        for (let segment of yearSplit) {
            segment = segment.trim();

            if (segment.indexOf("-") > -1) {
                const [start, end] = segment.split("-");
                for (let year = start; year <= end; year++) {
                    years.push(+year);
                }
            } else {
                years.push(+segment);
            }
        }

        return years;
    } catch (error) {
        throw(new Error("Unknown year spec - use comma-separated list of years of year-year ranges."))
    }
}


/**
 * Builds a list of years from a scenario.
 *
 * @param {object} scenario - Scenario to interpret
 *
 * @returns {Array} - List of years
 */
export function buildYearListFromScenario(scenario) {
    if (Array.isArray(scenario.years)) {
        return scenario.years
    }
    return buildYearList(scenario.years || `${scenario.startYear}-${scenario.endYear}`);
}