import React from "react"
import autoBind from "react-autobind"
import { Button, Form } from "react-bootstrap"

import Loading from "../../Loading.jsx"

import "./MultiAdaptationForm.scss"

class MultiAdaptationForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            subject: "",
            comment: "",
            label: "Multi-Asset Adaptation Request",
            success: false,
            error: null,
            valid: false,
            sending: false,
        }
        autoBind(this)
    }

    async handleSubmit() {
        const { comment, subject, email, label } = this.state

        this.setState({ error: null, sending: true })

        try {
            console.log("submit")
            const data = {
                email,
                subject,
                comment,
                label,
                url: window.location.href,
            }

            const response = await jQuery.ajax({
                method: "POST",
                // eslint-disable-next-line no-undef
                url: `${crConstants.authHost}/v1/multi_adaptation_form`,
                data: {
                    data: JSON.stringify(data, null, 2),
                    mail: JSON.stringify({
                        app: "globe",
                        template: "request",
                    }),
                },
                dataType: "json",
            })
            this.setState({ success: true, sending: false })
        } catch (e) {
            const err =
                (e.responseJSON && e.responseJSON.error && e.responseJSON.error.message) ||
                e.message ||
                e.statusText ||
                "" + e
            console.log("e", e)
            this.setState({ error: err, sending: false })
        }
    }

    handleChange(field, value) {
        this.setState({ [field]: value })
        setTimeout(() => this.setState({ valid: this.form.checkValidity() === true }), 0)
    }

    render() {
        const { subject, comment, email, error, success, valid, sending } = this.state

        return (
            <div className="MultiAdaptationForm">
                <h4>Multi-Asset Adaptation Form</h4>

                {!success && (
                    <Form
                        onSubmit={(e) => e.preventDefault()}
                        ref={(form) => {
                            this.form = form
                        }}>
                        <Form.Group controlId="email">
                            <Form.Label>Contact Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => this.handleChange("email", e.target.value)}
                                placeholder="Your email address"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="subject">
                            <Form.Label>Description of Assets</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={subject}
                                rows="5"
                                onChange={(e) => this.handleChange("subject", e.target.value)}
                                placeholder="All substations in Sydney with moderate or higher risk of flood."
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="additional">
                            <Form.Label>Adaptation Details</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={comment}
                                rows="5"
                                onChange={(e) => this.handleChange("comment", e.target.value)}
                                placeholder="Increase electrical height by 1 meter in 2040"
                                required
                            />
                        </Form.Group>

                        {!!error && <div className="error">{error}</div>}
                    </Form>
                )}

                {!!success && (
                    <div className="success">
                        Your request has been sent, we'll contact you shortly.
                    </div>
                )}

                <div className="buttons">
                    <Button
                        type="submit"
                        onClick={this.handleSubmit}
                        disabled={!valid || !!success || !!sending}>
                        {sending ? <Loading /> : success ? "Sent" : "Submit"}
                    </Button>
                </div>
            </div>
        )
    }
}

export default MultiAdaptationForm
