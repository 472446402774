import Highcharts from "highcharts"
import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"
import autoBind from "react-autobind"
import ReactHighcharts from "react-highcharts"

import { hazard_colours_array, hazard_labels } from '../constants.js'
import appState from '../CoreFiles/state.js'


function get_hazard_labels(hazards) {
    return hazards.map((h) => hazard_labels[h])
}

let default_hazards = [
    "flood_riverine",
    "inundation",
    "heat",
    "forest_fire",
    "wind",
    "soil_movement",
]

/**f
 * Get the risk hazard (VAR) data ready for the chart
 *
 * @param {object} chartData {
 *      results - Array of Arrays,
 *      maxToDate - bool
 *      hazards: machine names of hazards
 *      hazardLabels: optional human-readable names of hazards
 * }
 * optional params
 * @param {number} startingHazardIndex - Starting hazard index
 * @param {number} hazardsLength - length of hazard name array
 * @param {string} color - The color code of the data series
 *
 * @returns {Array} - Data series Array for chart
 */
const getDataForCharts = function (chartData, startingHazardIndex = 0, hazardsLength, color) {
    const { results, maxToDate } = chartData

    const scenario = appState.current_analysis.inputs.scenario
    const hazards = _.get(scenario, "hazards", default_hazards)
    const hazardLabels = _.get(chartData, "hazardLabels", get_hazard_labels(hazards))

    if (!hazardsLength) {
        // TODO: Get rid of this? just pass in all hazards needed?
        hazardsLength = hazards.length
    }

    let series = []

    for (let hazardIndex = startingHazardIndex; hazardIndex < hazardsLength; hazardIndex++) {
        // Make series for VAR
        let annualSeries = []
        let maxSeries = []

        let i = 0
        for (let yi = 0; yi < results.length; yi++) {
            let newVal = results[yi][hazardIndex]
            annualSeries.push(newVal)

            if (maxToDate) {
                if (maxSeries[0] == undefined) {
                    // Assign first value to maxSeries
                    maxSeries = [results[1][hazardIndex]]
                } else if (newVal < maxSeries[i - 1]) {
                    maxSeries.push(maxSeries[i - 1])
                } else {
                    maxSeries.push(newVal)
                }
            }

            i++
        }

        if (maxToDate) {
            series.push({
                type: "area",
                name: hazardLabels[hazardIndex] + " Max-to-Date",
                marker: {
                    enabled: false,
                },
                fillOpacity: 0.2,
                data: maxSeries,
                color: color,
            })
        } else {
            // Todo: Check if this can be deleted.
            // if (color === "CV") {
            //     let lineColorGraph
            //     let areaColor
            //     if (hazardLabels[hazardIndex] === "Flood (Riverine)") {
            //         lineColorGraph = "#3A657E"
            //         areaColor = "#C9D0D7"
            //     }
            //     if (hazardLabels[hazardIndex] === "Coastal Inundation") {
            //         lineColorGraph = "#3C8B53"
            //         areaColor = "#E9EAEB"
            //     }
            //     if (hazardLabels[hazardIndex] === "Soil Movement") {
            //         lineColorGraph = "#89233D"
            //         areaColor = "#DCC3C1"
            //     }
            //     if (hazardLabels[hazardIndex] === "Forest Fire") {
            //         lineColorGraph = "#D57A31"
            //         areaColor = "#F4DDC9"
            //     }
            //     if (hazardLabels[hazardIndex] === "Wind Damage") {
            //         lineColorGraph = "#E7D79D"
            //         areaColor = "#F6F0DF"
            //     }
            //     series.push({
            //         type: "area",
            //         name: hazardLabels[hazardIndex],
            //         marker: {
            //             enabled: false,
            //         },
            //         fillOpacity: "100%",
            //         lineColor: lineColorGraph,
            //         color: areaColor,
            //         data: annualSeries,
            //     })
            // } else {
                series.push({
                    type: "area",
                    name: hazardLabels[hazardIndex],
                    marker: {
                        enabled: false,
                    },
                    fillOpacity: 0.2,
                    color: color,
                    data: annualSeries,
                })
            // }
        }
    }
    return series
}

/**
 * Get the data for the series used to make the pie charts
 *
 * @param {Array} resultsData - Array of Arrays. Analysis result data,
 *      either riskHards or Failure probability
 * @param {number} year - Year the pie chart displays
 * @param {number} startYear - Start year of the analysis
 *
 * @returns {Array} - Array data result series
 */
const generatePieSeries = function (resultsData, year, startYear) {
    let yearIndex = year - startYear

    const scenario = appState.current_analysis.inputs.scenario
    const hazards = _.get(scenario, "hazards", default_hazards)
    const hazardLabels = get_hazard_labels(hazards)

    if (resultsData.length < 50) {
        // Attempt to match correct years in BLAZE export")
        // TODO: Determine year indexes more robustly
        switch(year) {
        case 2020:
            yearIndex = 6;
            break;
        case 2050:
            yearIndex = 12;
            break;
        case 2100:
            yearIndex = 22;
            break;
        }
    }

    const dataArray = resultsData[yearIndex]
        .map((resultData, i) => [hazardLabels[i], resultData, false])

    let series = [
        {
            allowPointSelect: true,
            keys: ["name", "y", "selected", "sliced"],
            dataLabels: {
                formatter: function () {
                    // display only if larger than 0
                    return this.y > 0 ? "<b>" + this.point.name : null
                },
            },
            data: dataArray,
        },
    ]
    return series
}

/**
 * Draws a Column chart
 *
 * @param {object} analysis - Analysis result data
 * @param {number} startYear - first year
 * @param {number} mortgageYear - second year
 * @param {number} endYear - third year
 * @param {string} dataSetName - name of dataset
 *
 * @returns {Array} - data series array
 */
const generateColumnSeries = function (
    analysis,
    startYear,
    mortgageYear,
    endYear,
    dataSetName,
    colorScheme
) {
    let yearIndexStart = startYear - analysis.inputs.scenario.startYear
    let yearIndexMortgage = mortgageYear - analysis.inputs.scenario.startYear
    let yearIndexEnd = endYear - analysis.inputs.scenario.startYear
    let riverineFlooding = colorScheme === "CV" ? ["#3A657E", "#3A657E"] : ["#d65c31", "#d34817"]
    let coastalInnundation = colorScheme === "CV" ? ["#3C8B53", "#3C8B53"] : ["#ad5050", "#ab3c3c"]
    let subsidence = colorScheme === "CV" ? ["#89233D", "#89233D"] : ["#d2d6a5", "#a4a879"]
    let forestFire = colorScheme === "CV" ? ["#D57A31", "#D57A31"] : ["#dbd5d3", "#b8b4b2"]
    let extremeWind = colorScheme === "CV" ? ["#E7D79D", "#E7D79D"] : ["#a89185", "#997f71"]
    let series = [
        {
            name: "Riverine Flooding",
            color: {
                linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1,
                },
                stops: [
                    [0, riverineFlooding[0]],
                    [1, riverineFlooding[1]],
                ],
            },
            data: [
                analysis.properties[dataSetName][yearIndexStart][0],
                analysis.properties[dataSetName][yearIndexMortgage][0],
                analysis.properties[dataSetName][yearIndexEnd][0],
            ],
        },
        {
            name: "Costal Inundation",
            color: {
                linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1,
                },
                stops: [
                    [0, coastalInnundation[0]],
                    [1, coastalInnundation[1]],
                ],
            },
            data: [
                analysis.properties[dataSetName][yearIndexStart][1],
                analysis.properties[dataSetName][yearIndexMortgage][1],
                analysis.properties[dataSetName][yearIndexEnd][1],
            ],
        },
        {
            name: "Subsidence",
            color: {
                linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1,
                },
                stops: [
                    [0, subsidence[0]],
                    [1, subsidence[1]],
                ],
            },
            data: [
                analysis.properties[dataSetName][yearIndexStart][5],
                analysis.properties[dataSetName][yearIndexMortgage][5],
                analysis.properties[dataSetName][yearIndexEnd][5],
            ],
        },
        {
            name: "Extreme Wind",
            color: {
                linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1,
                },
                stops: [
                    [0, extremeWind[0]],
                    [1, extremeWind[1]],
                ],
            },
            data: [
                analysis.properties[dataSetName][yearIndexStart][4],
                analysis.properties[dataSetName][yearIndexMortgage][4],
                analysis.properties[dataSetName][yearIndexEnd][4],
            ],
        },
        {
            name: "Forest Fire",
            color: {
                linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1,
                },
                stops: [
                    [0, forestFire[0]],
                    [1, forestFire[1]],
                ],
            },
            data: [
                analysis.properties[dataSetName][yearIndexStart][3],
                analysis.properties[dataSetName][yearIndexMortgage][3],
                analysis.properties[dataSetName][yearIndexEnd][3],
            ],
        },
    ]

    return series
}

const DrawColumnGraphCustom = function (
    analysis,
    startYear,
    mortgageYear,
    endYear,
    dataSetName,
    colorScheme
) {
    let graphHeight = "600px"
    let highChartsConfig = {
        chart: {
            type: "column",
            height: colorScheme === "CV" ? "515px" : graphHeight,
        },
        credits: {
            enabled: false,
        },
        title: {
            text: " ",
        },
        xAxis: {
            lineWidth: 2,
            lineColor: "#000000",
            categories: [startYear, mortgageYear, endYear],
            title: {
                text: "Year",
                style: { fontWeight: "bold", fontSize: 14 },
            },
        },
        yAxis: {
            min: 0,
            minRange: 0.001,
            gridLineWidth: 0,
            lineWidth: 2,
            lineColor: "#000000",
            gridLineColor: "transparent",
            title: {
                text: "VAR %",
                style: { fontWeight: "bold", fontSize: 14 },
            },
            labels: {
                formatter: function () {
                    return Highcharts.numberFormat(this.value * 100, 2, ".") + "%"
                },
            },
        },
        legend: {
            backgroundColor: "#E9EAEB",
            padding: 10,
        },
        navigation: {
            buttonOptions: {
                enabled: false,
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                pointWidth: 36,
            },
        },
        series: generateColumnSeries(
            analysis,
            startYear,
            mortgageYear,
            endYear,
            dataSetName,
            colorScheme
        ),
    }
    return highChartsConfig
}

const drawPieCharts = function (resultsData, title, year, startYear) {
    let highChartsConfig = {
        chart: {
            type: "pie",
        },
        credits: {
            enabled: false,
        },
        title: {
            text: title,
        },
        tooltip: {
            pointFormatter: function () {
                return (
                    "<b> " +
                    this.series.name +
                    "</b>: " +
                    Highcharts.numberFormat(this.y * 100, 2, ".") +
                    "%</b>"
                )
            },
        },
        navigation: {
            buttonOptions: {
                enabled: false,
            },
        },
        series: generatePieSeries(resultsData, year, startYear),
        colors: hazard_colours_array
    }
    return highChartsConfig
}

/**
 * Draws a standard chart
 *
 * @param {string} title - title of the chart
 * @param {string} yAxisTitle - title of the y axis
 * @param {Array} series - Data to be displayed
 * @param {boolean} exportChart - display export button
 * @param {Array} years - years of analysis
 *
 * @returns {Array} - Data series
 */
const drawHighCharts = function (title, yAxisTitle, series, exportChart = false, years) {
    let displayLegend = true
    let graphHeight = "500px"
    if (crConstants.ClimateValuation == true) {
        title = ""
        yAxisTitle = "Annual Value At Risk (%)"
        displayLegend = false
        graphHeight = "400px"
    } else {
        exportChart = true
    }
    let highChartsConfig = {
        chart: {
            zoomType: "xy",
            height: graphHeight,
            type: "area",
        },
        credits: {
            enabled: false,
        },
        title: {
            text: title,
        },
        xAxis: {
            title: {
                text: "Year",
                style: { fontWeight: "bold", fontSize: 18 },
            },
            labels: {
                style: {
                    fontWeight: "bold",
                    fontSize: 16,
                },
            },
            lineWidth: 2,
            lineColor: "#000000",
        },
        yAxis: {
            min: 0,
            minRange: 0.001,
            title: {
                text: yAxisTitle,
                style: { fontWeight: "bold", fontSize: 18 },
            },
            labels: {
                style: {
                    fontWeight: "bold",
                    fontSize: 16,
                },
                formatter: function () {
                    return Highcharts.numberFormat(this.value * 100, 2, ".") + "%"
                },
            },
            gridLineWidth: 0,
            lineWidth: 2,
            lineColor: "#000000",
            minTickInterval: 0.0001,
        },
        legend: {
            enabled: displayLegend,
        },
        tooltip: {
            pointFormatter: function () {
                return (
                    "<b> " +
                    this.series.name +
                    "</b>: " +
                    Highcharts.numberFormat(this.y * 100, 2, ".") +
                    "%</b>"
                )
            },
        },
        marker: {
            enabled: false,
        },
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false,
                },
            },
        },
        navigation: {
            buttonOptions: {
                enabled: exportChart,
            },
        },
        series: series,
        colors: hazard_colours_array
    }

    // Adjust for year list
    // TODO: Make this a proper list of years instead of an intervalled list
    highChartsConfig.plotOptions.series.pointStart = +years[0];
    highChartsConfig.plotOptions.series.pointInterval = years[1] - years[0];


    return highChartsConfig
}

/**
 * Draws a Max-to-Date VAR chart
 *
 * @param {object} props - { riskHazards, hazards, hazardLabels, years }
 *
 * @returns {object} - Chart
 */
const DrawMaxVARBreakdown = (props) => {
    const { riskHazards, hazards, hazardLabels, years } = props

    return (
        <ReactHighcharts
            config={drawHighCharts(
                "Contribution of each hazard to Max-to-Date Value At Risk (%)",
                "Max VAR (%) per Hazard",
                getDataForCharts({
                    results: riskHazards,
                    maxToDate: true,
                    hazards: hazards,
                    hazardLabels: hazardLabels,
                }),
                false,
                years
            )}
            className="RiskFraction "
        />
    )
}
DrawMaxVARBreakdown.propTypes = {
    riskHazards: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    hazards: PropTypes.array,
    hazardLabels: PropTypes.array,
    years: PropTypes.array,
}

// TODO: Change to only send the parts of the Analysis that is needed
// and not the full analysis
/**
 * Draws a VAR chart for CV
 *
 * @param {object} analysis - analysis data
 * @param {number} startYear - Start of analysis year
 * @param {number} mortgageYear - length of the mortgage in years
 * @param {number} endOfMortgageTerm - year that the mortgage ends
 * @param {Array} riskHazards - analysis results data
 */
class CVDrawMaxVARBreakdown extends React.Component {
    _BreakdownGraphReady = false

    static propTypes = {
        analysis: PropTypes.object.isRequired,
        startYear: PropTypes.number.isRequired,
        mortgageYear: PropTypes.number.isRequired,
        endOfMortgageTerm: PropTypes.number.isRequired,
        dataSourceName: PropTypes.string.isRequired,
        source: PropTypes.string,
        onAssetRenderComplete: PropTypes.func,
        colorScheme: PropTypes.string,
    }

    constructor(props) {
        super(props)
        autoBind(this)
        this.BreakdownGraphReady = this.BreakdownGraphReady.bind(this)
    }

    BreakdownGraphReady() {
        this._BreakdownGraphReady = true
        if (this.props.source && this._BreakdownGraphReady)
            this.props.onAssetRenderComplete(this.props.source)
    }
    render() {
        const {
            analysis,
            startYear,
            mortgageYear,
            endOfMortgageTerm,
            dataSourceName,
            colorScheme,
        } = this.props

        return (
            <ReactHighcharts
                config={DrawColumnGraphCustom(
                    analysis,
                    startYear,
                    mortgageYear,
                    endOfMortgageTerm,
                    dataSourceName,
                    colorScheme
                )}
                domProps={{ id: "chartId" }}
                callback={this.BreakdownGraphReady}
                className="RiskFraction "
            />
        )
    }
}

CVDrawMaxVARBreakdown.defaultProps = {
    colorScheme: "EasyXDI",
}

/**
 * Draws a VAR chart
 *
 * @param {Array} riskHazards - analysis results data
 * @param {boolean} exportChart - determines is export button is shown (optional)
 */
class DrawAnnualVARBreakdown extends React.Component {
    static propTypes = {
        years: PropTypes.array.isRequired,
        exportChart: PropTypes.bool,
        riskHazards: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        hazards: PropTypes.array,
        hazardLabels: PropTypes.array,
    }

    render() {
        const { riskHazards, exportChart, hazards, hazardLabels, years } = this.props

        return (
            <ReactHighcharts
                config={drawHighCharts(
                    "Contribution of each hazard to Annual Value At Risk (%)",
                    "Annual VAR (%) per Hazard",
                    getDataForCharts({
                        results: riskHazards,
                        maxToDate: false,
                        hazards: hazards,
                        hazardLabels: hazardLabels,
                    }),
                    exportChart,
                    years,
                )}
                className="RiskFraction "
            />
        )
    }
}

// TODO: change to use specific data from analysis
class DrawMaxFPBreakdown extends React.Component {
    static propTypes = {
        years: PropTypes.array.isRequired,
        failureHazards: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        source: PropTypes.string,
        onAssetRenderComplete: PropTypes.func,
    }

    constructor(props) {
        super(props)
        autoBind(this)
        this.MaxFPGraphReady = this.MaxFPGraphReady.bind(this)
    }

    MaxFPGraphReady() {
        if (this.props.source) this.props.onAssetRenderComplete(this.props.source)
    }

    render() {
        const { failureHazards, years } = this.props

        return (
            <ReactHighcharts
                domProps={{ id: "chartId" }}
                callback={this.MaxFPGraphReady}
                config={drawHighCharts(
                    "Contribution of each hazard to Max-to-Date Failure Probability",
                    "Max Failure Probability (%)",
                    getDataForCharts({
                        results: failureHazards,
                        maxToDate: true,
                    }),
                    false,
                    years,
                )}
                className="RiskFraction "
            />
        )
    }
}

// TODO: change to use specific data from analysis
class DrawAnnualFPBreakdown extends React.Component {
    static propTypes = {
        years: PropTypes.array.isRequired,
        failureHazards: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        source: PropTypes.string,
        onAssetRenderComplete: PropTypes.func,
        exportChart: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        autoBind(this)
        this.AnnualFPBGraphReady = this.AnnualFPBGraphReady.bind(this)
    }

    AnnualFPBGraphReady() {
        if (this.props.source) this.props.onAssetRenderComplete(this.props.source)
    }

    render() {
        const { failureHazards, exportChart, years } = this.props

        return (
            <ReactHighcharts
                domProps={{ id: "chartId1" }}
                callback={this.AnnualFPBGraphReady}
                config={drawHighCharts(
                    "Contribution of each hazard to Annual Failure Probability",
                    "Annual Failure Probability (%)",
                    getDataForCharts({
                        results: failureHazards,
                        maxToDate: false,
                    }),
                    exportChart,
                    years,
                )}
                className="RiskFraction "
            />
        )
    }
}

// TODO: change to use specific data from analysis
class DrawPieVARBreakdown extends React.Component {
    static propTypes = {
        startYear: PropTypes.number.isRequired,
        riskHazards: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        year: PropTypes.number,
        title: PropTypes.string,
    }

    render() {
        const { riskHazards, year, startYear } = this.props
        let title = this.props.title || year

        return (
            <ReactHighcharts
                config={drawPieCharts(riskHazards, title, year, startYear)}
                className="RiskFraction"
            />
        )
    }
}

// TODO: change to use specific data from analysis
class DrawPieFPBreakdown extends React.Component {
    static propTypes = {
        startYear: PropTypes.number.isRequired,
        failureHazards: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        year: PropTypes.number.isRequired,
        title: PropTypes.string,
    }

    render() {
        const { failureHazards, year, startYear } = this.props
        let title = this.props.title || year

        return (
            <ReactHighcharts
                config={drawPieCharts(failureHazards, title, year, startYear)}
                className="RiskFraction "
            />
        )
    }
}

// TODO: change to use specific data from analysis
class DrawVARBreakdownCustom extends React.Component {
    static propTypes = {
        years: PropTypes.array.isRequired,
        source: PropTypes.string,
        onAssetRenderComplete: PropTypes.func,
        analysis: PropTypes.object.isRequired,
        startingHazardIndex: PropTypes.string.isRequired,
        hazardsLimit: PropTypes.string.isRequired,
    }

    _VARGraphReady = false
    _FPGraphReady = false
    constructor(props) {
        super(props)
        autoBind(this)
        this.VARGraphReady = this.VARGraphReady.bind(this)
        this.FPGraphReady = this.FPGraphReady.bind(this)
    }

    VARGraphReady() {
        this._VARGraphReady = true
        if (this.props.source && this._FPGraphReady && this._VARGraphReady)
            this.props.onAssetRenderComplete(this.props.source)
    }
    FPGraphReady() {
        this._FPGraphReady = true
        if (this.props.source && this._FPGraphReady && this._VARGraphReady)
            this.props.onAssetRenderComplete(this.props.source)
    }
    render() {
        const { analysis, startingHazardIndex, hazardsLimit, years } = this.props

        return (
            <React.Fragment>
                <ReactHighcharts
                    domProps={{ id: "chartId" }}
                    callback={this.VARGraphReady}
                    config={drawHighCharts(
                        "Contribution of each hazard to Max-to-Date and Annual Value At Risk (%)",
                        "Max VAR (%) per Hazard",
                        getDataForCharts(
                            { results: analysis.properties.riskHazards, maxToDate: true },
                            startingHazardIndex,
                            hazardsLimit,
                            "#E0418E"
                        ).concat(
                            getDataForCharts(
                                { results: analysis.properties.riskHazards, maxToDate: false },
                                startingHazardIndex,
                                hazardsLimit
                            )
                        ),
                        false,
                        years
                    )}
                    className="RiskFraction "
                />

                <ReactHighcharts
                    domProps={{ id: "chartId2" }}
                    callback={this.FPGraphReady}
                    config={drawHighCharts(
                        "Contribution of each hazard to Max-to-Date and Annual Failure Probability",
                        "Annual Failure Probability (%)",
                        getDataForCharts(
                            { results: analysis.properties.failureHazards, maxToDate: true },
                            startingHazardIndex,
                            hazardsLimit,
                            "#E0418E"
                        ).concat(
                            getDataForCharts(
                                { results: analysis.properties.failureHazards, maxToDate: false },
                                startingHazardIndex,
                                hazardsLimit
                            )
                        ),
                        false,
                        years
                    )}
                    className="RiskFraction "
                />
            </React.Fragment>
        )
    }
}
class CVDrawVARBreakdownCustom extends React.Component {
    static propTypes = {
        years: PropTypes.array.isRequired,
        source: PropTypes.string,
        onAssetRenderComplete: PropTypes.func,
        analysis: PropTypes.object.isRequired,
        startingHazardIndex: PropTypes.string.isRequired,
        hazardsLimit: PropTypes.string.isRequired,
        color: PropTypes.string,
        colorScheme: PropTypes.string,
    }

    _VARGraphReady = false
    constructor(props) {
        super(props)
        autoBind(this)
        this.VARGraphReady = this.VARGraphReady.bind(this)
    }

    VARGraphReady() {
        this._VARGraphReady = true
        if (this.props.source && this._VARGraphReady)
            this.props.onAssetRenderComplete(this.props.source)
    }
    render() {
        const {
            analysis,
            startingHazardIndex,
            hazardsLimit,
            color,
            years,
            colorScheme,
        } = this.props

        let configureScheme
        if (colorScheme === "CV") {
            configureScheme = getDataForCharts(
                { results: analysis.properties.riskHazards, maxToDate: false },
                startingHazardIndex,
                hazardsLimit,
                colorScheme
            )
        } else {
            configureScheme = getDataForCharts(
                { results: analysis.properties.riskHazards, maxToDate: true },
                startingHazardIndex,
                hazardsLimit
            ).concat(
                getDataForCharts(
                    { results: analysis.properties.riskHazards, maxToDate: false },
                    startingHazardIndex,
                    hazardsLimit,
                    color
                )
            )
        }
        return (
            <React.Fragment>
                <ReactHighcharts
                    domProps={{ id: "chartId" }}
                    callback={this.VARGraphReady}
                    config={drawHighCharts(
                        "Contribution of each hazard to Max-to-Date and Annual Value At Risk (%)",
                        "Max VAR (%) per Hazard",
                        configureScheme,
                        false,
                        years
                    )}
                    className="RiskFraction "
                />
            </React.Fragment>
        )
    }
}

CVDrawVARBreakdownCustom.defaultProps = {
    colorScheme: "EasyXDI",
}
const DrawMaxFPBreakdownCustom = async (analysis) => {
    console.assert(typeof analysis === "object", "analysis not an object")

    return drawHighCharts(
        "Contribution of each hazard to Max-to-Date Failure Probability",
        "Max Failure Probability (%)",
        getDataForCharts({ results: analysis.properties.failureHazards, maxToDate: true }),
        false,
        analysis.inputs.scenario.startYear
    )
}

const DrawAnnualFPBreakdownCustom = async (analysis) => {
    console.assert(typeof analysis === "object", "analysis not an object")

    return drawHighCharts(
        "Contribution of each hazard to Annual Failure Probability",
        "Annual Failure Probability (%)",
        getDataForCharts({ results: analysis.properties.failureHazards, maxToDate: false }),
        false,
        analysis.inputs.scenario.startYear
    )
}

const DrawPieVARBreakdownCustom = async (analysis, year) => {
    console.assert(typeof analysis === "object", "analysis not an object")
    console.assert(typeof year === "number", "year is not a number")

    let title = year
    return drawPieCharts(analysis.properties.riskHazards, title, year, analysis.inputs.scenario.startYear)
}

const DrawPieFPBreakdownCustom = async (analysis, year) => {
    console.assert(typeof analysis === "object", "analysis not an object")
    console.assert(typeof year === "number", "year is not a number")

    let title = year
    return drawPieCharts(analysis.properties.failureHazards, title, year, analysis.inputs.scenario.startYear)
}

export {
    CVDrawMaxVARBreakdown, CVDrawVARBreakdownCustom, DrawAnnualFPBreakdown, DrawAnnualFPBreakdownCustom, DrawAnnualVARBreakdown,
    DrawMaxFPBreakdown, DrawMaxFPBreakdownCustom, DrawMaxVARBreakdown, DrawPieFPBreakdown, DrawPieFPBreakdownCustom, DrawPieVARBreakdown, DrawPieVARBreakdownCustom, DrawVARBreakdownCustom
}

