import React, { useState } from "react";
import PropTypes from "prop-types";

import { ToolbarButton } from "./ToolbarButton.jsx";

export const ToolbarSelector = ({
    children,
    buttonOnClick,
    buttonTitle,
    isOpen,
    onChange,
    options,
    selectId,
    selectTitle
}) => {
    const [selected, setSelected] = useState([]);

    /**
     * @param {*} param0 Event.
     */
    function callback({ target: { selectedOptions } }) {
        const data = Array.from(selectedOptions).map(option => option.value);
        setSelected(data);
        onChange(data);
    }

    return <div className="toolbar__selector">
        <ToolbarButton active={isOpen} fullWidth={true} onClick={buttonOnClick} title={buttonTitle}>
            <i className="titles-select">{children}</i>
        </ToolbarButton>
        {isOpen && <select multiple id={selectId} value={selected} title={selectTitle} onChange={callback}>
            {options.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
        </select>}
    </div>;
}

ToolbarSelector.propTypes = {
    children: PropTypes.any,
    buttonOnClick: PropTypes.func,
    buttonTitle: PropTypes.string,
    isOpen: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array,
    selectId: PropTypes.string,
    selectTitle: PropTypes.string,
}