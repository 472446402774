import React from "react"
import autoBind from "react-autobind"
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    Button,
    Form,
    Accordion,
    Card,
    useAccordionToggle,
    OverlayTrigger,
    Popover,
    InputGroup,
    FormControl,
    Tabs,
    Tab,
} from "react-bootstrap"
import NumberFormat from "react-number-format"
import { MdKeyboardArrowRight } from "react-icons/md"
import classNames from "classnames"
import _ from "lodash"
import SimpleSchema from "simpl-schema"
import immutable from "immutable"
import { observer } from "mobx-react"
import PropTypes from "prop-types"

import adaptState from "./adaptState"
import Loading from "../Loading.jsx"
import { getArchetypeData } from "../archetype.js"
import NiceSelect from "../UI-Utils/NiceSelect.jsx"
import {
    AdaptationTIPChart,
    AdaptationNPVChart,
    AdaptHazardsChart,
    AdaptFailureChart,
} from "./AdaptationCharts.jsx"
import { checkProp, checkNested } from "../utils"
import { calculateNetPresentValue, calculatePresentValue } from "../functions/finance"

import "./Adaptations.scss"
import "./AdaptationOptions.scss"
import { buildYearListFromScenario } from "../functions/scenario_utils"

// TODO: Whole file needs a massive clean up.

let finalNPV = [0, 0, 0]

SimpleSchema.extendOptions([
    "machineName",
    "description",
    "disableThousandsGrouping",
    "allowedValueLabels",
])
const schemaTypeMap = {
    real: Number,
    integer: SimpleSchema.Integer,
    boolean: Boolean,
    string: String,
}

const omFieldsInclude = [
    "buildYear",
    "threshold",
    "heatThreshold",
    "windThreshold",
    "fireProtection",
    "foundationDesign",
    "lifetime",
    "heightAboveGround",
    "elevation",
]
// For climate val: const omFieldsInclude = [
//     'buildYear', 'equivalentLandValue', "heatThreshold", "windThreshold",
//     "marketValue", "lifetime", "replacementCost", "heightAboveGround", "elevation" ];
// TODO: These should be moved to acrobase as fields
const windSpeedThresholds_labels = [20, 50, 100, 250, 500, 1000, 2000]
const heatThresholds_labels = [20, 50, 100, 250, 500, 1000, 2000]

@observer
class Adaptations extends React.Component {
    // TODO: Replace with cr_schemas and
    // https://github.com/dowjones/react-json-schema-proptypes
    static propTypes = {
        materials: PropTypes.object, // dictionary of all available materials
        analysis: PropTypes.shape({
            _id: PropTypes.string,
            geometry: PropTypes.object,
            metadata: PropTypes.object,
            properties: PropTypes.object, // TODO: Delete this. Not in Risk V2
            inputs: PropTypes.object,
            meta: PropTypes.object, // TODO: Delete this? Globe specific
        }),
        analysisBaseline: PropTypes.object,
        currentID: PropTypes.string,
        submitText: PropTypes.string,
        onSubmit: PropTypes.func, // This is runChangedParameters
        onCancel: PropTypes.func,
        onSaveAdaptation: PropTypes.func,
        hideFieldsWithNoDefaultValue: PropTypes.bool,
        displayCharts: PropTypes.bool, // turns off charts for tests
        currentValues: PropTypes.object, // baseline analysis.inputs.asset.properties
    }

    static defaultProps = {
        displayCharts: true,
    }

    constructor(props) {
        super(props)

        this.state = {
            valid: true,
            values: {},
            currentValues: {},
            defaultValues: {},
            dirtyValues: immutable.Set(),
            loading: true,
            noChanges: true,
            omSchema: {},
            currentElementsEnabled: immutable.Set(),
            elementsEnabled: immutable.Set(),
            dirtyElements: immutable.Set(),
            currentMaterials: {},
            defaultMaterials: {},
            materials: {},
            dirtyMaterials: immutable.Set(),
            analysisId: null,
            hideSaveButton: {},
            oldData: undefined,

            discountRate: 0.05,
            NPVTerm: 30,
            assetReplacementCost: 1000000,
            CAPEX: 0,
            adaptationYear: 2030,

            NPVBaseline: 0,
            NPVSecGraph01: 0,
            NPVSecGraph02: 0,
            NPVSecGraph03: 0,

            oldP1O1: 0,
            oldP1O2: 0,
            oldP1O3: 0,
            oldP2O1: 0,
            oldP2O2: 0,
            oldP2O3: 0,
            oldP3O1: 0,
            oldP3O2: 0,
            oldP3O3: 0,

            boolScenario: false,
            // Following States are used to determine if path/opt 2 or 3 should be added

            // // True for pathway2 false for pathway3
            // displayingPathwayState: true,
            // // true for option 2, false for option 3
            // displayingOptionState: true,

            // state to control the visibility of all pathways and options
            openCard: "0",
            openPathCard: "pathway1",
            openOptCard: "option1",
            pathOptVisible: {
                pathway1: {
                    pathway: true,
                    option1: true,
                    option2: false,
                    option3: false,
                },
                pathway2: {
                    pathway: false,
                    option1: false,
                    option2: false,
                    option3: false,
                },
                pathway3: {
                    pathway: false,
                    option1: false,
                    option2: false,
                    option3: false,
                },
            },

            /* Purpose of following state is to wait for 1.0-1.2 seconds, when
            user delete any option.  Because It is taking 0.6 seconds approx to
            update the line which is used in calculating the NPV for pathways. */
            stateForWaiting: 0,
        }
        autoBind(this)

        // TODO: Can this be merged with/replace this.state.materials? They are different shapes
        this.state.materials_orig = this.props.materials

        const years = buildYearListFromScenario(this.props.analysisBaseline.inputs.scenario)
        const startYear = this.props.analysisBaseline.inputs.scenario.startYear
            || years[0]
            || 1990

        this.startIndex = years.indexOf(startYear)
    }

    componentWillUnmount() {
        adaptState.reset()
    }

    /**
     * This function is filling Second Graph on the load of Application.
     *
     * @param {object} savedAdaptations - saved adaptations attached to analysis
     */
    dataOnApplicationLoad(savedAdaptations) {
        var options = ["option1", "option2", "option3"]
        var pathways = ["pathway1", "pathway2", "pathway3"]

        // Value that is coming by running latest option will be stored in following array
        // according to it's Pathway's value.Each index will show latest submitted option.
        let tempNPV = [0, 0, 0]
        var dataToBeAdded = ""
        // var optionToBeAdded = "" // FIXME: delete or use?
        var capexToBeAdded = 0
        // TODO: Use maps or lodash loops?
        for (let i = 0; i < pathways.length; i++) {
            for (let j = 0; j < options.length; j++) {
                if (savedAdaptations !== undefined) {
                    for (
                        // TODO: remove this conditional? still needed?
                        let index = this.props.analysis.inputs.scenario.startYear -
                              savedAdaptations.adaptationOptions.adaptationYear;
                        index < 81;
                        index++
                    ) {
                        // CAPEX will be added only, if index is equal to the Adaptation Year
                        if (
                            index + 1 ==
                            parseInt(this.state.adaptationYear) -
                                this.props.analysis.inputs.scenario.startYear
                        ) {
                            capexToBeAdded = parseFloat(
                                savedAdaptations.adaptationOptions.discountRate
                            )
                        }
                        tempNPV[i] =
                            tempNPV[i] +
                            (savedAdaptations.UpdatedLine[index] *
                                parseFloat(savedAdaptations.adaptationOptions.assetCost) +
                                capexToBeAdded) /
                                ((1 + parseFloat(savedAdaptations.adaptationOptions.discountRate)) *
                                    (index + 1))
                        dataToBeAdded = savedAdaptations.adaptationOptions.pathwayNumber
                        // optionToBeAdded = savedAdaptations.adaptationOptions.optionNumber
                    }
                }
            }
        }

        if (dataToBeAdded == "pathway1") {
            // Following code is inserting the value(s) of saved options of pathway -1 in adaptState
            finalNPV[0] = tempNPV[0]
        } else if (dataToBeAdded == "pathway2") {
            // Following code is inserting the value(s) of saved options of pathway -2 in adaptState
            finalNPV[1] = tempNPV[1]
        } else if (dataToBeAdded == "pathway3") {
            // Following code is inserting the value(s) of saved options of pathway -3 in adaptState
            finalNPV[2] = tempNPV[2]
        }
    }

    async componentDidMount() {
        // TODO: I don't think ANY of this stuff should be in adaptState...
        adaptState["npv"] = this.state.NPVTerm
        adaptState["discountRate"] = this.state.discountRate

        this.setState({
            NPVBaseline: 0,
            NPVSecGraph01: 0,
            NPVSecGraph02: 0,
            NPVSecGraph03: 0,
        })
        // Following code is for the NPV value of baseline.
        const endIndex = this.startIndex + parseInt(adaptState["npv"]);
        const tips = this.props.analysisBaseline.properties.totalRisk
            .slice(this.startIndex, endIndex)
            .map((value) => value * parseFloat(this.state.assetReplacementCost));

        this.setState(
            {
                NPVBaseline: calculateNetPresentValue(tips, parseFloat(adaptState["discountRate"])),
            },
            () => {}
        )

        // TODO: These should not be in adaptState, I don't think. If they
        // should, they should be added to adaptState.js
        // TODO: create these programatically?
        adaptState["initialP1O1_onLoad"] = 0
        adaptState["initialP1O2_onLoad"] = 0
        adaptState["initialP1O3_onLoad"] = 0
        adaptState["initialP2O1_onLoad"] = 0
        adaptState["initialP2O2_onLoad"] = 0
        adaptState["initialP2O3_onLoad"] = 0
        adaptState["initialP3O1_onLoad"] = 0
        adaptState["initialP3O2_onLoad"] = 0
        adaptState["initialP3O3_onLoad"] = 0
        var currentId = this.props.currentID
        if (currentId) {
            var totalRecord = adaptState.analyses
            var helpingObject = []
            var sendingObject = []
            for (let key in totalRecord) {
                if (currentId == totalRecord[key].parentAnalysisId) {
                    sendingObject[currentId] = totalRecord[key]
                    var pathway = totalRecord[key].adaptationOptions.pathwayNumber
                    var option = totalRecord[key].adaptationOptions.optionNumber

                    // TODO replace this with lodash.merge
                    if (helpingObject[currentId] !== undefined) {
                        if (helpingObject[currentId][pathway] !== undefined) {
                            helpingObject[currentId][pathway] = {
                                ...helpingObject[currentId][pathway],
                                [option]: totalRecord[key],
                            }
                        } else {
                            let level0 = { [option]: totalRecord[key] }
                            helpingObject[currentId] = {
                                ...helpingObject[currentId],
                                [pathway]: level0,
                            }
                        }
                    } else {
                        let level0 = { [option]: totalRecord[key] }
                        let level1 = { [pathway]: level0 }
                        helpingObject = { ...helpingObject, [currentId]: level1 }
                    }
                }
            }
            helpingObject = _.cloneDeep(helpingObject)

            // TODO: Should this be in adaptState?
            adaptState.helpingObjectForDefaultValues = helpingObject

            this.setState({
                oldData: helpingObject,
            })

            var optionsList = ["option1", "option2", "option3"]
            var pathwaysList = ["pathway1", "pathway2", "pathway3"]

            // Iterating saved adaptations
            finalNPV = [0, 0, 0]
            // Dealing with Graph No. 02:
            for (let i = 0; i < pathwaysList.length; i++) {
                for (let j = 0; j < optionsList.length; j++) {
                    if (helpingObject[this.props.currentID] !== undefined) {
                        if (helpingObject[this.props.currentID][pathwaysList[i]]) {
                            if (
                                helpingObject[this.props.currentID][pathwaysList[i]][optionsList[j]]
                            ) {
                                this.dataOnApplicationLoad(
                                    helpingObject[this.props.currentID][pathwaysList[i]][
                                        optionsList[j]
                                    ]
                                )
                            }
                        }
                    }
                }
            }
            this.setState({
                NPVSecGraph01: finalNPV[0],
                NPVSecGraph02: finalNPV[1],
                NPVSecGraph03: finalNPV[2],
            })
        }

        const { analysis, archetype } = this.props
        // TODO: This data needs to be available somewhere! not currently in Globe.
        const { fields, elements } = await getArchetypeData(
            archetype || analysis.inputs.archetype.acronym
        )

        const fieldsFiltered = _.pick(fields, omFieldsInclude)
        fieldsFiltered.buildYear.disableThousandsGrouping = true
        fieldsFiltered.lifetime.disableThousandsGrouping = true

        // TODO: Move these default options to acrobase.
        if (
            checkNested(analysis, "inputs", "asset", "properties", "windThreshold") &&
            typeof analysis.inputs.asset.properties.windThreshold == "number"
        ) {
            if (!windSpeedThresholds_labels.includes(1 / analysis.inputs.asset.properties.windThreshold)) {
                // Add the windspeed threshold from the analysis inputs, if it doesn't already exist
                windSpeedThresholds_labels.push(1 / analysis.inputs.asset.properties.windThreshold)
                windSpeedThresholds_labels.sort((a, b) => a - b)
            }
            fieldsFiltered.windThreshold.default = analysis.inputs.asset.properties.windThreshold
        } else {
            // blank windThreshold implies Ana gets it from a context layer.
            // We use 'NULL' here because react-select doesn't play nicely with null
            windSpeedThresholds_labels.unshift("NULL")
            fieldsFiltered.windThreshold.default = "NULL"
        }

        fieldsFiltered.windThreshold.allowedValues = windSpeedThresholds_labels.map((wt) =>
            wt == "NULL" ? "NULL" : 1 / wt
        )
        fieldsFiltered.windThreshold.allowedValueLabels = windSpeedThresholds_labels.map((wt) =>
            wt == "NULL" ? "From regulations" : `1 in ${wt}`
        )

        // set the current value of heat threshold using return frequency
        const heat_rf = analysis.inputs?.asset?.properties?.thresholds?.heat?.return_frequency
        if (heat_rf) {
            if (heat_rf && typeof heat_rf == "number") {
                if (!heatThresholds_labels.includes(1 / heat_rf)) {
                    // Add the heat threshold from the analysis inputs, if it doesn't already exist
                    heatThresholds_labels.push(1 / heat_rf)
                    heatThresholds_labels.sort((a, b) => a - b)
                }
                fieldsFiltered.heatThreshold.default = heat_rf
            } else {

                // blank windThreshold implies Ana gets it from a context layer.
                // We use 'NULL' here because react-select doesn't play nicely with null
                heatThresholds_labels.unshift("NULL")
                fieldsFiltered.heatThreshold.default = "NULL"
            }

            fieldsFiltered.heatThreshold.allowedValues = heatThresholds_labels.map((wt) =>
                wt == "NULL" ? "NULL" : 1 / wt
            )
            fieldsFiltered.heatThreshold.allowedValueLabels = heatThresholds_labels.map((wt) =>
                wt == "NULL" ? "From regulations" : `1 in ${wt}`
            )
        }

        // Replace field defaults from acrobase with the asset data, if provided.
        if (checkNested(analysis, "inputs", "asset", "properties")) {
            const analysisProps = analysis.inputs.asset.properties
            for (let prop of ["heightAboveGround", "buildYear"]) {
                if (checkProp(analysisProps, prop)) {
                    fieldsFiltered[prop].default = analysisProps[prop]
                }
            }
            if (
                checkProp(analysisProps, "totalHeight") &&
                checkProp(analysisProps, "heightAboveGround")
            ) {
                fields.elevation.default =
                    analysisProps.totalHeight - analysisProps.heightAboveGround
            }
        }

        const omSchema = _.mapValues(fieldsFiltered, (fieldDef, fieldName) => {
            let allowedValues =
                fieldDef.allowedValues || (fieldDef.type == "boolean" ? [false, true] : null)
            let allowedValueLabels = fieldDef.allowedValueLabels
            if (allowedValues && allowedValues.length && typeof allowedValues[0] == "object") {
                // Convert value/label objects to appropriate arrays.
                if (!allowedValueLabels) {
                    allowedValueLabels = allowedValues.map((v) => v.label)
                }
                allowedValues = allowedValues.map((v) => v.value)
            }

            return {
                machineName: fieldName,
                label: fieldDef.label,
                type: schemaTypeMap[fieldDef.type],
                description:
                    fieldDef.description.substring(0, 1).toLocaleUpperCase() +
                    fieldDef.description.substring(1) +
                    (fieldDef.description.endsWith(".") ? "" : "."),
                allowedValues,
                allowedValueLabels,
                required: true,
                min: fieldDef.hasOwnProperty("minimum") ? fieldDef.minimum : undefined,
                max: fieldDef.hasOwnProperty("maximum") ? fieldDef.maximum : undefined,
                disableThousandsGrouping: !!fieldDef.disableThousandsGrouping,
            }
        })

        this.omSchemaSS = new SimpleSchema(omSchema, {
            clean: {
                filter: true,
                autoConvert: true,
                removeEmptyStrings: true,
                trimStrings: true,
                getAutoValues: true,
                removeNullsFromArrays: true,
            },
        })

        this.validationCtx = this.omSchemaSS.newContext()

        // Generally, "Values" or "DesignValues" refers to the non-element-material components of the forms
        const currentValues = {
            ..._.mapValues(fieldsFiltered, (def) => def.default),
            ...(this.props.currentValues || {}),
        }

        const defaultValues = currentValues
        const currentElementsEnabled = immutable.Set(
            checkNested(analysis, "inputs", "archetype", "specifications") ||
                analysis.inputs.asset.properties.elementMaterials
                ?
                Object.keys(analysis.inputs.asset.properties.elementMaterials) || Object.keys(
                    analysis.inputs.archetype.specifications
                )
                : elements.map((e) => e.name)
        )

        // Set up materials dictionaries for Element material selection section.
        let currentMaterials = {}
        let providedMaterials = {}
        // TODO: Not sure if this is the best place to do this.
        if (checkNested(analysis, "inputs", "asset", "properties", "elementMaterials")) {
            providedMaterials = analysis.inputs.asset.properties.elementMaterials
            _.map(providedMaterials, (val, ele) => {
                currentMaterials[ele] = val.id + "_orig"
            })

            // Re-key providedMaterials by new material name.
            // TODO: fix problems with _.reduce and replace uglified code below
            // providedMaterials = _.reduce(providedMaterials, (out, val) => {
            //     console.log(out)
            //     console.log(val)
            //     return {
            //         ...out,
            //         [val.id + "_orig"]: {
            //             ...val,
            //             name: `${val.name}*`,
            //         },
            //     };
            // });

            // create temp object, (why? its late)
            const providedMaterialsObj = {}

            Object.keys(providedMaterials).forEach((val) => {
                const newName = providedMaterials[val].id + "_orig"

                providedMaterialsObj[newName] = {
                    ...providedMaterials[val],
                    name: `${providedMaterials[val].name}*`,
                }
            })
            // set new object as providedMaterials to avoid changing everywhere
            providedMaterials = providedMaterialsObj
        } else if (checkNested(analysis, "inputs", "asset", "properties", "materialsDict")) {
            providedMaterials = analysis.inputs.asset.properties.materialsDict
            _.map(providedMaterials, (val, ele) => {
                currentMaterials[ele] = ele + "_orig"
            })

            // re-map as above, but with fake material names
            // TODO: We can probably delete this... Most things that exist in repo
            // with inputs should use the above format instead..
            providedMaterials = _.reduce(
                providedMaterials,
                (out, val, ele) => {
                    if (checkProp(val, "coastal_inundation")) {
                        val["inundation"] = val["coastal_inundation"]
                        delete val["coastal_inundation"]
                    }
                    if (checkProp(val, "freeze_thaw")) {
                        val["freeze-thaw"] = val["freeze_thaw"]
                        delete val["freeze_thaw"]
                    }
                    return {
                        ...out,
                        [ele + "_orig"]: {
                            name: `Provided ${ele} default`,
                            failure_coefficients: val,
                        },
                    }
                },
                {}
            )
        }

        // Overwrite materials if they've already been selected,
        // and fall back on archetype defaults.
        currentMaterials = {
            ...Object.values(elements).reduce((cm, el) => {
                return { ...cm, [el.name]: el["default material"] }
            }, {}),
            ...currentMaterials,
            ...(this.props.currentMaterials || {}),
        }
        // Duplicate these values to show in the middle column.
        const defaultMaterials = currentMaterials

        adaptState.currentAnalysisId = this.props.analysis._id
        this.setState({
            analysisId: this.props.analysis.groupId,
            omSchema,
            values: this.props.newValues || currentValues,
            currentValues,
            defaultValues,
            loading: false,
            elements,
            currentElementsEnabled: currentElementsEnabled,
            elementsEnabled: currentElementsEnabled,
            materials: this.props.newMaterials || currentMaterials,
            currentMaterials,
            defaultMaterials,
            providedMaterials,
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.analysis) return

        if (this.props.valueUsedToTriggerChildAgain == 1) {
            this.componentDidMount()
        }

        if (this.state.loading || !nextProps.analysis) return

        this.updatingFromNewProps = true

        const currentValues = {
            ...this.state.currentValues,
            ...(nextProps.currentValues || {}),
        }
        const values = _.mapValues(this.state.values, (val, field) =>
            this.state.dirtyValues.has(field) ? val : currentValues[field]
        )

        var pathwayNumber = adaptState.currentPathway
        var optionNumber = adaptState.currentOption

        if (nextProps.analysis._id !== null && nextProps.analysis._id !== undefined) {
            adaptState.currentAnalysisId = nextProps.analysis._id
        }

        const analysisId = adaptState.currentAnalysisId

        // TODO: There is definitely a better way to do this. Use Lodash.merge
        if (pathwayNumber !== null && optionNumber !== null) {
            if (adaptState.adaptation[analysisId] !== undefined) {
                if (adaptState.adaptation[analysisId][pathwayNumber] !== undefined) {
                    if (
                        adaptState.adaptation[analysisId][pathwayNumber][optionNumber] !== undefined
                    ) {
                        adaptState.adaptation[analysisId][pathwayNumber][optionNumber] =
                            nextProps.analysis
                    } else {
                        var data = {
                            ...adaptState.adaptation[analysisId][pathwayNumber],
                            [optionNumber]: nextProps.analysis,
                        }
                        adaptState.adaptation[analysisId][pathwayNumber] = data
                    }
                } else {
                    var data = { [optionNumber]: nextProps.analysis }
                    adaptState.adaptation[analysisId] = {
                        ...adaptState.adaptation[analysisId],
                        [pathwayNumber]: data,
                    }
                }
            } else {
                var data = { [optionNumber]: nextProps.analysis }
                var level1 = { [pathwayNumber]: data }
                adaptState.adaptation[analysisId] = level1
            }
        }

        const currentElementsEnabled = immutable.Set(
            checkNested(nextProps.analysis, "inputs", "asset", "elements")
                ? Object.keys(nextProps.analysis.inputs.asset.elements)
                : Object.values(this.state.elements).map((e) => e.name)
        )

        const elementsEnabled = immutable.Set(
            Object.values(this.state.elements)
                .map((e) => e.name)
                .filter((e) =>
                    this.state.dirtyElements.has(e)
                        ? this.state.elementsEnabled.has(e)
                        : currentElementsEnabled.has(e)
                )
        )

        const currentMaterials = {
            ...this.state.currentMaterials,
            ...(nextProps.currentMaterials || nextProps.analysis.inputs.asset.elements || {}),
        }
        const materials = _.mapValues(this.state.materials, (val, el) =>
            this.state.dirtyMaterials.has(el) ? val : currentMaterials[el]
        )

        this.setState({
            currentValues,
            values,
            currentElementsEnabled,
            elementsEnabled,
            currentMaterials,
            materials,
            noChanges: !this.isChanged({
                currentValues,
                values,
                currentElementsEnabled,
                elementsEnabled,
                currentMaterials,
                materials,
            }),
        })

        // work-around: prevent this.updateValue() from running due to
        // non-user-input triggered onValuesChange event in NumberFormat
        // components.
        // TODO: fix this
        window.setTimeout(() => (this.updatingFromNewProps = false), 250)
    }

    /**
     *
     * @param {object} state
     */
    isChanged(state) {
        const {
            currentValues,
            values,
            currentElementsEnabled,
            elementsEnabled,
            currentMaterials,
            materials,
            adaptationOptions,
        } = { ...this.state, ...state }
        return (
            !_.isEqual(values, _.pick(currentValues, Object.keys(values))) ||
            !_.isEqual([...elementsEnabled].sort(), [...currentElementsEnabled].sort()) ||
            !_.isEqual(materials, currentMaterials)
        )
    }

    /**
     *
     * @param {string} pathwayNumber
     * @param {string} optionNumber
     * @param {string} field
     * @param {string|object|number|boolean} value
     */
    async updateValue(pathwayNumber, optionNumber, field, value) {
        if (this.updatingFromNewProps) return
        const values = this.omSchemaSS.clean({
            ...this.state.values,
            [field]: value,
        })
        this.setState({ values: values })
        const valid = this.validationCtx.validate(values, { keys: [field] })
        const analysisId = adaptState.currentAnalysisId
        if (adaptState.adaptationDesignValues[analysisId] === undefined) {
            var data = { [optionNumber]: values }
            var level1 = { [pathwayNumber]: data }
            adaptState.adaptationDesignValues[analysisId] = level1

            // TODO: Does this need to be in adaptState? If so, add to adaptState.js
            adaptState["pathwayJustUpdated"] = pathwayNumber
        } else {
            if (adaptState.adaptationDesignValues[analysisId][pathwayNumber] === undefined) {
                var data = { [optionNumber]: values }
                adaptState.adaptationDesignValues[analysisId][pathwayNumber] = data
                adaptState["pathwayJustUpdated"] = pathwayNumber
            } else {
                adaptState.adaptationDesignValues[analysisId][pathwayNumber][optionNumber] = values
                adaptState["pathwayJustUpdated"] = pathwayNumber
            }
        }

        await this.setState({
            dirtyValues: this.state.dirtyValues.add(field),
            noChanges: !this.isChanged({ values }),
            valid,
        })
    }
    /**
     *
     * @param {string} pathwayNumber
     * @param {string} optionNumber
     * @param {string} element
     * @param {string} material
     */
    async updateMaterial(pathwayNumber, optionNumber, element, material) {
        const materials = { ...this.state.materials, [element]: material }
        const analysisId = adaptState.currentAnalysisId

        // TODO: Use Lodash.merge?
        if (adaptState.adaptationMaterialValues[analysisId] === undefined) {
            var data = { [optionNumber]: materials }
            var level1 = { [pathwayNumber]: data }
            adaptState.adaptationMaterialValues[analysisId] = level1
        } else {
            if (adaptState.adaptationMaterialValues[analysisId][pathwayNumber] === undefined) {
                var data = { [optionNumber]: materials }
                adaptState.adaptationMaterialValues[analysisId][pathwayNumber] = data
            } else {
                adaptState.adaptationMaterialValues[analysisId][pathwayNumber][
                    optionNumber
                ] = materials
            }
        }

        this.setState({
            materials,
            dirtyMaterials: this.state.dirtyMaterials.add(element),
            noChanges: !this.isChanged({ materials }),
        })
    }

    // refreshes all values and validates when a change is made
    // TODO: Stop refreshing all the time!
    updateAdaptations(pathNum, optNum, field, value) {
        if (field == "capex") {
            this.setState(
                {
                    CAPEX: parseFloat(value),
                },
                () => {}
            )
        } else if (field == "adaptationYear") {
            // adaptState['pleaseChangeCurrentPathway'] = pathNum
            this.setState(
                {
                    adaptationYear: value,
                },
                () => {}
            )
        }

        const analysisId = adaptState.currentAnalysisId

        if (this.validateAdaptationOptions(field, value)) {
            const update_data = { [pathNum]: { [optNum]: { [field]: value } } }

            if (!checkProp(adaptState.adaptationValues, analysisId)) {
                // analysis doesn't yet exist, just create
                adaptState.adaptationValues[analysisId] = update_data
            } else {
                // values already exist, so we just need to update one.
                // This does a deep update, without affecting properties not in update_data.
                _.merge(adaptState.adaptationValues[analysisId], update_data)
            }

            // TODO: Why is this here? Should be up one level?
            if (
                checkProp(
                    adaptState.adaptationValues[analysisId][pathNum][optNum],
                    "adaptationYear"
                )
            ) {
                this.setState({ valid: true })
            }
        }

        this.setState({
            noChanges: false,
        })
    }

    async handleSubmit(pathNum, optNum) {
        adaptState.year[pathNum][optNum] = this.state.adaptationYear

        // TODO: Utilize boolScenario to limit unnecessary loads?
        this.setState({ boolScenario: true })
        var { values, elementsEnabled, materials, hideSaveButton } = this.state

        var elements = elementsEnabled.reduce((cm, el) => {

            return { ...cm, [el]: materials[el] }
        }, {})

        const analysisId = adaptState.currentAnalysisId

        if (checkNested(adaptState.adaptationDesignValues, analysisId, pathNum, optNum)) {
            values = adaptState.adaptationDesignValues[analysisId][pathNum][optNum]
        }

        if (checkNested(adaptState.adaptationMaterialValues, analysisId, pathNum, optNum)) {
            elements = elementsEnabled.reduce((cm, el) => {
                return {
                    ...cm,
                    [el]: adaptState.adaptationMaterialValues[analysisId][pathNum][optNum][el],
                }
            }, {})
            for (var key in elements) {
                if (elements[key] === "Not Included") delete elements[key]
            }
        }

        let data = {}
        if (checkProp(hideSaveButton, analysisId)) {
            if (checkProp(hideSaveButton[analysisId], pathNum)) {
                if (checkProp(hideSaveButton[analysisId][pathNum], optNum)) {
                    hideSaveButton[analysisId][pathNum][optNum] = false
                } else {
                    hideSaveButton[analysisId][pathNum] = {
                        ...hideSaveButton[analysisId][pathNum],
                        [optNum]: false,
                    }
                }
            } else {
                data = { [optNum]: false }
                hideSaveButton[analysisId] = {
                    ...hideSaveButton[analysisId],
                    [pathNum]: data,
                }
            }
        } else {
            data = { [optNum]: false }
            hideSaveButton = {
                ...hideSaveButton,
                [analysisId]: {
                    [pathNum]: data,
                },
            }
        }
        // Assign heat return frequency if used
        if (checkNested(this.props.analysisBaseline,
            "inputs",
            "asset",
            "properties",
            "thresholds",
            "heat",
            "return_frequency")) {
            values = {
                ...values,
                thresholds: {
                    ...values.thresholds,
                    heat: {
                        return_frequency: values.heatThreshold
                    }
                }
            }
            delete values.heatThreshold
        }
        // Assign wind return frequency if used
        if (checkNested(this.props.analysisBaseline,
            "inputs",
            "asset",
            "properties",
            "thresholds",
            "wind",
            "return_frequency")) {
            values = {
                ...values,
                thresholds: {
                    ...values.thresholds,
                    wind: {
                        return_frequency: values.windThreshold
                    },
                }
            }
            delete values.windThreshold
        }

        if (this.props.analysisBaseline.inputs.scenario.hazards.includes("cyclone_wind")) {
            const DEFAULT_CYCLONE_WIND_SPEED = [51]

            const hasWindSpeed = checkNested(
                this.props.analysisBaseline,
                "inputs",
                "asset",
                "properties",
                "thresholds",
                "cyclone_wind",
                "wind_speed"
            );
            
            const windSpeed = hasWindSpeed
                ? this.props.analysisBaseline.inputs.asset.properties.thresholds.cyclone_wind.wind_speed
                : DEFAULT_CYCLONE_WIND_SPEED

            values = {
                ...values,
                thresholds: {
                    ...values.thresholds,
                    cyclone_wind: {
                        wind_speed: windSpeed
                    }
                }
            }
        }


        adaptState.currentOption = optNum
        adaptState.currentPathway = pathNum
        this.setState({ hideSaveButton })

        // runChangedParameters call
        await this.props.onSubmit({
            parameters: values, // TODO: Make this the same everywhere.
            elements,
            extraMaterials: this.state.providedMaterials,
        })

        this.handleAssetValueChange(pathNum, optNum)
    }

    // TODO re-write this
    // TODO START HERE. Update button does not reset when option is deleted
    /**
     *
     * @param {string} pathwayNumber
     * @param {string} optionNumber
     */
    handleReset(pathwayNumber, optionNumber) {
        const analysisId = adaptState.currentAnalysisId
        const hideSaveButton = this.state.hideSaveButton
        const adaptDesignValues = adaptState.adaptationDesignValues[analysisId]
        const adaptMaterialValues = adaptState.adaptationMaterialValues[analysisId]
        const adaptValues = adaptState.adaptationValues[analysisId]

        if (checkNested(adaptDesignValues, pathwayNumber, optionNumber)) {
            // TODO change this to take the values of the option preceding it
            adaptDesignValues[pathwayNumber][optionNumber] = this.state.defaultValues
        }

        if (checkNested(adaptMaterialValues, pathwayNumber, optionNumber)) {
            // TODO change this to take the values of the option preceding it
            adaptMaterialValues[pathwayNumber][optionNumber] = this.state.defaultMaterials
        }

        if (checkNested(adaptValues, pathwayNumber, optionNumber)) {
            delete adaptValues[pathwayNumber][optionNumber]["capex"]
            // TODO change this to take the year of the option preceding it
            delete adaptValues[pathwayNumber][optionNumber]["adaptationYear"]
        }

        if (checkNested(hideSaveButton[analysisId], pathwayNumber, optionNumber)) {
            delete hideSaveButton[analysisId][pathwayNumber][optionNumber]
        }

        this.setState({
            hideSaveButton,
            values: this.state.defaultValues,
            elementsEnabled: this.state.currentElementsEnabled,
            materials: this.state.defaultMaterials,
            dirtyValues: immutable.Set(),
            dirtyElements: immutable.Set(),
            dirtyMaterials: immutable.Set(),
            noChanges: true,
            valid: true,
        })
    }

    // function for deleting pathways and options both from local storage
    // and the DB
    /**
     *
     * @param {string} pathNum
     * @param {string} optNum
     */
    handleDelete(pathNum, optNum) {
        const adapt = adaptState.adaptation[adaptState.currentAnalysisId]
        const designValues = adaptState.adaptationDesignValues[adaptState.currentAnalysisId]
        const matValues = adaptState.adaptationMaterialValues[adaptState.currentAnalysisId]

        // convert pathway number to actual number
        // TODO: I think this is a bad idea - we should use the same format everywhere.
        let actualPathwayNumber = pathNum.slice(-1)
        // this var is used to delete options if they are SAVED
        let tempOldData = this.state.oldData

        if (pathNum == undefined) {
            console.log("Delete Failed, not pathway selected!")
            return
        }

        // Edge case scenario adaptation can be done without a baseline analysisId
        // Only possible if whitelisted.
        const currentAnalysisIdBool = adapt !== undefined

        // This function deletes saved adaptations from the database
        // can handle undefined values
        if (this.props.currentID !== undefined) {
            this.props.onDeleteAdaptation(pathNum, optNum, this.props.currentID)
        }

        if (optNum) {
            // Delete adaptState for option
            if (currentAnalysisIdBool) {
                try {
                    delete adapt[pathNum][optNum]
                    delete designValues[pathNum][optNum]
                    // delete matValues[pathNum][optNum]
                } catch (e) {
                    console.log(e)
                }
                if (Object.keys(adapt[pathNum]).length == 0) {
                    try {
                        // remove breakdown chart
                        delete adaptState.path[actualPathwayNumber]["newRiskHazards"]
                        delete adapt[pathNum]
                        delete designValues[pathNum]
                        // delete matValues[pathNum]
                    } catch (e) {
                        console.log(e)
                    }
                }
            }

            // Delete oldData for option
            if (checkNested(tempOldData, this.props.currentID)) {
                delete tempOldData[this.props.currentID][pathNum][optNum]

                if (Object.keys(tempOldData[this.props.currentID][pathNum]).length == 0) {
                    delete tempOldData[this.props.currentID][pathNum]
                }
            }

            // reset option values
            this.handleReset(pathNum, optNum)

            // hides accordion
            this.hideOption(pathNum, optNum)
        } else {
            // This means that Delete Pathway is pressed.

            // If there is an adaptState with AnalysisID
            if (currentAnalysisIdBool) {
                try {
                    // remove breakdown chart
                    delete adaptState.path[actualPathwayNumber]["newRiskHazards"]
                    delete adapt[pathNum]
                    delete designValues[pathNum]
                    delete matValues[pathNum]
                } catch (e) {
                    console.log(e)
                }

                // Delete pathway from tempOldData and push
                // to this.state.oldData
                if (checkNested(tempOldData, this.props.currentID)) {
                    delete tempOldData[this.props.currentID][pathNum]
                }
            }

            // If a pathway is deleted remove the NPV data
            if (pathNum == "pathway1") {
                this.setState({ NPVSecGraph01: 0 })
            } else if (pathNum == "pathway2") {
                this.setState({ NPVSecGraph02: 0 })
            } else if (pathNum == "pathway3") {
                this.setState({ NPVSecGraph03: 0 })
            }

            // reset option 1
            this.handleReset(pathNum, "option1")

            // hides accordion
            this.hidePathway(pathNum)
        }

        // TODO: What does stateForWaiting do?
        this.setState(
            {
                stateForWaiting: this.state.stateForWaiting + 1,
                oldData: tempOldData,
            },
            () => {
                this.handleAssetValueChange(pathNum, optNum)
            }
        )
    }

    // TODO: make these three functions just one function
    /**
     * Opens/Closes one pathway accordion and Closes/Opens another.
     * @param {string} eventKey - passed automatically
     */
    togglePathCard(eventKey) {
        // console.log("Toggle Card Ran")
        this.setState({
            openPathCard: this.state.openPathCard === eventKey ? null : eventKey,
        })
    }

    /**
     * Opens/Closes one option accordion and Closes/Opens another.
     * @param {string} eventKey - passed automatically
     */
    toggleOptCard(eventKey) {
        // console.log("Toggle Card Ran")
        this.setState({
            openOptCard: this.state.openOptCard === eventKey ? null : eventKey,
        })
    }

    /**
     * Opens/Closes one  accordion and Closes/Opens another.
     * @param {string} eventKey - passed automatically
     */
    toggleCard(eventKey) {
        // console.log("Toggle Card Ran")
        this.setState({
            openCard: this.state.openCard === eventKey ? null : eventKey,
        })
    }

    // TODO: remove unused argument
    /**
     * @param {string} pathwayNumber
     */
    decadeYears = (pathwayNumber) => {
        var years = [
            { value: 2025, label: 2025 },
            { value: 2030, label: 2030 },
            { value: 2035, label: 2035 },
            { value: 2040, label: 2040 },
            { value: 2045, label: 2045 },
            { value: 2050, label: 2050 },
            { value: 2055, label: 2055 },
            { value: 2060, label: 2060 },
            { value: 2065, label: 2065 },
            { value: 2070, label: 2070 },
            { value: 2075, label: 2075 },
            { value: 2080, label: 2080 },
            { value: 2085, label: 2085 },
            { value: 2090, label: 2090 },
            { value: 2095, label: 2095 },
            { value: 2100, label: 2100 },
        ]
        var i = 0

        return years
    }
    /**
     *
     * @param {string} pathwayNumber
     * @param {string} optionNumber
     */
    async saveAdaptation(pathwayNumber, optionNumber) {
        var hideSaveButton = this.state.hideSaveButton
        var currentAnalysisId = adaptState.currentAnalysisId
        hideSaveButton[currentAnalysisId][pathwayNumber][optionNumber] = true
        await this.props.onSaveAdaptation(adaptState, pathwayNumber, optionNumber)
        this.setState({
            hideSaveButton,
            noChanges: true,
        })
    }

    // TODO: Rename this?
    /**
     *
     * @param {string} pathwayNumber
     * @param {string} optionNumber
     * @param {string} adaptationOptions
     */
    assetCustomisation(pathwayNumber, optionNumber, adaptationOptions) {
        const { analysis, submitText, onCancel } = this.props
        const { valid, openCard, loading, noChanges, hideSaveButton } = this.state

        const analysisId = adaptState.currentAnalysisId
        let showUpdateButton = false
        let showSaveButton = false
        let valuesSet = false

        if (
            checkNested(
                adaptState.adaptationDesignValues,
                analysisId,
                pathwayNumber,
                optionNumber
            ) ||
            checkNested(
                adaptState.adaptationMaterialValues,
                analysisId,
                pathwayNumber,
                optionNumber
            )
        ) {
            showUpdateButton = true
        }

        if (checkNested(adaptState.adaptationValues, analysisId, pathwayNumber, optionNumber)) {
            valuesSet = true
            showUpdateButton = true
        }

        if (checkNested(hideSaveButton, analysisId, pathwayNumber, optionNumber)) {
            if (!hideSaveButton[analysisId][pathwayNumber][optionNumber]) {
                showSaveButton = true
            }
        }

        let byDefaultValueCapex = 0
        let byDefaultValueAdaptationYear = this.state.adaptationYear

        if (checkNested(this.state.oldData, analysisId, pathwayNumber, optionNumber)) {
            byDefaultValueCapex = this.state.oldData[analysisId][pathwayNumber][optionNumber]
                .adaptationOptions.capex
            byDefaultValueAdaptationYear = this.state.oldData[analysisId][pathwayNumber][
                optionNumber
            ].adaptationOptions.adaptationYear
        }

        const adaptYear =
            valuesSet &&
            "adaptationYear" in adaptState.adaptationValues[analysisId][pathwayNumber][optionNumber]
                ? adaptState.adaptationValues[analysisId][pathwayNumber][optionNumber][
                      "adaptationYear"
                  ]
                : byDefaultValueAdaptationYear

        return (
            <div className="AssetCustomisation">
                {/* TODO: Pretty sure this is clashing with the actual
                    AssetCustomisation. Replace, and check SCSS */}
                {loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div className="col mb-3">
                            <InputGroup>
                                <label className="mr-2 mt-2">Adaptation Year: </label>
                                <NiceSelect
                                    options={this.decadeYears(pathwayNumber)}
                                    onChange={(val) =>
                                        this.updateAdaptations(
                                            pathwayNumber,
                                            optionNumber,
                                            "adaptationYear",
                                            val
                                        )
                                    }
                                    value={adaptYear}
                                    placeholder="0000"
                                    className="mr-3 adaptationInput"
                                />
                                <InputGroup.Prepend>
                                    <label className="ml-3 mr-3 mt-2">CAPEX:</label>
                                    <InputGroup.Text className="dollar-sign">$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    aria-label="Amount (to the nearest dollar)"
                                    accept="^[0-9]*$"
                                    min="0"
                                    required={true}
                                    onChange={(val) =>
                                        this.updateAdaptations(
                                            pathwayNumber,
                                            optionNumber,
                                            "capex",
                                            val.target.value
                                        )
                                    }
                                    value={
                                        valuesSet &&
                                        "capex" in
                                            adaptState.adaptationValues[analysisId][pathwayNumber][
                                                optionNumber
                                            ]
                                            ? adaptState.adaptationValues[analysisId][
                                                  pathwayNumber
                                              ][optionNumber]["capex"]
                                            : byDefaultValueCapex
                                    }
                                />
                            </InputGroup>
                        </div>

                        <Accordion defaultActiveKey="0">
                            <Card>
                                <CardHeader
                                    eventKey="0"
                                    openCard={openCard}
                                    onClick={this.toggleCard}>
                                    Design Specifications
                                </CardHeader>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        {this.renderOMFields(pathwayNumber, optionNumber)}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <CardHeader
                                    eventKey="1"
                                    openCard={openCard}
                                    onClick={this.toggleCard}>
                                    Element Specifications
                                </CardHeader>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        {this.renderElements(pathwayNumber, optionNumber)}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <div className="buttons">
                            <Button
                                onClick={() => this.handleReset(pathwayNumber, optionNumber)}
                                className="reset btn-sm"
                                title={noChanges ? "No changes" : "Reset to original values"}>
                                Reset
                            </Button>

                            {/* TODO: change handleSubmit to only process the pathway
                            that is selected instead of all pathways */}
                            {/* TODO: reset button not functioning properly */}
                            {valid && (
                                <Button
                                    onClick={() => this.handleSubmit(pathwayNumber, optionNumber)}
                                    variant="success"
                                    className="submit btn-sm"
                                    title={
                                        noChanges
                                            ? "No changes"
                                            : "Perform a new adaptation with these settings"
                                    }>
                                    {submitText}
                                </Button>
                            )}

                            {analysis && !analysis._id && showSaveButton && (
                                <Button
                                    onClick={() => this.saveAdaptation(pathwayNumber, optionNumber)}
                                    className="btn-secondary btn-sm">
                                    Save current adaptation
                                </Button>
                            )}

                            {onCancel && (
                                <Button onClick={onCancel} className="cancel btn-sm">
                                    Cancel
                                </Button>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }

    /**
     * Hides deleted pathway from interface, as well as all options it contains
     * @param {string} pathNum - pathway name. ex: pathway2
     */
    hidePathway(pathNum) {
        let pathChange = _.cloneDeep(this.state.pathOptVisible)

        // Set path and all options to false
        Object.keys(pathChange[pathNum]).forEach(function (path) {
            pathChange[pathNum][path] = false
        })

        this.setState({ pathOptVisible: pathChange })
    }

    /**
     * Changes pathOptVisible state to display pathway that is not visible
     * in numerical order.
     */
    showPathway() {
        let pathChange = _.cloneDeep(this.state.pathOptVisible)

        // Show added pathways in numerical order
        if (!pathChange.pathway1.pathway) {
            pathChange.pathway1.pathway = true
            pathChange.pathway1.option1 = true
        } else if (!pathChange.pathway2.pathway) {
            pathChange.pathway2.pathway = true
            pathChange.pathway2.option1 = true
        } else {
            pathChange.pathway3.pathway = true
            pathChange.pathway3.option1 = true
        }

        this.setState({ pathOptVisible: pathChange })
    }

    /**
     * Hides an option's interface when its deleted
     * @param {string} pathNum - pathway number. example: "pathway1"
     * @param {string} optNum  - option number. example: "option1"
     */
    hideOption(pathNum, optNum) {
        let optChange = _.cloneDeep(this.state.pathOptVisible)

        optChange[pathNum][optNum] = false

        this.setState({ pathOptVisible: optChange })
    }

    /**
     * Shows an additional option by unhiding it.
     * hides "Add option" when all options are displayed
     * @param {string} pathNum - Pathway number. Ex: "pathway 1"
     */
    showOptions(pathNum) {
        let optChange = _.cloneDeep(this.state.pathOptVisible)

        // Show added pathways in numerical order
        if (!optChange[pathNum].option1) {
            optChange[pathNum].option1 = true
        } else if (!optChange[pathNum].option2) {
            optChange[pathNum].option2 = true
        } else {
            optChange[pathNum].option3 = true
        }

        this.setState({ pathOptVisible: optChange })
    }

    adaptationOption(pathNum) {
        const { openOptCard, pathOptVisible } = this.state
        return (
            <div className="AdaptationOptions">
                <Accordion defaultActiveKey="option1">
                    {pathOptVisible[pathNum].option1 ? (
                        <Card>
                            <CardHeader
                                eventKey="option1"
                                openCard={openOptCard}
                                onClick={this.toggleOptCard}>
                                Adaptation Option 1
                            </CardHeader>
                            <div
                                className="deleteIconClass"
                                onClick={() => this.handleDelete(pathNum, "option1")}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    style={{ cursor: "pointer" }}
                                    title={"Delete"}
                                />
                            </div>
                            <Accordion.Collapse eventKey="option1">
                                <Card.Body>
                                    {this.assetCustomisation(
                                        pathNum,
                                        "option1",
                                        "adaptationOptions"
                                    )}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ) : (
                        ""
                    )}

                    {pathOptVisible[pathNum].option2 ? (
                        <Card id={`${pathNum}-option2`}>
                            <CardHeader
                                eventKey="option2"
                                openCard={openOptCard}
                                onClick={this.toggleOptCard}>
                                Adaptation Option 2
                            </CardHeader>
                            <div
                                className="deleteIconClass"
                                onClick={() => this.handleDelete(pathNum, "option2")}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    style={{ cursor: "pointer" }}
                                    title={"Delete"}
                                />
                            </div>
                            <Accordion.Collapse eventKey="option2">
                                <Card.Body>
                                    {this.assetCustomisation(
                                        pathNum,
                                        "option2",
                                        "adaptationOptions"
                                    )}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ) : (
                        ""
                    )}

                    {pathOptVisible[pathNum].option3 ? (
                        <Card id={`${pathNum}-option3`}>
                            <CardHeader
                                eventKey="option3"
                                openCard={openOptCard}
                                onClick={this.toggleOptCard}>
                                Adaptation Option 3
                            </CardHeader>
                            <div
                                className="deleteIconClass"
                                onClick={() => this.handleDelete(pathNum, "option3")}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    style={{ cursor: "pointer" }}
                                    title={"Delete"}
                                />
                            </div>
                            <Accordion.Collapse eventKey="option3">
                                <Card.Body>
                                    {this.assetCustomisation(
                                        pathNum,
                                        "option3",
                                        "adaptationOptions"
                                    )}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ) : (
                        ""
                    )}

                    {!pathOptVisible[pathNum].option1 ||
                    !pathOptVisible[pathNum].option2 ||
                    !pathOptVisible[pathNum].option3 ? (
                            <div
                                id={`${pathNum}-optionAdd`}
                                className="optionIcon"
                                onClick={() => this.showOptions(pathNum)}>
                                <div className="iconClass">
                                    <FontAwesomeIcon icon={faPlus} />
                                </div>

                                <p style={{ paddingTop: "1.5vh" }}>&nbsp; Add Option</p>
                            </div>
                        ) : (
                            ""
                        )}
                </Accordion>
            </div>
        )
    }

    /**
     *
     * @param {string} key
     * @param {string|number|object|boolean} value
     */
    validateAdaptationOptions(key, value) {
        var re = /^[0-9]*$/
        if (key === "assetCost" || key === "npv") re = /^[0-9]*$/
        if (key === "discountRate") re = /^\d*\.?\d*$/
        if (key === "capex") {
            re = /^[0-9]*$/
        }

        if (re.test(value)) {
            return true
        } else {
            return false
        }
    }

    /**
     * This function is just effecting the Baseline of NPV.
     */
    optimizingFunction() {
        const endIndex = this.startIndex + parseInt(adaptState["npv"]);
        const tips = this.props.analysisBaseline.properties.totalRisk
            .slice(this.startIndex, endIndex)
            .map((value) => value * parseFloat(this.state.assetReplacementCost));

        var term = calculateNetPresentValue(tips, parseFloat(adaptState["discountRate"]));

        this.setState({
            NPVBaseline: term,
        })
        this.handleAssetValueChange(null, null, "refreshing")
    }

    // TODO gut this function
    // TODO: make this apply to a specific pathway?
    // calculates the NPV with the current pathway values
    /**
     *
     * @param {string} key
     * @param {string|number|object|boolean} value
     * @param {string} refresh
     */
    async handleAssetValueChange(key, value, refresh) {
        var options = ["option1", "option2", "option3"]
        var pathways = ["pathway1", "pathway2", "pathway3"]
        /*
          Following code is just effecting the pathway1, pathway2, pathway3
        */
        var help = adaptState.adaptation
        var tempNPV = [0, 0, 0]
        for (let i = 0; i < pathways.length; i++) {
            for (let j = 0; j < options.length; j++) {
                // TODO: Merge these with checkNested
                if (
                    checkNested(
                        adaptState.adaptation,
                        adaptState.currentAnalysisId,
                        pathways[i],
                        options[j]
                    )
                ) {
                    // console.log(adaptState)
                    // TODO: Fix this repetition
                    var capexToBeAdded = 0
                    if (pathways[i] == "pathway1") {
                        var usedArray = adaptState.path[1]["newData"].map(
                            (v) => v * this.state.assetReplacementCost
                        )
                    }
                    if (pathways[i] == "pathway2") {
                        var usedArray = adaptState.path[2]["newData"].map(
                            (v) => v * this.state.assetReplacementCost
                        )
                    }
                    if (pathways[i] == "pathway3") {
                        var usedArray = adaptState.path[3]["newData"].map(
                            (v) => v * this.state.assetReplacementCost
                        )
                    }
                    if (refresh !== "refreshing") {
                        if (key == pathways[i]) {
                            tempNPV[i] = 0
                        }
                    }
                    // console.log("Adaptation Analysis", this.props.analysis)

                    const capexIndex = parseInt(this.state.adaptationYear) - this.props.analysis.inputs.scenario.startYear;
                    const endIndex = this.startIndex + parseInt(adaptState["npv"]);
                    const rate = parseFloat(adaptState["discountRate"]);
                    const tips = usedArray.slice(this.startIndex, endIndex);

                    tempNPV[i] = calculateNetPresentValue(tips, rate);

                    if (capexIndex >= this.startIndex && capexIndex < endIndex) {
                        tempNPV[i] += calculatePresentValue(this.state.CAPEX, rate, capexIndex - this.startIndex);
                    }
                }
            }
        }

        this.setState({
            NPVSecGraph01: tempNPV[0],
            NPVSecGraph02: tempNPV[1],
            NPVSecGraph03: tempNPV[2],
        })

        var analysisId = this.props.analysis._id
        if (this.validateAdaptationOptions(key, value)) {
            // TODO: Replace with Lodash.merge?
            if (adaptState.assetValues[analysisId] !== undefined) {
                if (adaptState.assetValues[analysisId][key] !== undefined) {
                    adaptState.assetValues[analysisId][key] = value
                } else {
                    adaptState.assetValues[analysisId] = {
                        ...adaptState.assetValues[analysisId],
                        [key]: value,
                    }
                }
            } else {
                var data = { [key]: value }
                adaptState.assetValues = {
                    ...adaptState.assetValues,
                    [analysisId]: data,
                }
            }
        }
    }

    threeFields() {
        var setValues = false
        var analysisId = adaptState.currentAnalysisId
        if (adaptState.assetValues[analysisId] !== undefined) {
            setValues = true
        }
        return (
            <div className="text-block">
                <div className="npv-inputs">
                    <div>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <label className="mr-1 mt-2">Asset Replacement Cost</label>
                                <InputGroup.Text className="dollar-sign">$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                defaultValue={this.state.assetReplacementCost}
                                required={true}
                                onChange={(val) =>
                                    (this.state.assetReplacementCost = val.target.value)
                                }
                            />

                            <label className="ml-3 mr-1 mt-2">Discount Rate</label>
                            <FormControl
                                defaultValue={this.state.discountRate}
                                min="0.0"
                                required={true}
                                onChange={(val) => (adaptState["discountRate"] = val.target.value)}
                            />

                            <label className="ml-3 mr-1 mt-2">NPV Term</label>
                            <FormControl
                                defaultValue={this.state.NPVTerm}
                                required={true}
                                onChange={(val) => (adaptState["npv"] = val.target.value)}
                            />
                            <Button
                                onClick={
                                    // This will effect and refresh the NPV Baseline
                                    () => this.optimizingFunction()
                                }
                                className="reset btn-sm ml-4"
                                title={"Update NPV Settings"}>
                                Update NPV Settings
                            </Button>
                        </InputGroup>
                    </div>
                </div>
            </div>
        )
    }

    showHazardChart(pathNum) {
        const { analysisBaseline } = this.props

        const riskHazards =
            pathNum == "baseline"
                ? analysisBaseline.properties.riskHazards
                : adaptState.path[pathNum]["newRiskHazards"]

        const pathway = pathNum == "baseline" ? "Baseline" : `Pathway - ${pathNum}`

        if (riskHazards !== undefined) {
            return (
                <AdaptHazardsChart
                    riskHazards={riskHazards}
                    hazards={analysisBaseline.inputs.scenario.hazards}
                    pathway={pathway}
                    years={buildYearListFromScenario(analysisBaseline.inputs.scenario)}
                />
            )
        }
    }

    showFailureChart(pathNum) {
        const { analysisBaseline } = this.props

        const failureHazards =
            pathNum == "baseline"
                ? this.props.analysisBaseline.properties.failureHazards
                : adaptState.path[pathNum]["newFailureHazards"]

        const pathway = pathNum == "baseline" ? "Baseline" : `Pathway - ${pathNum}`

        if (failureHazards !== undefined) {
            return (
                <AdaptFailureChart
                    failureHazards={failureHazards}
                    pathway={pathway}
                    years={buildYearListFromScenario(analysisBaseline.inputs.scenario)}
                />
            )
        }
    }

    render() {
        const { displayCharts } = this.props
        const { openPathCard, pathOptVisible } = this.state

        return (
            <div>
                {displayCharts && (
                    <div id="adaptation-charts">
                        <div className="text-block">
                            <Tabs defaultActiveKey="overview" id="adapt-subtab">
                                <Tab eventKey="overview" title="Overview">
                                    <div className="row">
                                        <AdaptationTIPChart
                                            analysisBaseline={this.props.analysisBaseline}
                                            assetReplacementCost={this.state.assetReplacementCost}
                                            boolScenario={this.state.boolScenario}
                                            oldData={this.state.oldData}
                                            currentID={this.props.currentID}
                                            adaptationYear={this.state.adaptationYear}
                                            years={buildYearListFromScenario(this.props.analysisBaseline.inputs.scenario)}
                                        />
                                        <AdaptationNPVChart
                                            NPVBaseline={this.state.NPVBaseline}
                                            NPVSecGraph01={this.state.NPVSecGraph01}
                                            NPVSecGraph02={this.state.NPVSecGraph02}
                                            NPVSecGraph03={this.state.NPVSecGraph03}
                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey="breakdown" title="Hazards">
                                    <div className="row">
                                        {this.showHazardChart("baseline")}
                                        {this.showHazardChart(1)}
                                    </div>
                                    <div className="row">
                                        {this.showHazardChart(2)}
                                        {this.showHazardChart(3)}
                                    </div>
                                </Tab>

                                <Tab eventKey="failure" title="Failure">
                                    <div className="row">
                                        {this.showFailureChart("baseline")}
                                        {this.showFailureChart(1)}
                                    </div>
                                    <div className="row">
                                        {this.showFailureChart(2)}
                                        {this.showFailureChart(3)}
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                )}

                {this.threeFields()}

                {/* TODO: Rename this class, as above */}
                <div className="AssetCustomisation" style={{ overflow: "visible" }}>
                    <Accordion defaultActiveKey="pathway1">
                        {pathOptVisible.pathway1.pathway ? (
                            <Card id="pathway1">
                                <CardHeader
                                    eventKey="pathway1"
                                    openCard={openPathCard}
                                    onClick={this.togglePathCard}>
                                    Adaptation Pathway 1
                                </CardHeader>
                                <div
                                    className="deleteIconClass"
                                    onClick={() => this.handleDelete("pathway1", null)}>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ cursor: "pointer" }}
                                        title={"Delete"}
                                    />
                                </div>
                                <Accordion.Collapse eventKey="pathway1">
                                    <Card.Body>{this.adaptationOption("pathway1")}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ) : (
                            ""
                        )}

                        {pathOptVisible.pathway2.pathway ? (
                            <Card id="pathway2">
                                <CardHeader
                                    eventKey="pathway2"
                                    openCard={openPathCard}
                                    onClick={this.togglePathCard}>
                                    Adaptation Pathway 2
                                </CardHeader>
                                <div
                                    className="deleteIconClass"
                                    onClick={() => this.handleDelete("pathway2", null)}>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ cursor: "pointer" }}
                                        title={"Delete"}
                                    />
                                </div>
                                <Accordion.Collapse eventKey="pathway2">
                                    <Card.Body>{this.adaptationOption("pathway2")}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ) : (
                            ""
                        )}

                        {pathOptVisible.pathway3.pathway ? (
                            <Card id="pathway3">
                                <CardHeader
                                    eventKey="pathway3"
                                    openCard={openPathCard}
                                    onClick={this.togglePathCard}>
                                    Adaptation Pathway 3
                                </CardHeader>
                                <div
                                    className="deleteIconClass"
                                    onClick={() => this.handleDelete("pathway3", null)}>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ cursor: "pointer" }}
                                        title={"Delete"}
                                    />
                                </div>
                                <Accordion.Collapse eventKey="pathway3">
                                    <Card.Body>{this.adaptationOption("pathway3")}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ) : (
                            ""
                        )}

                        {/* Hide add button if all are visible */}
                        {!pathOptVisible.pathway1.pathway ||
                        !pathOptVisible.pathway2.pathway ||
                        !pathOptVisible.pathway3.pathway ? (
                            <div id="AddPathway" className="pathwayIcon" onClick={this.showPathway}>
                                <div className="iconClass">
                                    <FontAwesomeIcon icon={faPlus} />
                                </div>
                                <p style={{ paddingTop: "1.5vh" }}>&nbsp; Add Pathway</p>
                            </div>
                        ) : (
                            ""
                        )}
                    </Accordion>
                </div>
            </div>
        )
    }

    makeInfoPopover(id, title, content) {
        const popover = (
            <Popover id={"popover-" + id}>
                <Popover.Title as="h3">{title}</Popover.Title>
                <Popover.Content>{content}</Popover.Content>
            </Popover>
        )

        return (
            <OverlayTrigger trigger="click" placement="auto" rootClose={true} overlay={popover}>
                <Button variant="primary" className="infopopover">
                    ?
                </Button>
            </OverlayTrigger>
        )
    }

    renderOMFields(pathwayNumber, optionNumber) {
        const { omSchema, currentValues, values, defaultValues, designValues } = this.state
        delete omSchema.buildYear
        delete omSchema.lifetime
        delete omSchema.elevation
        const { hideFieldsWithNoDefaultValue } = this.props
        const analysisId = adaptState.currentAnalysisId

        var setFields = false
        if (
            checkNested(
                adaptState,
                "adaptationDesignValues",
                analysisId,
                pathwayNumber,
                optionNumber
            )
        ) {
            setFields = true
        }

        return (
            <div className="asset-fields">
                {currentValues && (
                    <div className="header">
                        <div className="placeholder" />
                        <div>Default setting</div>
                        <div>Current setting</div>
                    </div>
                )}

                {Object.values(omSchema)
                    .filter(
                        (fieldDef) =>
                            !hideFieldsWithNoDefaultValue ||
                            defaultValues[fieldDef.machineName] !== null
                    )
                    .map((fieldDef) => (
                        <Form.Group controlId={fieldDef.machineName} key={fieldDef.machineName}>
                            <Form.Label>
                                {fieldDef.label}
                                {this.makeInfoPopover(
                                    fieldDef.machineName,
                                    fieldDef.label,
                                    fieldDef.description
                                )}
                            </Form.Label>

                            {defaultValues && (
                                <Field
                                    value={defaultValues[fieldDef.machineName]}
                                    disabled={true}
                                    fieldDef={fieldDef}
                                />
                            )}

                            <Field
                                value={
                                    setFields
                                        ? adaptState.adaptationDesignValues[analysisId][
                                              pathwayNumber
                                          ][optionNumber][fieldDef.machineName]
                                        : values[fieldDef.machineName]
                                }
                                simpleSchema={this.omSchemaSS}
                                validationCtx={this.validationCtx}
                                fieldDef={fieldDef}
                                onChange={(value) =>
                                    this.updateValue(
                                        pathwayNumber,
                                        optionNumber,
                                        fieldDef.machineName,
                                        value
                                    )
                                }
                            />
                        </Form.Group>
                    ))}
            </div>
        )
    }

    /**
     * This function renders the element material selector part of the Adaptation component
     *
     * TODO: Move this to a separate component
     */
    renderElements(pathwayNumber, optionNumber) {
        const {
            elements,
            currentElementsEnabled,
            elementsEnabled,
            defaultMaterials,
            materials,
        } = this.state

        if (Object.keys(this.state.materials_orig).length == 0) {
            return <Loading />
        }

        const analysisId = adaptState.currentAnalysisId
        const setFields = checkNested(
            adaptState.adaptationMaterialValues,
            "analysisId",
            "pathwayNumber",
            "optionNumber"
        )

        var materialOptions = Object.values(this.state.materials_orig).map((m) => {
            return { value: m.id, label: `${m.name}` }
        })
        const notIncludedOption = {
            value: "Not Included",
            label: "Not Included",
        }
        materialOptions.unshift(notIncludedOption)

        let providedExplainer = false

        // Material selection for asset elements
        const element_selects = Object.values(elements).map((element) => {
            // default material machine name.
            const defMat = defaultMaterials[element.name]
            const defaultMaterial = checkProp(this.state.providedMaterials, defMat)
                ? this.state.providedMaterials[defMat]
                : this.state.materials_orig[defMat]

            // Default material name/label
            const material_default = currentElementsEnabled.has(element.name)
                ? defaultMaterial
                    ? `${defaultMaterial.name}`
                    : "???"
                : defaultMaterial.name

            if (material_default.slice(-1) == "*") providedExplainer = true

            const material_value = setFields
                ? adaptState.adaptationMaterialValues[analysisId][pathwayNumber][optionNumber]
                : materials[element.name]

            // Combine provided material with acrobase-provided materials
            const eleMaterialOpts = [{ value: defMat, label: material_default }, ...materialOptions]

            return (
                <React.Fragment key={element.name}>
                    <div className="border-sep element-name">
                        {element.name.substring(0, 1) +
                            element.name
                                .substring(1)
                                .replace(/([A-Z])/g, " $1")
                                .toLowerCase()}
                    </div>

                    <div className="border-sep current-material">{material_default}</div>
                    <div>
                        <NiceSelect
                            options={eleMaterialOpts}
                            value={material_value}
                            onChange={(val) =>
                                this.updateMaterial(pathwayNumber, optionNumber, element.name, val)
                            }
                            placeholder="???"
                            isDisabled={elementsEnabled && !elementsEnabled.has(element.name)}
                        />
                    </div>
                    <div> </div>
                </React.Fragment>
            )
        })

        return (
            <div className="elements-wrapper">
                <div className="elements">
                    {/* Headers */}
                    <div className="header border-sep">Elements in this archetype</div>

                    <div className="secondary-header border-sep">Default Material</div>
                    <div className="secondary-header gridcolspan2">Material</div>

                    {/* Materials table */}
                    {element_selects}
                </div>

                {providedExplainer ? (
                    <div id="provided-explainer">
                        * These material specifications were provided by the existing analysis, and
                        may differ from the standard materials vulnerability specifications.
                    </div>
                ) : (
                    ""
                )}
            </div>
        )
    }
}

const formatFieldError = (fieldError) => {
    if (fieldError.type == "expectedType") {
        return `Must be a ${fieldError.dataType.toLowerCase()}.`
    }
    if (fieldError.type == "minNumber") {
        return `Minumum allowed value is ${fieldError.min}.`
    }
    if (fieldError.type == "maxNumber") {
        return `Maxumum allowed value is ${fieldError.max}.`
    }
    return fieldError.type
        .replace(/([A-Z])/g, " $1") // split camel case error code.
        .toLowerCase()
        .replace(/^./, function (str) {
            return str.toUpperCase()
        }) // uppercase the first character.
}

class Field extends React.Component {
    constructor(props) {
        super(props)

        autoBind(this)

        this.selectOptions =
            props.fieldDef.allowedValues &&
            props.fieldDef.allowedValues.map((val, idx) => {
                return {
                    value: val,
                    label: props.fieldDef.allowedValueLabels
                        ? props.fieldDef.allowedValueLabels[idx]
                        : val,
                }
            })
    }

    render() {
        const {
            fieldDef,
            validationCtx,
            value,
            onChange,
            placeholder,
            disabled,
            className,
            name,
        } = this.props

        let valid = true,
            error
        if (validationCtx) {
            error = validationCtx.validationErrors().find((err) => err.name == fieldDef.machineName)
            valid = !error
            error = !valid && formatFieldError(error)
        }

        const elProps = {
            value: value === null || typeof value == "undefined" ? "" : value,
            title: placeholder,
            placeholder,
            disabled,
            className,
            name,
        }

        if (this.selectOptions) {
            return (
                <NiceSelect
                    {...elProps}
                    multiple={false}
                    options={this.selectOptions}
                    onChange={onChange}
                    value={value || ""}
                    isDisabled={elProps.disabled}
                />
            )
        }

        const isInteger = fieldDef.type == SimpleSchema.Integer
        const isNumeric = isInteger || fieldDef.type == Number

        if (isNumeric) {
            elProps.min = fieldDef.min || 0
            elProps.step = isInteger ? 1 : "any"
            if (fieldDef.hasOwnProperty("max")) elProps.max = fieldDef.max
            if (!isInteger && elProps.value !== "") {
                // Work around for DynamicNumber failing for many decimal value places.
                elProps.value = Math.round(elProps.value * 1000) / 1000
            }
        }

        return React.createElement(
            "div",
            { className: "form-div-custom" },
            <React.Fragment>
                {isNumeric ? (
                    <NumberFormat
                        {...elProps}
                        thousandSeparator={!fieldDef.disableThousandsGrouping ? "," : undefined}
                        onValueChange={(values) => onChange && onChange(values.floatValue)}
                        className={classNames(
                            elProps.className,
                            "form-control",
                            !valid && "is-invalid"
                        )}
                    />
                ) : (
                    <Form.Control
                        type={isNumeric ? "number" : "text"}
                        {...elProps}
                        onChange={(e) => onChange(e.target.value)}
                        isInvalid={!valid}
                    />
                )}
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </React.Fragment>
        )
    }
}

function CardHeader({ children, eventKey, openCard, onClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => onClick(eventKey))

    return (
        <Card.Header onClick={decoratedOnClick} className={openCard != eventKey && "collapsed"}>
            <MdKeyboardArrowRight size="1.5em" />
            <div>{children}</div>
        </Card.Header>
    )
}

export default Adaptations
