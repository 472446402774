export const scenario60Adjustment = {
    1990: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1991: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1992: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1993: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1994: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1995: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1996: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1997: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1998: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1999: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2000: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2001: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2002: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2003: {
        "Riverine Flooding_factor": 0.99999,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 0.99999,
        "Forest Fire_factor": 0.99999,
        "Extreme Wind_factor": 0.99999,
        "Soil Subsidence_factor": 0.99999,
        "Freeze-Thaw_factor": 0.99999,
        "Surface Water Flooding_factor": 0.99999,
        "Precipitation_factor": 0.99999,
        "Drought_factor": 0.99999,
        "Cyclone Wind_factor": 0.99999
    },
    2004: {
        "Riverine Flooding_factor": 0.99996,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 0.99996,
        "Forest Fire_factor": 0.99996,
        "Extreme Wind_factor": 0.99996,
        "Soil Subsidence_factor": 0.99996,
        "Freeze-Thaw_factor": 0.99996,
        "Surface Water Flooding_factor": 0.99996,
        "Precipitation_factor": 0.99996,
        "Drought_factor": 0.99996,
        "Cyclone Wind_factor": 0.99996
    },
    2005: {
        "Riverine Flooding_factor": 0.99991,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 0.99991,
        "Forest Fire_factor": 0.99991,
        "Extreme Wind_factor": 0.99991,
        "Soil Subsidence_factor": 0.99991,
        "Freeze-Thaw_factor": 0.99991,
        "Surface Water Flooding_factor": 0.99991,
        "Precipitation_factor": 0.99991,
        "Drought_factor": 0.99991,
        "Cyclone Wind_factor": 0.99991
    },
    2006: {
        "Riverine Flooding_factor": 0.99984,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 0.99984,
        "Forest Fire_factor": 0.99984,
        "Extreme Wind_factor": 0.99984,
        "Soil Subsidence_factor": 0.99984,
        "Freeze-Thaw_factor": 0.99984,
        "Surface Water Flooding_factor": 0.99984,
        "Precipitation_factor": 0.99984,
        "Drought_factor": 0.99984,
        "Cyclone Wind_factor": 0.99984
    },
    2007: {
        "Riverine Flooding_factor": 0.99976,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 0.99976,
        "Forest Fire_factor": 0.99976,
        "Extreme Wind_factor": 0.99976,
        "Soil Subsidence_factor": 0.99976,
        "Freeze-Thaw_factor": 0.99976,
        "Surface Water Flooding_factor": 0.99976,
        "Precipitation_factor": 0.99976,
        "Drought_factor": 0.99976,
        "Cyclone Wind_factor": 0.99976
    },
    2008: {
        "Riverine Flooding_factor": 0.99966,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 0.99966,
        "Forest Fire_factor": 0.99966,
        "Extreme Wind_factor": 0.99966,
        "Soil Subsidence_factor": 0.99966,
        "Freeze-Thaw_factor": 0.99966,
        "Surface Water Flooding_factor": 0.99966,
        "Precipitation_factor": 0.99966,
        "Drought_factor": 0.99966,
        "Cyclone Wind_factor": 0.99966
    },
    2009: {
        "Riverine Flooding_factor": 0.99954,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 0.99954,
        "Forest Fire_factor": 0.99954,
        "Extreme Wind_factor": 0.99954,
        "Soil Subsidence_factor": 0.99954,
        "Freeze-Thaw_factor": 0.99954,
        "Surface Water Flooding_factor": 0.99954,
        "Precipitation_factor": 0.99954,
        "Drought_factor": 0.99954,
        "Cyclone Wind_factor": 0.99954
    },
    2010: {
        "Riverine Flooding_factor": 0.9994,
        "Coastal Inundation_factor": 0.99959,
        "Extreme Heat_factor": 0.9994,
        "Forest Fire_factor": 0.9994,
        "Extreme Wind_factor": 0.9994,
        "Soil Subsidence_factor": 0.9994,
        "Freeze-Thaw_factor": 0.9994,
        "Surface Water Flooding_factor": 0.9994,
        "Precipitation_factor": 0.9994,
        "Drought_factor": 0.9994,
        "Cyclone Wind_factor": 0.9994
    },
    2011: {
        "Riverine Flooding_factor": 0.99916,
        "Coastal Inundation_factor": 0.99902,
        "Extreme Heat_factor": 0.99916,
        "Forest Fire_factor": 0.99916,
        "Extreme Wind_factor": 0.99916,
        "Soil Subsidence_factor": 0.99916,
        "Freeze-Thaw_factor": 0.99916,
        "Surface Water Flooding_factor": 0.99916,
        "Precipitation_factor": 0.99916,
        "Drought_factor": 0.99916,
        "Cyclone Wind_factor": 0.99916
    },
    2012: {
        "Riverine Flooding_factor": 0.99875,
        "Coastal Inundation_factor": 0.99778,
        "Extreme Heat_factor": 0.99875,
        "Forest Fire_factor": 0.99875,
        "Extreme Wind_factor": 0.99875,
        "Soil Subsidence_factor": 0.99875,
        "Freeze-Thaw_factor": 0.99875,
        "Surface Water Flooding_factor": 0.99875,
        "Precipitation_factor": 0.99875,
        "Drought_factor": 0.99875,
        "Cyclone Wind_factor": 0.99875
    },
    2013: {
        "Riverine Flooding_factor": 0.99814,
        "Coastal Inundation_factor": 0.9961,
        "Extreme Heat_factor": 0.99814,
        "Forest Fire_factor": 0.99814,
        "Extreme Wind_factor": 0.99814,
        "Soil Subsidence_factor": 0.99814,
        "Freeze-Thaw_factor": 0.99814,
        "Surface Water Flooding_factor": 0.99814,
        "Precipitation_factor": 0.99814,
        "Drought_factor": 0.99814,
        "Cyclone Wind_factor": 0.99814
    },
    2014: {
        "Riverine Flooding_factor": 0.99731,
        "Coastal Inundation_factor": 0.99423,
        "Extreme Heat_factor": 0.99731,
        "Forest Fire_factor": 0.99731,
        "Extreme Wind_factor": 0.99731,
        "Soil Subsidence_factor": 0.99731,
        "Freeze-Thaw_factor": 0.99731,
        "Surface Water Flooding_factor": 0.99731,
        "Precipitation_factor": 0.99731,
        "Drought_factor": 0.99731,
        "Cyclone Wind_factor": 0.99731
    },
    2015: {
        "Riverine Flooding_factor": 0.99614,
        "Coastal Inundation_factor": 0.99174,
        "Extreme Heat_factor": 0.99614,
        "Forest Fire_factor": 0.99614,
        "Extreme Wind_factor": 0.99614,
        "Soil Subsidence_factor": 0.99614,
        "Freeze-Thaw_factor": 0.99614,
        "Surface Water Flooding_factor": 0.99614,
        "Precipitation_factor": 0.99614,
        "Drought_factor": 0.99614,
        "Cyclone Wind_factor": 0.99614
    },
    2016: {
        "Riverine Flooding_factor": 0.99454,
        "Coastal Inundation_factor": 0.9883,
        "Extreme Heat_factor": 0.99454,
        "Forest Fire_factor": 0.99454,
        "Extreme Wind_factor": 0.99454,
        "Soil Subsidence_factor": 0.99454,
        "Freeze-Thaw_factor": 0.99454,
        "Surface Water Flooding_factor": 0.99454,
        "Precipitation_factor": 0.99454,
        "Drought_factor": 0.99454,
        "Cyclone Wind_factor": 0.99454
    },
    2017: {
        "Riverine Flooding_factor": 0.99236,
        "Coastal Inundation_factor": 0.98413,
        "Extreme Heat_factor": 0.99236,
        "Forest Fire_factor": 0.99236,
        "Extreme Wind_factor": 0.99236,
        "Soil Subsidence_factor": 0.99236,
        "Freeze-Thaw_factor": 0.99236,
        "Surface Water Flooding_factor": 0.99236,
        "Precipitation_factor": 0.99236,
        "Drought_factor": 0.99236,
        "Cyclone Wind_factor": 0.99236
    },
    2018: {
        "Riverine Flooding_factor": 0.98952,
        "Coastal Inundation_factor": 0.97895,
        "Extreme Heat_factor": 0.98952,
        "Forest Fire_factor": 0.98952,
        "Extreme Wind_factor": 0.98952,
        "Soil Subsidence_factor": 0.98952,
        "Freeze-Thaw_factor": 0.98952,
        "Surface Water Flooding_factor": 0.98952,
        "Precipitation_factor": 0.98952,
        "Drought_factor": 0.98952,
        "Cyclone Wind_factor": 0.98952
    },
    2019: {
        "Riverine Flooding_factor": 0.986,
        "Coastal Inundation_factor": 0.97295,
        "Extreme Heat_factor": 0.986,
        "Forest Fire_factor": 0.986,
        "Extreme Wind_factor": 0.986,
        "Soil Subsidence_factor": 0.986,
        "Freeze-Thaw_factor": 0.986,
        "Surface Water Flooding_factor": 0.986,
        "Precipitation_factor": 0.986,
        "Drought_factor": 0.986,
        "Cyclone Wind_factor": 0.986
    },
    2020: {
        "Riverine Flooding_factor": 0.98176,
        "Coastal Inundation_factor": 0.96674,
        "Extreme Heat_factor": 0.98176,
        "Forest Fire_factor": 0.98176,
        "Extreme Wind_factor": 0.98176,
        "Soil Subsidence_factor": 0.98176,
        "Freeze-Thaw_factor": 0.98176,
        "Surface Water Flooding_factor": 0.98176,
        "Precipitation_factor": 0.98176,
        "Drought_factor": 0.98176,
        "Cyclone Wind_factor": 0.98176
    },
    2021: {
        "Riverine Flooding_factor": 0.97684,
        "Coastal Inundation_factor": 0.95997,
        "Extreme Heat_factor": 0.97684,
        "Forest Fire_factor": 0.97684,
        "Extreme Wind_factor": 0.97684,
        "Soil Subsidence_factor": 0.97684,
        "Freeze-Thaw_factor": 0.97684,
        "Surface Water Flooding_factor": 0.97684,
        "Precipitation_factor": 0.97684,
        "Drought_factor": 0.97684,
        "Cyclone Wind_factor": 0.97684
    },
    2022: {
        "Riverine Flooding_factor": 0.97136,
        "Coastal Inundation_factor": 0.95388,
        "Extreme Heat_factor": 0.97136,
        "Forest Fire_factor": 0.97136,
        "Extreme Wind_factor": 0.97136,
        "Soil Subsidence_factor": 0.97136,
        "Freeze-Thaw_factor": 0.97136,
        "Surface Water Flooding_factor": 0.97136,
        "Precipitation_factor": 0.97136,
        "Drought_factor": 0.97136,
        "Cyclone Wind_factor": 0.97136
    },
    2023: {
        "Riverine Flooding_factor": 0.9655,
        "Coastal Inundation_factor": 0.94779,
        "Extreme Heat_factor": 0.9655,
        "Forest Fire_factor": 0.9655,
        "Extreme Wind_factor": 0.9655,
        "Soil Subsidence_factor": 0.9655,
        "Freeze-Thaw_factor": 0.9655,
        "Surface Water Flooding_factor": 0.9655,
        "Precipitation_factor": 0.9655,
        "Drought_factor": 0.9655,
        "Cyclone Wind_factor": 0.9655
    },
    2024: {
        "Riverine Flooding_factor": 0.95925,
        "Coastal Inundation_factor": 0.94104,
        "Extreme Heat_factor": 0.95925,
        "Forest Fire_factor": 0.95925,
        "Extreme Wind_factor": 0.95925,
        "Soil Subsidence_factor": 0.95925,
        "Freeze-Thaw_factor": 0.95925,
        "Surface Water Flooding_factor": 0.95925,
        "Precipitation_factor": 0.95925,
        "Drought_factor": 0.95925,
        "Cyclone Wind_factor": 0.95925
    },
    2025: {
        "Riverine Flooding_factor": 0.95272,
        "Coastal Inundation_factor": 0.93465,
        "Extreme Heat_factor": 0.95272,
        "Forest Fire_factor": 0.95272,
        "Extreme Wind_factor": 0.95272,
        "Soil Subsidence_factor": 0.95272,
        "Freeze-Thaw_factor": 0.95272,
        "Surface Water Flooding_factor": 0.95272,
        "Precipitation_factor": 0.95272,
        "Drought_factor": 0.95272,
        "Cyclone Wind_factor": 0.95272
    },
    2026: {
        "Riverine Flooding_factor": 0.94603,
        "Coastal Inundation_factor": 0.92912,
        "Extreme Heat_factor": 0.94603,
        "Forest Fire_factor": 0.94603,
        "Extreme Wind_factor": 0.94603,
        "Soil Subsidence_factor": 0.94603,
        "Freeze-Thaw_factor": 0.94603,
        "Surface Water Flooding_factor": 0.94603,
        "Precipitation_factor": 0.94603,
        "Drought_factor": 0.94603,
        "Cyclone Wind_factor": 0.94603
    },
    2027: {
        "Riverine Flooding_factor": 0.93939,
        "Coastal Inundation_factor": 0.9242,
        "Extreme Heat_factor": 0.93939,
        "Forest Fire_factor": 0.93939,
        "Extreme Wind_factor": 0.93939,
        "Soil Subsidence_factor": 0.93939,
        "Freeze-Thaw_factor": 0.93939,
        "Surface Water Flooding_factor": 0.93939,
        "Precipitation_factor": 0.93939,
        "Drought_factor": 0.93939,
        "Cyclone Wind_factor": 0.93939
    },
    2028: {
        "Riverine Flooding_factor": 0.93284,
        "Coastal Inundation_factor": 0.9206,
        "Extreme Heat_factor": 0.93284,
        "Forest Fire_factor": 0.93284,
        "Extreme Wind_factor": 0.93284,
        "Soil Subsidence_factor": 0.93284,
        "Freeze-Thaw_factor": 0.93284,
        "Surface Water Flooding_factor": 0.93284,
        "Precipitation_factor": 0.93284,
        "Drought_factor": 0.93284,
        "Cyclone Wind_factor": 0.93284
    },
    2029: {
        "Riverine Flooding_factor": 0.92643,
        "Coastal Inundation_factor": 0.91751,
        "Extreme Heat_factor": 0.92643,
        "Forest Fire_factor": 0.92643,
        "Extreme Wind_factor": 0.92643,
        "Soil Subsidence_factor": 0.92643,
        "Freeze-Thaw_factor": 0.92643,
        "Surface Water Flooding_factor": 0.92643,
        "Precipitation_factor": 0.92643,
        "Drought_factor": 0.92643,
        "Cyclone Wind_factor": 0.92643
    },
    2030: {
        "Riverine Flooding_factor": 0.92018,
        "Coastal Inundation_factor": 0.91513,
        "Extreme Heat_factor": 0.92018,
        "Forest Fire_factor": 0.92018,
        "Extreme Wind_factor": 0.92018,
        "Soil Subsidence_factor": 0.92018,
        "Freeze-Thaw_factor": 0.92018,
        "Surface Water Flooding_factor": 0.92018,
        "Precipitation_factor": 0.92018,
        "Drought_factor": 0.92018,
        "Cyclone Wind_factor": 0.92018
    },
    2031: {
        "Riverine Flooding_factor": 0.91413,
        "Coastal Inundation_factor": 0.91324,
        "Extreme Heat_factor": 0.91413,
        "Forest Fire_factor": 0.91413,
        "Extreme Wind_factor": 0.91413,
        "Soil Subsidence_factor": 0.91413,
        "Freeze-Thaw_factor": 0.91413,
        "Surface Water Flooding_factor": 0.91413,
        "Precipitation_factor": 0.91413,
        "Drought_factor": 0.91413,
        "Cyclone Wind_factor": 0.91413
    },
    2032: {
        "Riverine Flooding_factor": 0.90822,
        "Coastal Inundation_factor": 0.91111,
        "Extreme Heat_factor": 0.90822,
        "Forest Fire_factor": 0.90822,
        "Extreme Wind_factor": 0.90822,
        "Soil Subsidence_factor": 0.90822,
        "Freeze-Thaw_factor": 0.90822,
        "Surface Water Flooding_factor": 0.90822,
        "Precipitation_factor": 0.90822,
        "Drought_factor": 0.90822,
        "Cyclone Wind_factor": 0.90822
    },
    2033: {
        "Riverine Flooding_factor": 0.90211,
        "Coastal Inundation_factor": 0.90928,
        "Extreme Heat_factor": 0.90211,
        "Forest Fire_factor": 0.90211,
        "Extreme Wind_factor": 0.90211,
        "Soil Subsidence_factor": 0.90211,
        "Freeze-Thaw_factor": 0.90211,
        "Surface Water Flooding_factor": 0.90211,
        "Precipitation_factor": 0.90211,
        "Drought_factor": 0.90211,
        "Cyclone Wind_factor": 0.90211
    },
    2034: {
        "Riverine Flooding_factor": 0.89587,
        "Coastal Inundation_factor": 0.90825,
        "Extreme Heat_factor": 0.89587,
        "Forest Fire_factor": 0.89587,
        "Extreme Wind_factor": 0.89587,
        "Soil Subsidence_factor": 0.89587,
        "Freeze-Thaw_factor": 0.89587,
        "Surface Water Flooding_factor": 0.89587,
        "Precipitation_factor": 0.89587,
        "Drought_factor": 0.89587,
        "Cyclone Wind_factor": 0.89587
    },
    2035: {
        "Riverine Flooding_factor": 0.88952,
        "Coastal Inundation_factor": 0.90741,
        "Extreme Heat_factor": 0.88952,
        "Forest Fire_factor": 0.88952,
        "Extreme Wind_factor": 0.88952,
        "Soil Subsidence_factor": 0.88952,
        "Freeze-Thaw_factor": 0.88952,
        "Surface Water Flooding_factor": 0.88952,
        "Precipitation_factor": 0.88952,
        "Drought_factor": 0.88952,
        "Cyclone Wind_factor": 0.88952
    },
    2036: {
        "Riverine Flooding_factor": 0.88314,
        "Coastal Inundation_factor": 0.90665,
        "Extreme Heat_factor": 0.88314,
        "Forest Fire_factor": 0.88314,
        "Extreme Wind_factor": 0.88314,
        "Soil Subsidence_factor": 0.88314,
        "Freeze-Thaw_factor": 0.88314,
        "Surface Water Flooding_factor": 0.88314,
        "Precipitation_factor": 0.88314,
        "Drought_factor": 0.88314,
        "Cyclone Wind_factor": 0.88314
    },
    2037: {
        "Riverine Flooding_factor": 0.87667,
        "Coastal Inundation_factor": 0.90598,
        "Extreme Heat_factor": 0.87667,
        "Forest Fire_factor": 0.87667,
        "Extreme Wind_factor": 0.87667,
        "Soil Subsidence_factor": 0.87667,
        "Freeze-Thaw_factor": 0.87667,
        "Surface Water Flooding_factor": 0.87667,
        "Precipitation_factor": 0.87667,
        "Drought_factor": 0.87667,
        "Cyclone Wind_factor": 0.87667
    },
    2038: {
        "Riverine Flooding_factor": 0.87016,
        "Coastal Inundation_factor": 0.90474,
        "Extreme Heat_factor": 0.87016,
        "Forest Fire_factor": 0.87016,
        "Extreme Wind_factor": 0.87016,
        "Soil Subsidence_factor": 0.87016,
        "Freeze-Thaw_factor": 0.87016,
        "Surface Water Flooding_factor": 0.87016,
        "Precipitation_factor": 0.87016,
        "Drought_factor": 0.87016,
        "Cyclone Wind_factor": 0.87016
    },
    2039: {
        "Riverine Flooding_factor": 0.86361,
        "Coastal Inundation_factor": 0.90389,
        "Extreme Heat_factor": 0.86361,
        "Forest Fire_factor": 0.86361,
        "Extreme Wind_factor": 0.86361,
        "Soil Subsidence_factor": 0.86361,
        "Freeze-Thaw_factor": 0.86361,
        "Surface Water Flooding_factor": 0.86361,
        "Precipitation_factor": 0.86361,
        "Drought_factor": 0.86361,
        "Cyclone Wind_factor": 0.86361
    },
    2040: {
        "Riverine Flooding_factor": 0.85704,
        "Coastal Inundation_factor": 0.90302,
        "Extreme Heat_factor": 0.85704,
        "Forest Fire_factor": 0.85704,
        "Extreme Wind_factor": 0.85704,
        "Soil Subsidence_factor": 0.85704,
        "Freeze-Thaw_factor": 0.85704,
        "Surface Water Flooding_factor": 0.85704,
        "Precipitation_factor": 0.85704,
        "Drought_factor": 0.85704,
        "Cyclone Wind_factor": 0.85704
    },
    2041: {
        "Riverine Flooding_factor": 0.85046,
        "Coastal Inundation_factor": 0.90247,
        "Extreme Heat_factor": 0.85046,
        "Forest Fire_factor": 0.85046,
        "Extreme Wind_factor": 0.85046,
        "Soil Subsidence_factor": 0.85046,
        "Freeze-Thaw_factor": 0.85046,
        "Surface Water Flooding_factor": 0.85046,
        "Precipitation_factor": 0.85046,
        "Drought_factor": 0.85046,
        "Cyclone Wind_factor": 0.85046
    },
    2042: {
        "Riverine Flooding_factor": 0.84387,
        "Coastal Inundation_factor": 0.90208,
        "Extreme Heat_factor": 0.84387,
        "Forest Fire_factor": 0.84387,
        "Extreme Wind_factor": 0.84387,
        "Soil Subsidence_factor": 0.84387,
        "Freeze-Thaw_factor": 0.84387,
        "Surface Water Flooding_factor": 0.84387,
        "Precipitation_factor": 0.84387,
        "Drought_factor": 0.84387,
        "Cyclone Wind_factor": 0.84387
    },
    2043: {
        "Riverine Flooding_factor": 0.83754,
        "Coastal Inundation_factor": 0.90179,
        "Extreme Heat_factor": 0.83754,
        "Forest Fire_factor": 0.83754,
        "Extreme Wind_factor": 0.83754,
        "Soil Subsidence_factor": 0.83754,
        "Freeze-Thaw_factor": 0.83754,
        "Surface Water Flooding_factor": 0.83754,
        "Precipitation_factor": 0.83754,
        "Drought_factor": 0.83754,
        "Cyclone Wind_factor": 0.83754
    },
    2044: {
        "Riverine Flooding_factor": 0.83146,
        "Coastal Inundation_factor": 0.90155,
        "Extreme Heat_factor": 0.83146,
        "Forest Fire_factor": 0.83146,
        "Extreme Wind_factor": 0.83146,
        "Soil Subsidence_factor": 0.83146,
        "Freeze-Thaw_factor": 0.83146,
        "Surface Water Flooding_factor": 0.83146,
        "Precipitation_factor": 0.83146,
        "Drought_factor": 0.83146,
        "Cyclone Wind_factor": 0.83146
    },
    2045: {
        "Riverine Flooding_factor": 0.82558,
        "Coastal Inundation_factor": 0.90137,
        "Extreme Heat_factor": 0.82558,
        "Forest Fire_factor": 0.82558,
        "Extreme Wind_factor": 0.82558,
        "Soil Subsidence_factor": 0.82558,
        "Freeze-Thaw_factor": 0.82558,
        "Surface Water Flooding_factor": 0.82558,
        "Precipitation_factor": 0.82558,
        "Drought_factor": 0.82558,
        "Cyclone Wind_factor": 0.82558
    },
    2046: {
        "Riverine Flooding_factor": 0.81976,
        "Coastal Inundation_factor": 0.90122,
        "Extreme Heat_factor": 0.81976,
        "Forest Fire_factor": 0.81976,
        "Extreme Wind_factor": 0.81976,
        "Soil Subsidence_factor": 0.81976,
        "Freeze-Thaw_factor": 0.81976,
        "Surface Water Flooding_factor": 0.81976,
        "Precipitation_factor": 0.81976,
        "Drought_factor": 0.81976,
        "Cyclone Wind_factor": 0.81976
    },
    2047: {
        "Riverine Flooding_factor": 0.81398,
        "Coastal Inundation_factor": 0.90095,
        "Extreme Heat_factor": 0.81398,
        "Forest Fire_factor": 0.81398,
        "Extreme Wind_factor": 0.81398,
        "Soil Subsidence_factor": 0.81398,
        "Freeze-Thaw_factor": 0.81398,
        "Surface Water Flooding_factor": 0.81398,
        "Precipitation_factor": 0.81398,
        "Drought_factor": 0.81398,
        "Cyclone Wind_factor": 0.81398
    },
    2048: {
        "Riverine Flooding_factor": 0.80823,
        "Coastal Inundation_factor": 0.90098,
        "Extreme Heat_factor": 0.80823,
        "Forest Fire_factor": 0.80823,
        "Extreme Wind_factor": 0.80823,
        "Soil Subsidence_factor": 0.80823,
        "Freeze-Thaw_factor": 0.80823,
        "Surface Water Flooding_factor": 0.80823,
        "Precipitation_factor": 0.80823,
        "Drought_factor": 0.80823,
        "Cyclone Wind_factor": 0.80823
    },
    2049: {
        "Riverine Flooding_factor": 0.80252,
        "Coastal Inundation_factor": 0.901,
        "Extreme Heat_factor": 0.80252,
        "Forest Fire_factor": 0.80252,
        "Extreme Wind_factor": 0.80252,
        "Soil Subsidence_factor": 0.80252,
        "Freeze-Thaw_factor": 0.80252,
        "Surface Water Flooding_factor": 0.80252,
        "Precipitation_factor": 0.80252,
        "Drought_factor": 0.80252,
        "Cyclone Wind_factor": 0.80252
    },
    2050: {
        "Riverine Flooding_factor": 0.79685,
        "Coastal Inundation_factor": 0.90086,
        "Extreme Heat_factor": 0.79685,
        "Forest Fire_factor": 0.79685,
        "Extreme Wind_factor": 0.79685,
        "Soil Subsidence_factor": 0.79685,
        "Freeze-Thaw_factor": 0.79685,
        "Surface Water Flooding_factor": 0.79685,
        "Precipitation_factor": 0.79685,
        "Drought_factor": 0.79685,
        "Cyclone Wind_factor": 0.79685
    },
    2051: {
        "Riverine Flooding_factor": 0.7912,
        "Coastal Inundation_factor": 0.90056,
        "Extreme Heat_factor": 0.7912,
        "Forest Fire_factor": 0.7912,
        "Extreme Wind_factor": 0.7912,
        "Soil Subsidence_factor": 0.7912,
        "Freeze-Thaw_factor": 0.7912,
        "Surface Water Flooding_factor": 0.7912,
        "Precipitation_factor": 0.7912,
        "Drought_factor": 0.7912,
        "Cyclone Wind_factor": 0.7912
    },
    2052: {
        "Riverine Flooding_factor": 0.78558,
        "Coastal Inundation_factor": 0.90032,
        "Extreme Heat_factor": 0.78558,
        "Forest Fire_factor": 0.78558,
        "Extreme Wind_factor": 0.78558,
        "Soil Subsidence_factor": 0.78558,
        "Freeze-Thaw_factor": 0.78558,
        "Surface Water Flooding_factor": 0.78558,
        "Precipitation_factor": 0.78558,
        "Drought_factor": 0.78558,
        "Cyclone Wind_factor": 0.78558
    },
    2053: {
        "Riverine Flooding_factor": 0.77995,
        "Coastal Inundation_factor": 0.90012,
        "Extreme Heat_factor": 0.77995,
        "Forest Fire_factor": 0.77995,
        "Extreme Wind_factor": 0.77995,
        "Soil Subsidence_factor": 0.77995,
        "Freeze-Thaw_factor": 0.77995,
        "Surface Water Flooding_factor": 0.77995,
        "Precipitation_factor": 0.77995,
        "Drought_factor": 0.77995,
        "Cyclone Wind_factor": 0.77995
    },
    2054: {
        "Riverine Flooding_factor": 0.77434,
        "Coastal Inundation_factor": 0.89993,
        "Extreme Heat_factor": 0.77434,
        "Forest Fire_factor": 0.77434,
        "Extreme Wind_factor": 0.77434,
        "Soil Subsidence_factor": 0.77434,
        "Freeze-Thaw_factor": 0.77434,
        "Surface Water Flooding_factor": 0.77434,
        "Precipitation_factor": 0.77434,
        "Drought_factor": 0.77434,
        "Cyclone Wind_factor": 0.77434
    },
    2055: {
        "Riverine Flooding_factor": 0.76877,
        "Coastal Inundation_factor": 0.89966,
        "Extreme Heat_factor": 0.76877,
        "Forest Fire_factor": 0.76877,
        "Extreme Wind_factor": 0.76877,
        "Soil Subsidence_factor": 0.76877,
        "Freeze-Thaw_factor": 0.76877,
        "Surface Water Flooding_factor": 0.76877,
        "Precipitation_factor": 0.76877,
        "Drought_factor": 0.76877,
        "Cyclone Wind_factor": 0.76877
    },
    2056: {
        "Riverine Flooding_factor": 0.76331,
        "Coastal Inundation_factor": 0.89928,
        "Extreme Heat_factor": 0.76331,
        "Forest Fire_factor": 0.76331,
        "Extreme Wind_factor": 0.76331,
        "Soil Subsidence_factor": 0.76331,
        "Freeze-Thaw_factor": 0.76331,
        "Surface Water Flooding_factor": 0.76331,
        "Precipitation_factor": 0.76331,
        "Drought_factor": 0.76331,
        "Cyclone Wind_factor": 0.76331
    },
    2057: {
        "Riverine Flooding_factor": 0.75799,
        "Coastal Inundation_factor": 0.89888,
        "Extreme Heat_factor": 0.75799,
        "Forest Fire_factor": 0.75799,
        "Extreme Wind_factor": 0.75799,
        "Soil Subsidence_factor": 0.75799,
        "Freeze-Thaw_factor": 0.75799,
        "Surface Water Flooding_factor": 0.75799,
        "Precipitation_factor": 0.75799,
        "Drought_factor": 0.75799,
        "Cyclone Wind_factor": 0.75799
    },
    2058: {
        "Riverine Flooding_factor": 0.75282,
        "Coastal Inundation_factor": 0.89826,
        "Extreme Heat_factor": 0.75282,
        "Forest Fire_factor": 0.75282,
        "Extreme Wind_factor": 0.75282,
        "Soil Subsidence_factor": 0.75282,
        "Freeze-Thaw_factor": 0.75282,
        "Surface Water Flooding_factor": 0.75282,
        "Precipitation_factor": 0.75282,
        "Drought_factor": 0.75282,
        "Cyclone Wind_factor": 0.75282
    },
    2059: {
        "Riverine Flooding_factor": 0.74782,
        "Coastal Inundation_factor": 0.89742,
        "Extreme Heat_factor": 0.74782,
        "Forest Fire_factor": 0.74782,
        "Extreme Wind_factor": 0.74782,
        "Soil Subsidence_factor": 0.74782,
        "Freeze-Thaw_factor": 0.74782,
        "Surface Water Flooding_factor": 0.74782,
        "Precipitation_factor": 0.74782,
        "Drought_factor": 0.74782,
        "Cyclone Wind_factor": 0.74782
    },
    2060: {
        "Riverine Flooding_factor": 0.743,
        "Coastal Inundation_factor": 0.89653,
        "Extreme Heat_factor": 0.743,
        "Forest Fire_factor": 0.743,
        "Extreme Wind_factor": 0.743,
        "Soil Subsidence_factor": 0.743,
        "Freeze-Thaw_factor": 0.743,
        "Surface Water Flooding_factor": 0.743,
        "Precipitation_factor": 0.743,
        "Drought_factor": 0.743,
        "Cyclone Wind_factor": 0.743
    },
    2061: {
        "Riverine Flooding_factor": 0.73836,
        "Coastal Inundation_factor": 0.89558,
        "Extreme Heat_factor": 0.73836,
        "Forest Fire_factor": 0.73836,
        "Extreme Wind_factor": 0.73836,
        "Soil Subsidence_factor": 0.73836,
        "Freeze-Thaw_factor": 0.73836,
        "Surface Water Flooding_factor": 0.73836,
        "Precipitation_factor": 0.73836,
        "Drought_factor": 0.73836,
        "Cyclone Wind_factor": 0.73836
    },
    2062: {
        "Riverine Flooding_factor": 0.73391,
        "Coastal Inundation_factor": 0.89456,
        "Extreme Heat_factor": 0.73391,
        "Forest Fire_factor": 0.73391,
        "Extreme Wind_factor": 0.73391,
        "Soil Subsidence_factor": 0.73391,
        "Freeze-Thaw_factor": 0.73391,
        "Surface Water Flooding_factor": 0.73391,
        "Precipitation_factor": 0.73391,
        "Drought_factor": 0.73391,
        "Cyclone Wind_factor": 0.73391
    },
    2063: {
        "Riverine Flooding_factor": 0.72966,
        "Coastal Inundation_factor": 0.89347,
        "Extreme Heat_factor": 0.72966,
        "Forest Fire_factor": 0.72966,
        "Extreme Wind_factor": 0.72966,
        "Soil Subsidence_factor": 0.72966,
        "Freeze-Thaw_factor": 0.72966,
        "Surface Water Flooding_factor": 0.72966,
        "Precipitation_factor": 0.72966,
        "Drought_factor": 0.72966,
        "Cyclone Wind_factor": 0.72966
    },
    2064: {
        "Riverine Flooding_factor": 0.72559,
        "Coastal Inundation_factor": 0.8922,
        "Extreme Heat_factor": 0.72559,
        "Forest Fire_factor": 0.72559,
        "Extreme Wind_factor": 0.72559,
        "Soil Subsidence_factor": 0.72559,
        "Freeze-Thaw_factor": 0.72559,
        "Surface Water Flooding_factor": 0.72559,
        "Precipitation_factor": 0.72559,
        "Drought_factor": 0.72559,
        "Cyclone Wind_factor": 0.72559
    },
    2065: {
        "Riverine Flooding_factor": 0.7217,
        "Coastal Inundation_factor": 0.89092,
        "Extreme Heat_factor": 0.7217,
        "Forest Fire_factor": 0.7217,
        "Extreme Wind_factor": 0.7217,
        "Soil Subsidence_factor": 0.7217,
        "Freeze-Thaw_factor": 0.7217,
        "Surface Water Flooding_factor": 0.7217,
        "Precipitation_factor": 0.7217,
        "Drought_factor": 0.7217,
        "Cyclone Wind_factor": 0.7217
    },
    2066: {
        "Riverine Flooding_factor": 0.71799,
        "Coastal Inundation_factor": 0.88959,
        "Extreme Heat_factor": 0.71799,
        "Forest Fire_factor": 0.71799,
        "Extreme Wind_factor": 0.71799,
        "Soil Subsidence_factor": 0.71799,
        "Freeze-Thaw_factor": 0.71799,
        "Surface Water Flooding_factor": 0.71799,
        "Precipitation_factor": 0.71799,
        "Drought_factor": 0.71799,
        "Cyclone Wind_factor": 0.71799
    },
    2067: {
        "Riverine Flooding_factor": 0.71443,
        "Coastal Inundation_factor": 0.88844,
        "Extreme Heat_factor": 0.71443,
        "Forest Fire_factor": 0.71443,
        "Extreme Wind_factor": 0.71443,
        "Soil Subsidence_factor": 0.71443,
        "Freeze-Thaw_factor": 0.71443,
        "Surface Water Flooding_factor": 0.71443,
        "Precipitation_factor": 0.71443,
        "Drought_factor": 0.71443,
        "Cyclone Wind_factor": 0.71443
    },
    2068: {
        "Riverine Flooding_factor": 0.71101,
        "Coastal Inundation_factor": 0.88745,
        "Extreme Heat_factor": 0.71101,
        "Forest Fire_factor": 0.71101,
        "Extreme Wind_factor": 0.71101,
        "Soil Subsidence_factor": 0.71101,
        "Freeze-Thaw_factor": 0.71101,
        "Surface Water Flooding_factor": 0.71101,
        "Precipitation_factor": 0.71101,
        "Drought_factor": 0.71101,
        "Cyclone Wind_factor": 0.71101
    },
    2069: {
        "Riverine Flooding_factor": 0.70771,
        "Coastal Inundation_factor": 0.88663,
        "Extreme Heat_factor": 0.70771,
        "Forest Fire_factor": 0.70771,
        "Extreme Wind_factor": 0.70771,
        "Soil Subsidence_factor": 0.70771,
        "Freeze-Thaw_factor": 0.70771,
        "Surface Water Flooding_factor": 0.70771,
        "Precipitation_factor": 0.70771,
        "Drought_factor": 0.70771,
        "Cyclone Wind_factor": 0.70771
    },
    2070: {
        "Riverine Flooding_factor": 0.70455,
        "Coastal Inundation_factor": 0.88588,
        "Extreme Heat_factor": 0.70455,
        "Forest Fire_factor": 0.70455,
        "Extreme Wind_factor": 0.70455,
        "Soil Subsidence_factor": 0.70455,
        "Freeze-Thaw_factor": 0.70455,
        "Surface Water Flooding_factor": 0.70455,
        "Precipitation_factor": 0.70455,
        "Drought_factor": 0.70455,
        "Cyclone Wind_factor": 0.70455
    },
    2071: {
        "Riverine Flooding_factor": 0.7015,
        "Coastal Inundation_factor": 0.88526,
        "Extreme Heat_factor": 0.7015,
        "Forest Fire_factor": 0.7015,
        "Extreme Wind_factor": 0.7015,
        "Soil Subsidence_factor": 0.7015,
        "Freeze-Thaw_factor": 0.7015,
        "Surface Water Flooding_factor": 0.7015,
        "Precipitation_factor": 0.7015,
        "Drought_factor": 0.7015,
        "Cyclone Wind_factor": 0.7015
    },
    2072: {
        "Riverine Flooding_factor": 0.69855,
        "Coastal Inundation_factor": 0.88477,
        "Extreme Heat_factor": 0.69855,
        "Forest Fire_factor": 0.69855,
        "Extreme Wind_factor": 0.69855,
        "Soil Subsidence_factor": 0.69855,
        "Freeze-Thaw_factor": 0.69855,
        "Surface Water Flooding_factor": 0.69855,
        "Precipitation_factor": 0.69855,
        "Drought_factor": 0.69855,
        "Cyclone Wind_factor": 0.69855
    },
    2073: {
        "Riverine Flooding_factor": 0.69567,
        "Coastal Inundation_factor": 0.8844,
        "Extreme Heat_factor": 0.69567,
        "Forest Fire_factor": 0.69567,
        "Extreme Wind_factor": 0.69567,
        "Soil Subsidence_factor": 0.69567,
        "Freeze-Thaw_factor": 0.69567,
        "Surface Water Flooding_factor": 0.69567,
        "Precipitation_factor": 0.69567,
        "Drought_factor": 0.69567,
        "Cyclone Wind_factor": 0.69567
    },
    2074: {
        "Riverine Flooding_factor": 0.69282,
        "Coastal Inundation_factor": 0.88431,
        "Extreme Heat_factor": 0.69282,
        "Forest Fire_factor": 0.69282,
        "Extreme Wind_factor": 0.69282,
        "Soil Subsidence_factor": 0.69282,
        "Freeze-Thaw_factor": 0.69282,
        "Surface Water Flooding_factor": 0.69282,
        "Precipitation_factor": 0.69282,
        "Drought_factor": 0.69282,
        "Cyclone Wind_factor": 0.69282
    },
    2075: {
        "Riverine Flooding_factor": 0.68995,
        "Coastal Inundation_factor": 0.88443,
        "Extreme Heat_factor": 0.68995,
        "Forest Fire_factor": 0.68995,
        "Extreme Wind_factor": 0.68995,
        "Soil Subsidence_factor": 0.68995,
        "Freeze-Thaw_factor": 0.68995,
        "Surface Water Flooding_factor": 0.68995,
        "Precipitation_factor": 0.68995,
        "Drought_factor": 0.68995,
        "Cyclone Wind_factor": 0.68995
    },
    2076: {
        "Riverine Flooding_factor": 0.68702,
        "Coastal Inundation_factor": 0.88473,
        "Extreme Heat_factor": 0.68702,
        "Forest Fire_factor": 0.68702,
        "Extreme Wind_factor": 0.68702,
        "Soil Subsidence_factor": 0.68702,
        "Freeze-Thaw_factor": 0.68702,
        "Surface Water Flooding_factor": 0.68702,
        "Precipitation_factor": 0.68702,
        "Drought_factor": 0.68702,
        "Cyclone Wind_factor": 0.68702
    },
    2077: {
        "Riverine Flooding_factor": 0.684,
        "Coastal Inundation_factor": 0.88512,
        "Extreme Heat_factor": 0.684,
        "Forest Fire_factor": 0.684,
        "Extreme Wind_factor": 0.684,
        "Soil Subsidence_factor": 0.684,
        "Freeze-Thaw_factor": 0.684,
        "Surface Water Flooding_factor": 0.684,
        "Precipitation_factor": 0.684,
        "Drought_factor": 0.684,
        "Cyclone Wind_factor": 0.684
    },
    2078: {
        "Riverine Flooding_factor": 0.68087,
        "Coastal Inundation_factor": 0.88545,
        "Extreme Heat_factor": 0.68087,
        "Forest Fire_factor": 0.68087,
        "Extreme Wind_factor": 0.68087,
        "Soil Subsidence_factor": 0.68087,
        "Freeze-Thaw_factor": 0.68087,
        "Surface Water Flooding_factor": 0.68087,
        "Precipitation_factor": 0.68087,
        "Drought_factor": 0.68087,
        "Cyclone Wind_factor": 0.68087
    },
    2079: {
        "Riverine Flooding_factor": 0.67758,
        "Coastal Inundation_factor": 0.88574,
        "Extreme Heat_factor": 0.67758,
        "Forest Fire_factor": 0.67758,
        "Extreme Wind_factor": 0.67758,
        "Soil Subsidence_factor": 0.67758,
        "Freeze-Thaw_factor": 0.67758,
        "Surface Water Flooding_factor": 0.67758,
        "Precipitation_factor": 0.67758,
        "Drought_factor": 0.67758,
        "Cyclone Wind_factor": 0.67758
    },
    2080: {
        "Riverine Flooding_factor": 0.6741,
        "Coastal Inundation_factor": 0.88612,
        "Extreme Heat_factor": 0.6741,
        "Forest Fire_factor": 0.6741,
        "Extreme Wind_factor": 0.6741,
        "Soil Subsidence_factor": 0.6741,
        "Freeze-Thaw_factor": 0.6741,
        "Surface Water Flooding_factor": 0.6741,
        "Precipitation_factor": 0.6741,
        "Drought_factor": 0.6741,
        "Cyclone Wind_factor": 0.6741
    },
    2081: {
        "Riverine Flooding_factor": 0.67041,
        "Coastal Inundation_factor": 0.88644,
        "Extreme Heat_factor": 0.67041,
        "Forest Fire_factor": 0.67041,
        "Extreme Wind_factor": 0.67041,
        "Soil Subsidence_factor": 0.67041,
        "Freeze-Thaw_factor": 0.67041,
        "Surface Water Flooding_factor": 0.67041,
        "Precipitation_factor": 0.67041,
        "Drought_factor": 0.67041,
        "Cyclone Wind_factor": 0.67041
    },
    2082: {
        "Riverine Flooding_factor": 0.66647,
        "Coastal Inundation_factor": 0.88664,
        "Extreme Heat_factor": 0.66647,
        "Forest Fire_factor": 0.66647,
        "Extreme Wind_factor": 0.66647,
        "Soil Subsidence_factor": 0.66647,
        "Freeze-Thaw_factor": 0.66647,
        "Surface Water Flooding_factor": 0.66647,
        "Precipitation_factor": 0.66647,
        "Drought_factor": 0.66647,
        "Cyclone Wind_factor": 0.66647
    },
    2083: {
        "Riverine Flooding_factor": 0.66231,
        "Coastal Inundation_factor": 0.88672,
        "Extreme Heat_factor": 0.66231,
        "Forest Fire_factor": 0.66231,
        "Extreme Wind_factor": 0.66231,
        "Soil Subsidence_factor": 0.66231,
        "Freeze-Thaw_factor": 0.66231,
        "Surface Water Flooding_factor": 0.66231,
        "Precipitation_factor": 0.66231,
        "Drought_factor": 0.66231,
        "Cyclone Wind_factor": 0.66231
    },
    2084: {
        "Riverine Flooding_factor": 0.65796,
        "Coastal Inundation_factor": 0.88659,
        "Extreme Heat_factor": 0.65796,
        "Forest Fire_factor": 0.65796,
        "Extreme Wind_factor": 0.65796,
        "Soil Subsidence_factor": 0.65796,
        "Freeze-Thaw_factor": 0.65796,
        "Surface Water Flooding_factor": 0.65796,
        "Precipitation_factor": 0.65796,
        "Drought_factor": 0.65796,
        "Cyclone Wind_factor": 0.65796
    },
    2085: {
        "Riverine Flooding_factor": 0.65344,
        "Coastal Inundation_factor": 0.88638,
        "Extreme Heat_factor": 0.65344,
        "Forest Fire_factor": 0.65344,
        "Extreme Wind_factor": 0.65344,
        "Soil Subsidence_factor": 0.65344,
        "Freeze-Thaw_factor": 0.65344,
        "Surface Water Flooding_factor": 0.65344,
        "Precipitation_factor": 0.65344,
        "Drought_factor": 0.65344,
        "Cyclone Wind_factor": 0.65344
    },
    2086: {
        "Riverine Flooding_factor": 0.64878,
        "Coastal Inundation_factor": 0.88611,
        "Extreme Heat_factor": 0.64878,
        "Forest Fire_factor": 0.64878,
        "Extreme Wind_factor": 0.64878,
        "Soil Subsidence_factor": 0.64878,
        "Freeze-Thaw_factor": 0.64878,
        "Surface Water Flooding_factor": 0.64878,
        "Precipitation_factor": 0.64878,
        "Drought_factor": 0.64878,
        "Cyclone Wind_factor": 0.64878
    },
    2087: {
        "Riverine Flooding_factor": 0.64401,
        "Coastal Inundation_factor": 0.88576,
        "Extreme Heat_factor": 0.64401,
        "Forest Fire_factor": 0.64401,
        "Extreme Wind_factor": 0.64401,
        "Soil Subsidence_factor": 0.64401,
        "Freeze-Thaw_factor": 0.64401,
        "Surface Water Flooding_factor": 0.64401,
        "Precipitation_factor": 0.64401,
        "Drought_factor": 0.64401,
        "Cyclone Wind_factor": 0.64401
    },
    2088: {
        "Riverine Flooding_factor": 0.63916,
        "Coastal Inundation_factor": 0.8855,
        "Extreme Heat_factor": 0.63916,
        "Forest Fire_factor": 0.63916,
        "Extreme Wind_factor": 0.63916,
        "Soil Subsidence_factor": 0.63916,
        "Freeze-Thaw_factor": 0.63916,
        "Surface Water Flooding_factor": 0.63916,
        "Precipitation_factor": 0.63916,
        "Drought_factor": 0.63916,
        "Cyclone Wind_factor": 0.63916
    },
    2089: {
        "Riverine Flooding_factor": 0.63425,
        "Coastal Inundation_factor": 0.88532,
        "Extreme Heat_factor": 0.63425,
        "Forest Fire_factor": 0.63425,
        "Extreme Wind_factor": 0.63425,
        "Soil Subsidence_factor": 0.63425,
        "Freeze-Thaw_factor": 0.63425,
        "Surface Water Flooding_factor": 0.63425,
        "Precipitation_factor": 0.63425,
        "Drought_factor": 0.63425,
        "Cyclone Wind_factor": 0.63425
    },
    2090: {
        "Riverine Flooding_factor": 0.6293,
        "Coastal Inundation_factor": 0.88509,
        "Extreme Heat_factor": 0.6293,
        "Forest Fire_factor": 0.6293,
        "Extreme Wind_factor": 0.6293,
        "Soil Subsidence_factor": 0.6293,
        "Freeze-Thaw_factor": 0.6293,
        "Surface Water Flooding_factor": 0.6293,
        "Precipitation_factor": 0.6293,
        "Drought_factor": 0.6293,
        "Cyclone Wind_factor": 0.6293
    },
    2091: {
        "Riverine Flooding_factor": 0.62435,
        "Coastal Inundation_factor": 0.88493,
        "Extreme Heat_factor": 0.62435,
        "Forest Fire_factor": 0.62435,
        "Extreme Wind_factor": 0.62435,
        "Soil Subsidence_factor": 0.62435,
        "Freeze-Thaw_factor": 0.62435,
        "Surface Water Flooding_factor": 0.62435,
        "Precipitation_factor": 0.62435,
        "Drought_factor": 0.62435,
        "Cyclone Wind_factor": 0.62435
    },
    2092: {
        "Riverine Flooding_factor": 0.61943,
        "Coastal Inundation_factor": 0.88498,
        "Extreme Heat_factor": 0.61943,
        "Forest Fire_factor": 0.61943,
        "Extreme Wind_factor": 0.61943,
        "Soil Subsidence_factor": 0.61943,
        "Freeze-Thaw_factor": 0.61943,
        "Surface Water Flooding_factor": 0.61943,
        "Precipitation_factor": 0.61943,
        "Drought_factor": 0.61943,
        "Cyclone Wind_factor": 0.61943
    },
    2093: {
        "Riverine Flooding_factor": 0.61476,
        "Coastal Inundation_factor": 0.88515,
        "Extreme Heat_factor": 0.61476,
        "Forest Fire_factor": 0.61476,
        "Extreme Wind_factor": 0.61476,
        "Soil Subsidence_factor": 0.61476,
        "Freeze-Thaw_factor": 0.61476,
        "Surface Water Flooding_factor": 0.61476,
        "Precipitation_factor": 0.61476,
        "Drought_factor": 0.61476,
        "Cyclone Wind_factor": 0.61476
    },
    2094: {
        "Riverine Flooding_factor": 0.61036,
        "Coastal Inundation_factor": 0.88544,
        "Extreme Heat_factor": 0.61036,
        "Forest Fire_factor": 0.61036,
        "Extreme Wind_factor": 0.61036,
        "Soil Subsidence_factor": 0.61036,
        "Freeze-Thaw_factor": 0.61036,
        "Surface Water Flooding_factor": 0.61036,
        "Precipitation_factor": 0.61036,
        "Drought_factor": 0.61036,
        "Cyclone Wind_factor": 0.61036
    },
    2095: {
        "Riverine Flooding_factor": 0.60621,
        "Coastal Inundation_factor": 0.88575,
        "Extreme Heat_factor": 0.60621,
        "Forest Fire_factor": 0.60621,
        "Extreme Wind_factor": 0.60621,
        "Soil Subsidence_factor": 0.60621,
        "Freeze-Thaw_factor": 0.60621,
        "Surface Water Flooding_factor": 0.60621,
        "Precipitation_factor": 0.60621,
        "Drought_factor": 0.60621,
        "Cyclone Wind_factor": 0.60621
    },
    2096: {
        "Riverine Flooding_factor": 0.60233,
        "Coastal Inundation_factor": 0.88606,
        "Extreme Heat_factor": 0.60233,
        "Forest Fire_factor": 0.60233,
        "Extreme Wind_factor": 0.60233,
        "Soil Subsidence_factor": 0.60233,
        "Freeze-Thaw_factor": 0.60233,
        "Surface Water Flooding_factor": 0.60233,
        "Precipitation_factor": 0.60233,
        "Drought_factor": 0.60233,
        "Cyclone Wind_factor": 0.60233
    },
    2097: {
        "Riverine Flooding_factor": 0.60016,
        "Coastal Inundation_factor": 0.88622,
        "Extreme Heat_factor": 0.60016,
        "Forest Fire_factor": 0.60016,
        "Extreme Wind_factor": 0.60016,
        "Soil Subsidence_factor": 0.60016,
        "Freeze-Thaw_factor": 0.60016,
        "Surface Water Flooding_factor": 0.60016,
        "Precipitation_factor": 0.60016,
        "Drought_factor": 0.60016,
        "Cyclone Wind_factor": 0.60016
    },
    2098: {
        "Riverine Flooding_factor": 0.59808,
        "Coastal Inundation_factor": 0.8864,
        "Extreme Heat_factor": 0.59808,
        "Forest Fire_factor": 0.59808,
        "Extreme Wind_factor": 0.59808,
        "Soil Subsidence_factor": 0.59808,
        "Freeze-Thaw_factor": 0.59808,
        "Surface Water Flooding_factor": 0.59808,
        "Precipitation_factor": 0.59808,
        "Drought_factor": 0.59808,
        "Cyclone Wind_factor": 0.59808
    },
    2099: {
        "Riverine Flooding_factor": 0.59609,
        "Coastal Inundation_factor": 0.88658,
        "Extreme Heat_factor": 0.59609,
        "Forest Fire_factor": 0.59609,
        "Extreme Wind_factor": 0.59609,
        "Soil Subsidence_factor": 0.59609,
        "Freeze-Thaw_factor": 0.59609,
        "Surface Water Flooding_factor": 0.59609,
        "Precipitation_factor": 0.59609,
        "Drought_factor": 0.59609,
        "Cyclone Wind_factor": 0.59609
    },
    2100: {
        "Riverine Flooding_factor": 0.59426,
        "Coastal Inundation_factor": 0.88685,
        "Extreme Heat_factor": 0.59426,
        "Forest Fire_factor": 0.59426,
        "Extreme Wind_factor": 0.59426,
        "Soil Subsidence_factor": 0.59426,
        "Freeze-Thaw_factor": 0.59426,
        "Surface Water Flooding_factor": 0.59426,
        "Precipitation_factor": 0.59426,
        "Drought_factor": 0.59426,
        "Cyclone Wind_factor": 0.59426
    }
};
