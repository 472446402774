import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext } from "react"
import { Accordion, AccordionContext, Card } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./ControlledAccordion.scss";

function Toggle({ children, eventKey }) {
    const currentEventKey = useContext(AccordionContext);

    const classes = classNames("controlled-accordion__header", {
        "controlled-accordion__header--expanded": currentEventKey === eventKey
    })

    return <Accordion.Toggle className={classes} as={Card.Header} eventKey={eventKey}>
        <MdKeyboardArrowRight size="1.5em" />
        { children }
    </Accordion.Toggle>;
}

Toggle.propTypes = {
    children: PropTypes.string,
    eventKey: PropTypes.string
}

export const ControlledAccordion = ({ children, defaultActiveKey = "0" }) => {
    return <Accordion defaultActiveKey={defaultActiveKey} className="controlled-accordion">
        {children.map((child, index) => {
            if (!child) {
                return null;
            }

            const key = `${index}`;

            return <Card key={key}>
                <Toggle eventKey={key}>
                    { child.title }
                </Toggle>
                <Accordion.Collapse eventKey={key}>
                    <Card.Body>
                        { child.body }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        })}
    </Accordion>;
}

ControlledAccordion.propTypes = {
    children: PropTypes.array,
    defaultActiveKey: PropTypes.string
}