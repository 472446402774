import React from "react";
import PropTypes from "prop-types";

import { ToolbarAreaOptionsParameter } from "./ToolbarAreaOptionsParameter.jsx";

export const ToolbarAreaOptions = ({ options }) => {
    return <div id="toolbar-area-options" className="toolbar__area-options cesium-baseLayerPicker">
        {options.map(({ callback, id, options, title }) => (
            <ToolbarAreaOptionsParameter
                key={id}
                callback={callback}
                id={id}
                options={options}
                title={title}
            />
        ))}
    </div>;
}

ToolbarAreaOptions.propTypes = {
    options: PropTypes.array,
}