export const scenario26Adjustment = {
    1990: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1991: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1992: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1993: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1994: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1995: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1996: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1997: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1998: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    1999: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2000: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2001: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2002: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2003: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2004: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2005: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2006: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2007: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2008: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2009: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 1.0,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2010: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 0.99758,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2011: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 0.99359,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2012: {
        "Riverine Flooding_factor": 1.0,
        "Coastal Inundation_factor": 0.9862,
        "Extreme Heat_factor": 1.0,
        "Forest Fire_factor": 1.0,
        "Extreme Wind_factor": 1.0,
        "Soil Subsidence_factor": 1.0,
        "Freeze-Thaw_factor": 1.0,
        "Surface Water Flooding_factor": 1.0,
        "Precipitation_factor": 1.0,
        "Drought_factor": 1.0,
        "Cyclone Wind_factor": 1.0
    },
    2013: {
        "Riverine Flooding_factor": 0.99764,
        "Coastal Inundation_factor": 0.97606,
        "Extreme Heat_factor": 0.99764,
        "Forest Fire_factor": 0.99764,
        "Extreme Wind_factor": 0.99764,
        "Soil Subsidence_factor": 0.99764,
        "Freeze-Thaw_factor": 0.99764,
        "Surface Water Flooding_factor": 0.99764,
        "Precipitation_factor": 0.99764,
        "Drought_factor": 0.99764,
        "Cyclone Wind_factor": 0.99764
    },
    2014: {
        "Riverine Flooding_factor": 0.9928,
        "Coastal Inundation_factor": 0.96381,
        "Extreme Heat_factor": 0.9928,
        "Forest Fire_factor": 0.9928,
        "Extreme Wind_factor": 0.9928,
        "Soil Subsidence_factor": 0.9928,
        "Freeze-Thaw_factor": 0.9928,
        "Surface Water Flooding_factor": 0.9928,
        "Precipitation_factor": 0.9928,
        "Drought_factor": 0.9928,
        "Cyclone Wind_factor": 0.9928
    },
    2015: {
        "Riverine Flooding_factor": 0.98651,
        "Coastal Inundation_factor": 0.94818,
        "Extreme Heat_factor": 0.98651,
        "Forest Fire_factor": 0.98651,
        "Extreme Wind_factor": 0.98651,
        "Soil Subsidence_factor": 0.98651,
        "Freeze-Thaw_factor": 0.98651,
        "Surface Water Flooding_factor": 0.98651,
        "Precipitation_factor": 0.98651,
        "Drought_factor": 0.98651,
        "Cyclone Wind_factor": 0.98651
    },
    2016: {
        "Riverine Flooding_factor": 0.97931,
        "Coastal Inundation_factor": 0.92839,
        "Extreme Heat_factor": 0.97931,
        "Forest Fire_factor": 0.97931,
        "Extreme Wind_factor": 0.97931,
        "Soil Subsidence_factor": 0.97931,
        "Freeze-Thaw_factor": 0.97931,
        "Surface Water Flooding_factor": 0.97931,
        "Precipitation_factor": 0.97931,
        "Drought_factor": 0.97931,
        "Cyclone Wind_factor": 0.97931
    },
    2017: {
        "Riverine Flooding_factor": 0.97069,
        "Coastal Inundation_factor": 0.90487,
        "Extreme Heat_factor": 0.97069,
        "Forest Fire_factor": 0.97069,
        "Extreme Wind_factor": 0.97069,
        "Soil Subsidence_factor": 0.97069,
        "Freeze-Thaw_factor": 0.97069,
        "Surface Water Flooding_factor": 0.97069,
        "Precipitation_factor": 0.97069,
        "Drought_factor": 0.97069,
        "Cyclone Wind_factor": 0.97069
    },
    2018: {
        "Riverine Flooding_factor": 0.96034,
        "Coastal Inundation_factor": 0.87703,
        "Extreme Heat_factor": 0.96034,
        "Forest Fire_factor": 0.96034,
        "Extreme Wind_factor": 0.96034,
        "Soil Subsidence_factor": 0.96034,
        "Freeze-Thaw_factor": 0.96034,
        "Surface Water Flooding_factor": 0.96034,
        "Precipitation_factor": 0.96034,
        "Drought_factor": 0.96034,
        "Cyclone Wind_factor": 0.96034
    },
    2019: {
        "Riverine Flooding_factor": 0.94854,
        "Coastal Inundation_factor": 0.84525,
        "Extreme Heat_factor": 0.94854,
        "Forest Fire_factor": 0.94854,
        "Extreme Wind_factor": 0.94854,
        "Soil Subsidence_factor": 0.94854,
        "Freeze-Thaw_factor": 0.94854,
        "Surface Water Flooding_factor": 0.94854,
        "Precipitation_factor": 0.94854,
        "Drought_factor": 0.94854,
        "Cyclone Wind_factor": 0.94854
    },
    2020: {
        "Riverine Flooding_factor": 0.93524,
        "Coastal Inundation_factor": 0.81389,
        "Extreme Heat_factor": 0.93524,
        "Forest Fire_factor": 0.93524,
        "Extreme Wind_factor": 0.93524,
        "Soil Subsidence_factor": 0.93524,
        "Freeze-Thaw_factor": 0.93524,
        "Surface Water Flooding_factor": 0.93524,
        "Precipitation_factor": 0.93524,
        "Drought_factor": 0.93524,
        "Cyclone Wind_factor": 0.93524
    },
    2021: {
        "Riverine Flooding_factor": 0.92043,
        "Coastal Inundation_factor": 0.78159,
        "Extreme Heat_factor": 0.92043,
        "Forest Fire_factor": 0.92043,
        "Extreme Wind_factor": 0.92043,
        "Soil Subsidence_factor": 0.92043,
        "Freeze-Thaw_factor": 0.92043,
        "Surface Water Flooding_factor": 0.92043,
        "Precipitation_factor": 0.92043,
        "Drought_factor": 0.92043,
        "Cyclone Wind_factor": 0.92043
    },
    2022: {
        "Riverine Flooding_factor": 0.90338,
        "Coastal Inundation_factor": 0.75236,
        "Extreme Heat_factor": 0.90338,
        "Forest Fire_factor": 0.90338,
        "Extreme Wind_factor": 0.90338,
        "Soil Subsidence_factor": 0.90338,
        "Freeze-Thaw_factor": 0.90338,
        "Surface Water Flooding_factor": 0.90338,
        "Precipitation_factor": 0.90338,
        "Drought_factor": 0.90338,
        "Cyclone Wind_factor": 0.90338
    },
    2023: {
        "Riverine Flooding_factor": 0.88896,
        "Coastal Inundation_factor": 0.7245,
        "Extreme Heat_factor": 0.88896,
        "Forest Fire_factor": 0.88896,
        "Extreme Wind_factor": 0.88896,
        "Soil Subsidence_factor": 0.88896,
        "Freeze-Thaw_factor": 0.88896,
        "Surface Water Flooding_factor": 0.88896,
        "Precipitation_factor": 0.88896,
        "Drought_factor": 0.88896,
        "Cyclone Wind_factor": 0.88896
    },
    2024: {
        "Riverine Flooding_factor": 0.87512,
        "Coastal Inundation_factor": 0.69637,
        "Extreme Heat_factor": 0.87512,
        "Forest Fire_factor": 0.87512,
        "Extreme Wind_factor": 0.87512,
        "Soil Subsidence_factor": 0.87512,
        "Freeze-Thaw_factor": 0.87512,
        "Surface Water Flooding_factor": 0.87512,
        "Precipitation_factor": 0.87512,
        "Drought_factor": 0.87512,
        "Cyclone Wind_factor": 0.87512
    },
    2025: {
        "Riverine Flooding_factor": 0.86039,
        "Coastal Inundation_factor": 0.67079,
        "Extreme Heat_factor": 0.86039,
        "Forest Fire_factor": 0.86039,
        "Extreme Wind_factor": 0.86039,
        "Soil Subsidence_factor": 0.86039,
        "Freeze-Thaw_factor": 0.86039,
        "Surface Water Flooding_factor": 0.86039,
        "Precipitation_factor": 0.86039,
        "Drought_factor": 0.86039,
        "Cyclone Wind_factor": 0.86039
    },
    2026: {
        "Riverine Flooding_factor": 0.84363,
        "Coastal Inundation_factor": 0.64906,
        "Extreme Heat_factor": 0.84363,
        "Forest Fire_factor": 0.84363,
        "Extreme Wind_factor": 0.84363,
        "Soil Subsidence_factor": 0.84363,
        "Freeze-Thaw_factor": 0.84363,
        "Surface Water Flooding_factor": 0.84363,
        "Precipitation_factor": 0.84363,
        "Drought_factor": 0.84363,
        "Cyclone Wind_factor": 0.84363
    },
    2027: {
        "Riverine Flooding_factor": 0.82683,
        "Coastal Inundation_factor": 0.63058,
        "Extreme Heat_factor": 0.82683,
        "Forest Fire_factor": 0.82683,
        "Extreme Wind_factor": 0.82683,
        "Soil Subsidence_factor": 0.82683,
        "Freeze-Thaw_factor": 0.82683,
        "Surface Water Flooding_factor": 0.82683,
        "Precipitation_factor": 0.82683,
        "Drought_factor": 0.82683,
        "Cyclone Wind_factor": 0.82683
    },
    2028: {
        "Riverine Flooding_factor": 0.80998,
        "Coastal Inundation_factor": 0.61682,
        "Extreme Heat_factor": 0.80998,
        "Forest Fire_factor": 0.80998,
        "Extreme Wind_factor": 0.80998,
        "Soil Subsidence_factor": 0.80998,
        "Freeze-Thaw_factor": 0.80998,
        "Surface Water Flooding_factor": 0.80998,
        "Precipitation_factor": 0.80998,
        "Drought_factor": 0.80998,
        "Cyclone Wind_factor": 0.80998
    },
    2029: {
        "Riverine Flooding_factor": 0.79238,
        "Coastal Inundation_factor": 0.60628,
        "Extreme Heat_factor": 0.79238,
        "Forest Fire_factor": 0.79238,
        "Extreme Wind_factor": 0.79238,
        "Soil Subsidence_factor": 0.79238,
        "Freeze-Thaw_factor": 0.79238,
        "Surface Water Flooding_factor": 0.79238,
        "Precipitation_factor": 0.79238,
        "Drought_factor": 0.79238,
        "Cyclone Wind_factor": 0.79238
    },
    2030: {
        "Riverine Flooding_factor": 0.77434,
        "Coastal Inundation_factor": 0.59772,
        "Extreme Heat_factor": 0.77434,
        "Forest Fire_factor": 0.77434,
        "Extreme Wind_factor": 0.77434,
        "Soil Subsidence_factor": 0.77434,
        "Freeze-Thaw_factor": 0.77434,
        "Surface Water Flooding_factor": 0.77434,
        "Precipitation_factor": 0.77434,
        "Drought_factor": 0.77434,
        "Cyclone Wind_factor": 0.77434
    },
    2031: {
        "Riverine Flooding_factor": 0.75592,
        "Coastal Inundation_factor": 0.59113,
        "Extreme Heat_factor": 0.75592,
        "Forest Fire_factor": 0.75592,
        "Extreme Wind_factor": 0.75592,
        "Soil Subsidence_factor": 0.75592,
        "Freeze-Thaw_factor": 0.75592,
        "Surface Water Flooding_factor": 0.75592,
        "Precipitation_factor": 0.75592,
        "Drought_factor": 0.75592,
        "Cyclone Wind_factor": 0.75592
    },
    2032: {
        "Riverine Flooding_factor": 0.73928,
        "Coastal Inundation_factor": 0.5842,
        "Extreme Heat_factor": 0.73928,
        "Forest Fire_factor": 0.73928,
        "Extreme Wind_factor": 0.73928,
        "Soil Subsidence_factor": 0.73928,
        "Freeze-Thaw_factor": 0.73928,
        "Surface Water Flooding_factor": 0.73928,
        "Precipitation_factor": 0.73928,
        "Drought_factor": 0.73928,
        "Cyclone Wind_factor": 0.73928
    },
    2033: {
        "Riverine Flooding_factor": 0.72067,
        "Coastal Inundation_factor": 0.57825,
        "Extreme Heat_factor": 0.72067,
        "Forest Fire_factor": 0.72067,
        "Extreme Wind_factor": 0.72067,
        "Soil Subsidence_factor": 0.72067,
        "Freeze-Thaw_factor": 0.72067,
        "Surface Water Flooding_factor": 0.72067,
        "Precipitation_factor": 0.72067,
        "Drought_factor": 0.72067,
        "Cyclone Wind_factor": 0.72067
    },
    2034: {
        "Riverine Flooding_factor": 0.70497,
        "Coastal Inundation_factor": 0.57446,
        "Extreme Heat_factor": 0.70497,
        "Forest Fire_factor": 0.70497,
        "Extreme Wind_factor": 0.70497,
        "Soil Subsidence_factor": 0.70497,
        "Freeze-Thaw_factor": 0.70497,
        "Surface Water Flooding_factor": 0.70497,
        "Precipitation_factor": 0.70497,
        "Drought_factor": 0.70497,
        "Cyclone Wind_factor": 0.70497
    },
    2035: {
        "Riverine Flooding_factor": 0.69123,
        "Coastal Inundation_factor": 0.57118,
        "Extreme Heat_factor": 0.69123,
        "Forest Fire_factor": 0.69123,
        "Extreme Wind_factor": 0.69123,
        "Soil Subsidence_factor": 0.69123,
        "Freeze-Thaw_factor": 0.69123,
        "Surface Water Flooding_factor": 0.69123,
        "Precipitation_factor": 0.69123,
        "Drought_factor": 0.69123,
        "Cyclone Wind_factor": 0.69123
    },
    2036: {
        "Riverine Flooding_factor": 0.67998,
        "Coastal Inundation_factor": 0.56807,
        "Extreme Heat_factor": 0.67998,
        "Forest Fire_factor": 0.67998,
        "Extreme Wind_factor": 0.67998,
        "Soil Subsidence_factor": 0.67998,
        "Freeze-Thaw_factor": 0.67998,
        "Surface Water Flooding_factor": 0.67998,
        "Precipitation_factor": 0.67998,
        "Drought_factor": 0.67998,
        "Cyclone Wind_factor": 0.67998
    },
    2037: {
        "Riverine Flooding_factor": 0.66903,
        "Coastal Inundation_factor": 0.56522,
        "Extreme Heat_factor": 0.66903,
        "Forest Fire_factor": 0.66903,
        "Extreme Wind_factor": 0.66903,
        "Soil Subsidence_factor": 0.66903,
        "Freeze-Thaw_factor": 0.66903,
        "Surface Water Flooding_factor": 0.66903,
        "Precipitation_factor": 0.66903,
        "Drought_factor": 0.66903,
        "Cyclone Wind_factor": 0.66903
    },
    2038: {
        "Riverine Flooding_factor": 0.65946,
        "Coastal Inundation_factor": 0.56135,
        "Extreme Heat_factor": 0.65946,
        "Forest Fire_factor": 0.65946,
        "Extreme Wind_factor": 0.65946,
        "Soil Subsidence_factor": 0.65946,
        "Freeze-Thaw_factor": 0.65946,
        "Surface Water Flooding_factor": 0.65946,
        "Precipitation_factor": 0.65946,
        "Drought_factor": 0.65946,
        "Cyclone Wind_factor": 0.65946
    },
    2039: {
        "Riverine Flooding_factor": 0.65179,
        "Coastal Inundation_factor": 0.55821,
        "Extreme Heat_factor": 0.65179,
        "Forest Fire_factor": 0.65179,
        "Extreme Wind_factor": 0.65179,
        "Soil Subsidence_factor": 0.65179,
        "Freeze-Thaw_factor": 0.65179,
        "Surface Water Flooding_factor": 0.65179,
        "Precipitation_factor": 0.65179,
        "Drought_factor": 0.65179,
        "Cyclone Wind_factor": 0.65179
    },
    2040: {
        "Riverine Flooding_factor": 0.64604,
        "Coastal Inundation_factor": 0.55503,
        "Extreme Heat_factor": 0.64604,
        "Forest Fire_factor": 0.64604,
        "Extreme Wind_factor": 0.64604,
        "Soil Subsidence_factor": 0.64604,
        "Freeze-Thaw_factor": 0.64604,
        "Surface Water Flooding_factor": 0.64604,
        "Precipitation_factor": 0.64604,
        "Drought_factor": 0.64604,
        "Cyclone Wind_factor": 0.64604
    },
    2041: {
        "Riverine Flooding_factor": 0.64213,
        "Coastal Inundation_factor": 0.55244,
        "Extreme Heat_factor": 0.64213,
        "Forest Fire_factor": 0.64213,
        "Extreme Wind_factor": 0.64213,
        "Soil Subsidence_factor": 0.64213,
        "Freeze-Thaw_factor": 0.64213,
        "Surface Water Flooding_factor": 0.64213,
        "Precipitation_factor": 0.64213,
        "Drought_factor": 0.64213,
        "Cyclone Wind_factor": 0.64213
    },
    2042: {
        "Riverine Flooding_factor": 0.63761,
        "Coastal Inundation_factor": 0.55023,
        "Extreme Heat_factor": 0.63761,
        "Forest Fire_factor": 0.63761,
        "Extreme Wind_factor": 0.63761,
        "Soil Subsidence_factor": 0.63761,
        "Freeze-Thaw_factor": 0.63761,
        "Surface Water Flooding_factor": 0.63761,
        "Precipitation_factor": 0.63761,
        "Drought_factor": 0.63761,
        "Cyclone Wind_factor": 0.63761
    },
    2043: {
        "Riverine Flooding_factor": 0.63462,
        "Coastal Inundation_factor": 0.54824,
        "Extreme Heat_factor": 0.63462,
        "Forest Fire_factor": 0.63462,
        "Extreme Wind_factor": 0.63462,
        "Soil Subsidence_factor": 0.63462,
        "Freeze-Thaw_factor": 0.63462,
        "Surface Water Flooding_factor": 0.63462,
        "Precipitation_factor": 0.63462,
        "Drought_factor": 0.63462,
        "Cyclone Wind_factor": 0.63462
    },
    2044: {
        "Riverine Flooding_factor": 0.63,
        "Coastal Inundation_factor": 0.54634,
        "Extreme Heat_factor": 0.63,
        "Forest Fire_factor": 0.63,
        "Extreme Wind_factor": 0.63,
        "Soil Subsidence_factor": 0.63,
        "Freeze-Thaw_factor": 0.63,
        "Surface Water Flooding_factor": 0.63,
        "Precipitation_factor": 0.63,
        "Drought_factor": 0.63,
        "Cyclone Wind_factor": 0.63
    },
    2045: {
        "Riverine Flooding_factor": 0.62566,
        "Coastal Inundation_factor": 0.5446,
        "Extreme Heat_factor": 0.62566,
        "Forest Fire_factor": 0.62566,
        "Extreme Wind_factor": 0.62566,
        "Soil Subsidence_factor": 0.62566,
        "Freeze-Thaw_factor": 0.62566,
        "Surface Water Flooding_factor": 0.62566,
        "Precipitation_factor": 0.62566,
        "Drought_factor": 0.62566,
        "Cyclone Wind_factor": 0.62566
    },
    2046: {
        "Riverine Flooding_factor": 0.62176,
        "Coastal Inundation_factor": 0.5429,
        "Extreme Heat_factor": 0.62176,
        "Forest Fire_factor": 0.62176,
        "Extreme Wind_factor": 0.62176,
        "Soil Subsidence_factor": 0.62176,
        "Freeze-Thaw_factor": 0.62176,
        "Surface Water Flooding_factor": 0.62176,
        "Precipitation_factor": 0.62176,
        "Drought_factor": 0.62176,
        "Cyclone Wind_factor": 0.62176
    },
    2047: {
        "Riverine Flooding_factor": 0.61834,
        "Coastal Inundation_factor": 0.54108,
        "Extreme Heat_factor": 0.61834,
        "Forest Fire_factor": 0.61834,
        "Extreme Wind_factor": 0.61834,
        "Soil Subsidence_factor": 0.61834,
        "Freeze-Thaw_factor": 0.61834,
        "Surface Water Flooding_factor": 0.61834,
        "Precipitation_factor": 0.61834,
        "Drought_factor": 0.61834,
        "Cyclone Wind_factor": 0.61834
    },
    2048: {
        "Riverine Flooding_factor": 0.61444,
        "Coastal Inundation_factor": 0.5398,
        "Extreme Heat_factor": 0.61444,
        "Forest Fire_factor": 0.61444,
        "Extreme Wind_factor": 0.61444,
        "Soil Subsidence_factor": 0.61444,
        "Freeze-Thaw_factor": 0.61444,
        "Surface Water Flooding_factor": 0.61444,
        "Precipitation_factor": 0.61444,
        "Drought_factor": 0.61444,
        "Cyclone Wind_factor": 0.61444
    },
    2049: {
        "Riverine Flooding_factor": 0.61002,
        "Coastal Inundation_factor": 0.5385,
        "Extreme Heat_factor": 0.61002,
        "Forest Fire_factor": 0.61002,
        "Extreme Wind_factor": 0.61002,
        "Soil Subsidence_factor": 0.61002,
        "Freeze-Thaw_factor": 0.61002,
        "Surface Water Flooding_factor": 0.61002,
        "Precipitation_factor": 0.61002,
        "Drought_factor": 0.61002,
        "Cyclone Wind_factor": 0.61002
    },
    2050: {
        "Riverine Flooding_factor": 0.60424,
        "Coastal Inundation_factor": 0.53699,
        "Extreme Heat_factor": 0.60424,
        "Forest Fire_factor": 0.60424,
        "Extreme Wind_factor": 0.60424,
        "Soil Subsidence_factor": 0.60424,
        "Freeze-Thaw_factor": 0.60424,
        "Surface Water Flooding_factor": 0.60424,
        "Precipitation_factor": 0.60424,
        "Drought_factor": 0.60424,
        "Cyclone Wind_factor": 0.60424
    },
    2051: {
        "Riverine Flooding_factor": 0.59714,
        "Coastal Inundation_factor": 0.53523,
        "Extreme Heat_factor": 0.59714,
        "Forest Fire_factor": 0.59714,
        "Extreme Wind_factor": 0.59714,
        "Soil Subsidence_factor": 0.59714,
        "Freeze-Thaw_factor": 0.59714,
        "Surface Water Flooding_factor": 0.59714,
        "Precipitation_factor": 0.59714,
        "Drought_factor": 0.59714,
        "Cyclone Wind_factor": 0.59714
    },
    2052: {
        "Riverine Flooding_factor": 0.58986,
        "Coastal Inundation_factor": 0.5336,
        "Extreme Heat_factor": 0.58986,
        "Forest Fire_factor": 0.58986,
        "Extreme Wind_factor": 0.58986,
        "Soil Subsidence_factor": 0.58986,
        "Freeze-Thaw_factor": 0.58986,
        "Surface Water Flooding_factor": 0.58986,
        "Precipitation_factor": 0.58986,
        "Drought_factor": 0.58986,
        "Cyclone Wind_factor": 0.58986
    },
    2053: {
        "Riverine Flooding_factor": 0.58124,
        "Coastal Inundation_factor": 0.53206,
        "Extreme Heat_factor": 0.58124,
        "Forest Fire_factor": 0.58124,
        "Extreme Wind_factor": 0.58124,
        "Soil Subsidence_factor": 0.58124,
        "Freeze-Thaw_factor": 0.58124,
        "Surface Water Flooding_factor": 0.58124,
        "Precipitation_factor": 0.58124,
        "Drought_factor": 0.58124,
        "Cyclone Wind_factor": 0.58124
    },
    2054: {
        "Riverine Flooding_factor": 0.57181,
        "Coastal Inundation_factor": 0.53055,
        "Extreme Heat_factor": 0.57181,
        "Forest Fire_factor": 0.57181,
        "Extreme Wind_factor": 0.57181,
        "Soil Subsidence_factor": 0.57181,
        "Freeze-Thaw_factor": 0.57181,
        "Surface Water Flooding_factor": 0.57181,
        "Precipitation_factor": 0.57181,
        "Drought_factor": 0.57181,
        "Cyclone Wind_factor": 0.57181
    },
    2055: {
        "Riverine Flooding_factor": 0.56112,
        "Coastal Inundation_factor": 0.52895,
        "Extreme Heat_factor": 0.56112,
        "Forest Fire_factor": 0.56112,
        "Extreme Wind_factor": 0.56112,
        "Soil Subsidence_factor": 0.56112,
        "Freeze-Thaw_factor": 0.56112,
        "Surface Water Flooding_factor": 0.56112,
        "Precipitation_factor": 0.56112,
        "Drought_factor": 0.56112,
        "Cyclone Wind_factor": 0.56112
    },
    2056: {
        "Riverine Flooding_factor": 0.54922,
        "Coastal Inundation_factor": 0.5272,
        "Extreme Heat_factor": 0.54922,
        "Forest Fire_factor": 0.54922,
        "Extreme Wind_factor": 0.54922,
        "Soil Subsidence_factor": 0.54922,
        "Freeze-Thaw_factor": 0.54922,
        "Surface Water Flooding_factor": 0.54922,
        "Precipitation_factor": 0.54922,
        "Drought_factor": 0.54922,
        "Cyclone Wind_factor": 0.54922
    },
    2057: {
        "Riverine Flooding_factor": 0.53731,
        "Coastal Inundation_factor": 0.52548,
        "Extreme Heat_factor": 0.53731,
        "Forest Fire_factor": 0.53731,
        "Extreme Wind_factor": 0.53731,
        "Soil Subsidence_factor": 0.53731,
        "Freeze-Thaw_factor": 0.53731,
        "Surface Water Flooding_factor": 0.53731,
        "Precipitation_factor": 0.53731,
        "Drought_factor": 0.53731,
        "Cyclone Wind_factor": 0.53731
    },
    2058: {
        "Riverine Flooding_factor": 0.52528,
        "Coastal Inundation_factor": 0.52347,
        "Extreme Heat_factor": 0.52528,
        "Forest Fire_factor": 0.52528,
        "Extreme Wind_factor": 0.52528,
        "Soil Subsidence_factor": 0.52528,
        "Freeze-Thaw_factor": 0.52528,
        "Surface Water Flooding_factor": 0.52528,
        "Precipitation_factor": 0.52528,
        "Drought_factor": 0.52528,
        "Cyclone Wind_factor": 0.52528
    },
    2059: {
        "Riverine Flooding_factor": 0.5128,
        "Coastal Inundation_factor": 0.52115,
        "Extreme Heat_factor": 0.5128,
        "Forest Fire_factor": 0.5128,
        "Extreme Wind_factor": 0.5128,
        "Soil Subsidence_factor": 0.5128,
        "Freeze-Thaw_factor": 0.5128,
        "Surface Water Flooding_factor": 0.5128,
        "Precipitation_factor": 0.5128,
        "Drought_factor": 0.5128,
        "Cyclone Wind_factor": 0.5128
    },
    2060: {
        "Riverine Flooding_factor": 0.50058,
        "Coastal Inundation_factor": 0.5188,
        "Extreme Heat_factor": 0.50058,
        "Forest Fire_factor": 0.50058,
        "Extreme Wind_factor": 0.50058,
        "Soil Subsidence_factor": 0.50058,
        "Freeze-Thaw_factor": 0.50058,
        "Surface Water Flooding_factor": 0.50058,
        "Precipitation_factor": 0.50058,
        "Drought_factor": 0.50058,
        "Cyclone Wind_factor": 0.50058
    },
    2061: {
        "Riverine Flooding_factor": 0.48844,
        "Coastal Inundation_factor": 0.5164,
        "Extreme Heat_factor": 0.48844,
        "Forest Fire_factor": 0.48844,
        "Extreme Wind_factor": 0.48844,
        "Soil Subsidence_factor": 0.48844,
        "Freeze-Thaw_factor": 0.48844,
        "Surface Water Flooding_factor": 0.48844,
        "Precipitation_factor": 0.48844,
        "Drought_factor": 0.48844,
        "Cyclone Wind_factor": 0.48844
    },
    2062: {
        "Riverine Flooding_factor": 0.47623,
        "Coastal Inundation_factor": 0.51393,
        "Extreme Heat_factor": 0.47623,
        "Forest Fire_factor": 0.47623,
        "Extreme Wind_factor": 0.47623,
        "Soil Subsidence_factor": 0.47623,
        "Freeze-Thaw_factor": 0.47623,
        "Surface Water Flooding_factor": 0.47623,
        "Precipitation_factor": 0.47623,
        "Drought_factor": 0.47623,
        "Cyclone Wind_factor": 0.47623
    },
    2063: {
        "Riverine Flooding_factor": 0.46464,
        "Coastal Inundation_factor": 0.51136,
        "Extreme Heat_factor": 0.46464,
        "Forest Fire_factor": 0.46464,
        "Extreme Wind_factor": 0.46464,
        "Soil Subsidence_factor": 0.46464,
        "Freeze-Thaw_factor": 0.46464,
        "Surface Water Flooding_factor": 0.46464,
        "Precipitation_factor": 0.46464,
        "Drought_factor": 0.46464,
        "Cyclone Wind_factor": 0.46464
    },
    2064: {
        "Riverine Flooding_factor": 0.45346,
        "Coastal Inundation_factor": 0.50858,
        "Extreme Heat_factor": 0.45346,
        "Forest Fire_factor": 0.45346,
        "Extreme Wind_factor": 0.45346,
        "Soil Subsidence_factor": 0.45346,
        "Freeze-Thaw_factor": 0.45346,
        "Surface Water Flooding_factor": 0.45346,
        "Precipitation_factor": 0.45346,
        "Drought_factor": 0.45346,
        "Cyclone Wind_factor": 0.45346
    },
    2065: {
        "Riverine Flooding_factor": 0.44266,
        "Coastal Inundation_factor": 0.50579,
        "Extreme Heat_factor": 0.44266,
        "Forest Fire_factor": 0.44266,
        "Extreme Wind_factor": 0.44266,
        "Soil Subsidence_factor": 0.44266,
        "Freeze-Thaw_factor": 0.44266,
        "Surface Water Flooding_factor": 0.44266,
        "Precipitation_factor": 0.44266,
        "Drought_factor": 0.44266,
        "Cyclone Wind_factor": 0.44266
    },
    2066: {
        "Riverine Flooding_factor": 0.43215,
        "Coastal Inundation_factor": 0.50298,
        "Extreme Heat_factor": 0.43215,
        "Forest Fire_factor": 0.43215,
        "Extreme Wind_factor": 0.43215,
        "Soil Subsidence_factor": 0.43215,
        "Freeze-Thaw_factor": 0.43215,
        "Surface Water Flooding_factor": 0.43215,
        "Precipitation_factor": 0.43215,
        "Drought_factor": 0.43215,
        "Cyclone Wind_factor": 0.43215
    },
    2067: {
        "Riverine Flooding_factor": 0.42162,
        "Coastal Inundation_factor": 0.50043,
        "Extreme Heat_factor": 0.42162,
        "Forest Fire_factor": 0.42162,
        "Extreme Wind_factor": 0.42162,
        "Soil Subsidence_factor": 0.42162,
        "Freeze-Thaw_factor": 0.42162,
        "Surface Water Flooding_factor": 0.42162,
        "Precipitation_factor": 0.42162,
        "Drought_factor": 0.42162,
        "Cyclone Wind_factor": 0.42162
    },
    2068: {
        "Riverine Flooding_factor": 0.4111,
        "Coastal Inundation_factor": 0.49816,
        "Extreme Heat_factor": 0.4111,
        "Forest Fire_factor": 0.4111,
        "Extreme Wind_factor": 0.4111,
        "Soil Subsidence_factor": 0.4111,
        "Freeze-Thaw_factor": 0.4111,
        "Surface Water Flooding_factor": 0.4111,
        "Precipitation_factor": 0.4111,
        "Drought_factor": 0.4111,
        "Cyclone Wind_factor": 0.4111
    },
    2069: {
        "Riverine Flooding_factor": 0.40106,
        "Coastal Inundation_factor": 0.49613,
        "Extreme Heat_factor": 0.40106,
        "Forest Fire_factor": 0.40106,
        "Extreme Wind_factor": 0.40106,
        "Soil Subsidence_factor": 0.40106,
        "Freeze-Thaw_factor": 0.40106,
        "Surface Water Flooding_factor": 0.40106,
        "Precipitation_factor": 0.40106,
        "Drought_factor": 0.40106,
        "Cyclone Wind_factor": 0.40106
    },
    2070: {
        "Riverine Flooding_factor": 0.39117,
        "Coastal Inundation_factor": 0.49422,
        "Extreme Heat_factor": 0.39117,
        "Forest Fire_factor": 0.39117,
        "Extreme Wind_factor": 0.39117,
        "Soil Subsidence_factor": 0.39117,
        "Freeze-Thaw_factor": 0.39117,
        "Surface Water Flooding_factor": 0.39117,
        "Precipitation_factor": 0.39117,
        "Drought_factor": 0.39117,
        "Cyclone Wind_factor": 0.39117
    },
    2071: {
        "Riverine Flooding_factor": 0.38183,
        "Coastal Inundation_factor": 0.49253,
        "Extreme Heat_factor": 0.38183,
        "Forest Fire_factor": 0.38183,
        "Extreme Wind_factor": 0.38183,
        "Soil Subsidence_factor": 0.38183,
        "Freeze-Thaw_factor": 0.38183,
        "Surface Water Flooding_factor": 0.38183,
        "Precipitation_factor": 0.38183,
        "Drought_factor": 0.38183,
        "Cyclone Wind_factor": 0.38183
    },
    2072: {
        "Riverine Flooding_factor": 0.37327,
        "Coastal Inundation_factor": 0.49102,
        "Extreme Heat_factor": 0.37327,
        "Forest Fire_factor": 0.37327,
        "Extreme Wind_factor": 0.37327,
        "Soil Subsidence_factor": 0.37327,
        "Freeze-Thaw_factor": 0.37327,
        "Surface Water Flooding_factor": 0.37327,
        "Precipitation_factor": 0.37327,
        "Drought_factor": 0.37327,
        "Cyclone Wind_factor": 0.37327
    },
    2073: {
        "Riverine Flooding_factor": 0.36509,
        "Coastal Inundation_factor": 0.4897,
        "Extreme Heat_factor": 0.36509,
        "Forest Fire_factor": 0.36509,
        "Extreme Wind_factor": 0.36509,
        "Soil Subsidence_factor": 0.36509,
        "Freeze-Thaw_factor": 0.36509,
        "Surface Water Flooding_factor": 0.36509,
        "Precipitation_factor": 0.36509,
        "Drought_factor": 0.36509,
        "Cyclone Wind_factor": 0.36509
    },
    2074: {
        "Riverine Flooding_factor": 0.35744,
        "Coastal Inundation_factor": 0.48878,
        "Extreme Heat_factor": 0.35744,
        "Forest Fire_factor": 0.35744,
        "Extreme Wind_factor": 0.35744,
        "Soil Subsidence_factor": 0.35744,
        "Freeze-Thaw_factor": 0.35744,
        "Surface Water Flooding_factor": 0.35744,
        "Precipitation_factor": 0.35744,
        "Drought_factor": 0.35744,
        "Cyclone Wind_factor": 0.35744
    },
    2075: {
        "Riverine Flooding_factor": 0.34991,
        "Coastal Inundation_factor": 0.48814,
        "Extreme Heat_factor": 0.34991,
        "Forest Fire_factor": 0.34991,
        "Extreme Wind_factor": 0.34991,
        "Soil Subsidence_factor": 0.34991,
        "Freeze-Thaw_factor": 0.34991,
        "Surface Water Flooding_factor": 0.34991,
        "Precipitation_factor": 0.34991,
        "Drought_factor": 0.34991,
        "Cyclone Wind_factor": 0.34991
    },
    2076: {
        "Riverine Flooding_factor": 0.34291,
        "Coastal Inundation_factor": 0.48779,
        "Extreme Heat_factor": 0.34291,
        "Forest Fire_factor": 0.34291,
        "Extreme Wind_factor": 0.34291,
        "Soil Subsidence_factor": 0.34291,
        "Freeze-Thaw_factor": 0.34291,
        "Surface Water Flooding_factor": 0.34291,
        "Precipitation_factor": 0.34291,
        "Drought_factor": 0.34291,
        "Cyclone Wind_factor": 0.34291
    },
    2077: {
        "Riverine Flooding_factor": 0.33571,
        "Coastal Inundation_factor": 0.48755,
        "Extreme Heat_factor": 0.33571,
        "Forest Fire_factor": 0.33571,
        "Extreme Wind_factor": 0.33571,
        "Soil Subsidence_factor": 0.33571,
        "Freeze-Thaw_factor": 0.33571,
        "Surface Water Flooding_factor": 0.33571,
        "Precipitation_factor": 0.33571,
        "Drought_factor": 0.33571,
        "Cyclone Wind_factor": 0.33571
    },
    2078: {
        "Riverine Flooding_factor": 0.32916,
        "Coastal Inundation_factor": 0.48726,
        "Extreme Heat_factor": 0.32916,
        "Forest Fire_factor": 0.32916,
        "Extreme Wind_factor": 0.32916,
        "Soil Subsidence_factor": 0.32916,
        "Freeze-Thaw_factor": 0.32916,
        "Surface Water Flooding_factor": 0.32916,
        "Precipitation_factor": 0.32916,
        "Drought_factor": 0.32916,
        "Cyclone Wind_factor": 0.32916
    },
    2079: {
        "Riverine Flooding_factor": 0.32312,
        "Coastal Inundation_factor": 0.48691,
        "Extreme Heat_factor": 0.32312,
        "Forest Fire_factor": 0.32312,
        "Extreme Wind_factor": 0.32312,
        "Soil Subsidence_factor": 0.32312,
        "Freeze-Thaw_factor": 0.32312,
        "Surface Water Flooding_factor": 0.32312,
        "Precipitation_factor": 0.32312,
        "Drought_factor": 0.32312,
        "Cyclone Wind_factor": 0.32312
    },
    2080: {
        "Riverine Flooding_factor": 0.31773,
        "Coastal Inundation_factor": 0.4867,
        "Extreme Heat_factor": 0.31773,
        "Forest Fire_factor": 0.31773,
        "Extreme Wind_factor": 0.31773,
        "Soil Subsidence_factor": 0.31773,
        "Freeze-Thaw_factor": 0.31773,
        "Surface Water Flooding_factor": 0.31773,
        "Precipitation_factor": 0.31773,
        "Drought_factor": 0.31773,
        "Cyclone Wind_factor": 0.31773
    },
    2081: {
        "Riverine Flooding_factor": 0.31303,
        "Coastal Inundation_factor": 0.48643,
        "Extreme Heat_factor": 0.31303,
        "Forest Fire_factor": 0.31303,
        "Extreme Wind_factor": 0.31303,
        "Soil Subsidence_factor": 0.31303,
        "Freeze-Thaw_factor": 0.31303,
        "Surface Water Flooding_factor": 0.31303,
        "Precipitation_factor": 0.31303,
        "Drought_factor": 0.31303,
        "Cyclone Wind_factor": 0.31303
    },
    2082: {
        "Riverine Flooding_factor": 0.3086,
        "Coastal Inundation_factor": 0.486,
        "Extreme Heat_factor": 0.3086,
        "Forest Fire_factor": 0.3086,
        "Extreme Wind_factor": 0.3086,
        "Soil Subsidence_factor": 0.3086,
        "Freeze-Thaw_factor": 0.3086,
        "Surface Water Flooding_factor": 0.3086,
        "Precipitation_factor": 0.3086,
        "Drought_factor": 0.3086,
        "Cyclone Wind_factor": 0.3086
    },
    2083: {
        "Riverine Flooding_factor": 0.30447,
        "Coastal Inundation_factor": 0.48543,
        "Extreme Heat_factor": 0.30447,
        "Forest Fire_factor": 0.30447,
        "Extreme Wind_factor": 0.30447,
        "Soil Subsidence_factor": 0.30447,
        "Freeze-Thaw_factor": 0.30447,
        "Surface Water Flooding_factor": 0.30447,
        "Precipitation_factor": 0.30447,
        "Drought_factor": 0.30447,
        "Cyclone Wind_factor": 0.30447
    },
    2084: {
        "Riverine Flooding_factor": 0.30073,
        "Coastal Inundation_factor": 0.48458,
        "Extreme Heat_factor": 0.30073,
        "Forest Fire_factor": 0.30073,
        "Extreme Wind_factor": 0.30073,
        "Soil Subsidence_factor": 0.30073,
        "Freeze-Thaw_factor": 0.30073,
        "Surface Water Flooding_factor": 0.30073,
        "Precipitation_factor": 0.30073,
        "Drought_factor": 0.30073,
        "Cyclone Wind_factor": 0.30073
    },
    2085: {
        "Riverine Flooding_factor": 0.29802,
        "Coastal Inundation_factor": 0.48364,
        "Extreme Heat_factor": 0.29802,
        "Forest Fire_factor": 0.29802,
        "Extreme Wind_factor": 0.29802,
        "Soil Subsidence_factor": 0.29802,
        "Freeze-Thaw_factor": 0.29802,
        "Surface Water Flooding_factor": 0.29802,
        "Precipitation_factor": 0.29802,
        "Drought_factor": 0.29802,
        "Cyclone Wind_factor": 0.29802
    },
    2086: {
        "Riverine Flooding_factor": 0.29539,
        "Coastal Inundation_factor": 0.48262,
        "Extreme Heat_factor": 0.29539,
        "Forest Fire_factor": 0.29539,
        "Extreme Wind_factor": 0.29539,
        "Soil Subsidence_factor": 0.29539,
        "Freeze-Thaw_factor": 0.29539,
        "Surface Water Flooding_factor": 0.29539,
        "Precipitation_factor": 0.29539,
        "Drought_factor": 0.29539,
        "Cyclone Wind_factor": 0.29539
    },
    2087: {
        "Riverine Flooding_factor": 0.29349,
        "Coastal Inundation_factor": 0.48152,
        "Extreme Heat_factor": 0.29349,
        "Forest Fire_factor": 0.29349,
        "Extreme Wind_factor": 0.29349,
        "Soil Subsidence_factor": 0.29349,
        "Freeze-Thaw_factor": 0.29349,
        "Surface Water Flooding_factor": 0.29349,
        "Precipitation_factor": 0.29349,
        "Drought_factor": 0.29349,
        "Cyclone Wind_factor": 0.29349
    },
    2088: {
        "Riverine Flooding_factor": 0.29156,
        "Coastal Inundation_factor": 0.48053,
        "Extreme Heat_factor": 0.29156,
        "Forest Fire_factor": 0.29156,
        "Extreme Wind_factor": 0.29156,
        "Soil Subsidence_factor": 0.29156,
        "Freeze-Thaw_factor": 0.29156,
        "Surface Water Flooding_factor": 0.29156,
        "Precipitation_factor": 0.29156,
        "Drought_factor": 0.29156,
        "Cyclone Wind_factor": 0.29156
    },
    2089: {
        "Riverine Flooding_factor": 0.28956,
        "Coastal Inundation_factor": 0.47966,
        "Extreme Heat_factor": 0.28956,
        "Forest Fire_factor": 0.28956,
        "Extreme Wind_factor": 0.28956,
        "Soil Subsidence_factor": 0.28956,
        "Freeze-Thaw_factor": 0.28956,
        "Surface Water Flooding_factor": 0.28956,
        "Precipitation_factor": 0.28956,
        "Drought_factor": 0.28956,
        "Cyclone Wind_factor": 0.28956
    },
    2090: {
        "Riverine Flooding_factor": 0.28737,
        "Coastal Inundation_factor": 0.47873,
        "Extreme Heat_factor": 0.28737,
        "Forest Fire_factor": 0.28737,
        "Extreme Wind_factor": 0.28737,
        "Soil Subsidence_factor": 0.28737,
        "Freeze-Thaw_factor": 0.28737,
        "Surface Water Flooding_factor": 0.28737,
        "Precipitation_factor": 0.28737,
        "Drought_factor": 0.28737,
        "Cyclone Wind_factor": 0.28737
    },
    2091: {
        "Riverine Flooding_factor": 0.28467,
        "Coastal Inundation_factor": 0.47791,
        "Extreme Heat_factor": 0.28467,
        "Forest Fire_factor": 0.28467,
        "Extreme Wind_factor": 0.28467,
        "Soil Subsidence_factor": 0.28467,
        "Freeze-Thaw_factor": 0.28467,
        "Surface Water Flooding_factor": 0.28467,
        "Precipitation_factor": 0.28467,
        "Drought_factor": 0.28467,
        "Cyclone Wind_factor": 0.28467
    },
    2092: {
        "Riverine Flooding_factor": 0.28164,
        "Coastal Inundation_factor": 0.47736,
        "Extreme Heat_factor": 0.28164,
        "Forest Fire_factor": 0.28164,
        "Extreme Wind_factor": 0.28164,
        "Soil Subsidence_factor": 0.28164,
        "Freeze-Thaw_factor": 0.28164,
        "Surface Water Flooding_factor": 0.28164,
        "Precipitation_factor": 0.28164,
        "Drought_factor": 0.28164,
        "Cyclone Wind_factor": 0.28164
    },
    2093: {
        "Riverine Flooding_factor": 0.27851,
        "Coastal Inundation_factor": 0.47702,
        "Extreme Heat_factor": 0.27851,
        "Forest Fire_factor": 0.27851,
        "Extreme Wind_factor": 0.27851,
        "Soil Subsidence_factor": 0.27851,
        "Freeze-Thaw_factor": 0.27851,
        "Surface Water Flooding_factor": 0.27851,
        "Precipitation_factor": 0.27851,
        "Drought_factor": 0.27851,
        "Cyclone Wind_factor": 0.27851
    },
    2094: {
        "Riverine Flooding_factor": 0.27512,
        "Coastal Inundation_factor": 0.47686,
        "Extreme Heat_factor": 0.27512,
        "Forest Fire_factor": 0.27512,
        "Extreme Wind_factor": 0.27512,
        "Soil Subsidence_factor": 0.27512,
        "Freeze-Thaw_factor": 0.27512,
        "Surface Water Flooding_factor": 0.27512,
        "Precipitation_factor": 0.27512,
        "Drought_factor": 0.27512,
        "Cyclone Wind_factor": 0.27512
    },
    2095: {
        "Riverine Flooding_factor": 0.27113,
        "Coastal Inundation_factor": 0.47676,
        "Extreme Heat_factor": 0.27113,
        "Forest Fire_factor": 0.27113,
        "Extreme Wind_factor": 0.27113,
        "Soil Subsidence_factor": 0.27113,
        "Freeze-Thaw_factor": 0.27113,
        "Surface Water Flooding_factor": 0.27113,
        "Precipitation_factor": 0.27113,
        "Drought_factor": 0.27113,
        "Cyclone Wind_factor": 0.27113
    },
    2096: {
        "Riverine Flooding_factor": 0.26699,
        "Coastal Inundation_factor": 0.4767,
        "Extreme Heat_factor": 0.26699,
        "Forest Fire_factor": 0.26699,
        "Extreme Wind_factor": 0.26699,
        "Soil Subsidence_factor": 0.26699,
        "Freeze-Thaw_factor": 0.26699,
        "Surface Water Flooding_factor": 0.26699,
        "Precipitation_factor": 0.26699,
        "Drought_factor": 0.26699,
        "Cyclone Wind_factor": 0.26699
    },
    2097: {
        "Riverine Flooding_factor": 0.26481,
        "Coastal Inundation_factor": 0.47666,
        "Extreme Heat_factor": 0.26481,
        "Forest Fire_factor": 0.26481,
        "Extreme Wind_factor": 0.26481,
        "Soil Subsidence_factor": 0.26481,
        "Freeze-Thaw_factor": 0.26481,
        "Surface Water Flooding_factor": 0.26481,
        "Precipitation_factor": 0.26481,
        "Drought_factor": 0.26481,
        "Cyclone Wind_factor": 0.26481
    },
    2098: {
        "Riverine Flooding_factor": 0.26253,
        "Coastal Inundation_factor": 0.47663,
        "Extreme Heat_factor": 0.26253,
        "Forest Fire_factor": 0.26253,
        "Extreme Wind_factor": 0.26253,
        "Soil Subsidence_factor": 0.26253,
        "Freeze-Thaw_factor": 0.26253,
        "Surface Water Flooding_factor": 0.26253,
        "Precipitation_factor": 0.26253,
        "Drought_factor": 0.26253,
        "Cyclone Wind_factor": 0.26253
    },
    2099: {
        "Riverine Flooding_factor": 0.26013,
        "Coastal Inundation_factor": 0.47663,
        "Extreme Heat_factor": 0.26013,
        "Forest Fire_factor": 0.26013,
        "Extreme Wind_factor": 0.26013,
        "Soil Subsidence_factor": 0.26013,
        "Freeze-Thaw_factor": 0.26013,
        "Surface Water Flooding_factor": 0.26013,
        "Precipitation_factor": 0.26013,
        "Drought_factor": 0.26013,
        "Cyclone Wind_factor": 0.26013
    },
    2100: {
        "Riverine Flooding_factor": 0.25771,
        "Coastal Inundation_factor": 0.47677,
        "Extreme Heat_factor": 0.25771,
        "Forest Fire_factor": 0.25771,
        "Extreme Wind_factor": 0.25771,
        "Soil Subsidence_factor": 0.25771,
        "Freeze-Thaw_factor": 0.25771,
        "Surface Water Flooding_factor": 0.25771,
        "Precipitation_factor": 0.25771,
        "Drought_factor": 0.25771,
        "Cyclone Wind_factor": 0.25771
    }
};
