import _ from "lodash"
import React from "react"
import autoBind from "react-autobind"
import { observer } from "mobx-react"
import { toJS } from "mobx"
import ReactGA from "react-ga"
import PropTypes from "prop-types"

import { doAnalysis, munge_analysis } from "../analyses"
import { getMaterials } from "../archetype"
import Loading from "../Loading.jsx"
import Adaptations from "./Adaptations.jsx"

import "./Adaptations.scss"

/**
 * Main Adaptation handling component.
 *
 * @param {object} analysis - analysis object (from Ana/Easy [FIXME: unfuck])
 *
 * @example
 *
 *    import AdaptationWrapper from '../../CR-Components/AssetCustomisation/AdaptationWrapper.jsx';
 *
 *    class ExampleClass extends React.Component {
 *      render() {
 *        const { analysis } = this.props;
 *
 *        return (
 *            <AdaptationWrapper
 *                analysis={analysis}
 *                materials={toJS(appState.materials)} //from Acrobase
 *                doAnalysis={doAnalysis // TODO: Does this need to be here? }
 *                handleSaveAdaptation={handleSaveAdaptation}
 *                handleDeleteAdaptation={handleDeleteAdaptation}
 *            />}
 *        )
 *      }
 *    }
 */
@observer
class AdaptationWrapper extends React.Component {
    static propTypes = {
        analysis: PropTypes.object,
        materials: PropTypes.object,
        handleSaveAdaptation: PropTypes.func,
        handleDeleteAdaptation: PropTypes.func,
        displayCharts: PropTypes.bool,
    }

    static defaultProps = {
        displayCharts: true,
        materials: getMaterials(),
        handleSaveAdaptation: (
            () => console.log("Adaptations can't be saved in this app.")
        ), // stub, over-ridden in Easy
        handleDeleteAdaptation: (
            () => console.log("Adaptation not saved, so skipping deletion.")
        ), // stub, over-ridden in Easy
    }

    constructor(props) {
        super(props)

        this.state = {
            analysis: this.props.analysis,
            loading: false,
            assetValue: {},
            receivedData: {},
            assetReplacementCost: 0,
            CAPEX: 0,
            adaptationYear: 2030,
            valueUsedToTriggerChildAgain: 0,
            materials: this.props.materials,
        }

        this.state.analysis = munge_analysis(this.state.analysis)
        this.state.analysisBaseline = _.cloneDeep(toJS(this.state.analysis))

        autoBind(this)
    }

    componentDidMount() {
        if (crConstants.production) {
            ReactGA.modalview("/analysis-result/adaptation")
        }
    }

    // TODO: Should this be merged with the function in Easy::AnalysisResult?
    async ADAPT_runChangedParameters({ parameters, elements, extraMaterials }) {
        if (crConstants.production) {
            ReactGA.modalview("/analysis-result/adaptation/run-changes")
        }

        this.setState({ loading: true })
        // TODO: Standardise the format of this function. Should work in Globe and Easy.
        // It should take a set of parameters in, and convert to an Ana analysis input,
        // And return an Analysis object with a standard format (e.g. Ana format)

        let newParameters = {
            ...this.state.analysis.inputs.asset.properties,
            ...parameters
        }

        // If heatThreshold and windThreshold are unused in parameters, remove them
        // This avoids using 'thresholds' and supplying heatThreshold at the same time
        if (!parameters.heatThreshold && newParameters.heatThreshold) {
            delete newParameters.heatThreshold
        }
        if (!parameters.windThreshold && newParameters.windThreshold) {
            delete newParameters.windThreshold
        }

        let analysis = await doAnalysis({
            inputs: this.state.analysis.inputs,
            parameters: {
                archetype: this.state.analysis.inputs.archetype.acronym,
                ...newParameters
            },
            elements,
            extraMaterials,
        })

        analysis = munge_analysis(analysis)

        analysis.groupId = this.props.analysis.groupId
        if (this.props.analysis.payment) {
            analysis.payment = this.props.analysis.payment
        }

        this.setState({
            analysis: analysis,
            loading: false,
        })

        // this.handleAssetValueChange(pathwayNumber, optionNumber)
    }


    render() {
        const { analysis, materials } = this.state

        if (
            // Check if Globe is being used. Redirect to Easy if not.
            !Object.keys(crConstants).includes("ClimateValuation") && // TODO: Review how this works
            analysis.input_source == "munging"
        ) {
            let url =
                "https://dev.easy.xdi.systems/#~(formValues" +
                "~(latitude~" +
                analysis.geometry.coordinates[1] +
                "~longitude~" +
                analysis.geometry.coordinates[0]
            let known_archetype
            if (analysis.inputs.archetype.acronym != undefined) {
                url += "~archetype~'" + analysis.inputs.archetype.acronym + ")~showModal~true)"
                known_archetype = true
            } else {
                url += ")~showModal~false)"
                known_archetype = false
            }

            return (
                <React.Fragment>
                    <div className="easy-link">
                        <p>
                            <i className="material-icons">warning</i> The repository data for this
                            asset has not yet been updated for adaptation in XDI Globe.
                        </p>
                        <p>
                            We are currently working on getting all data updated. In the meantime
                            you can{" "}
                            <a href={url}>
                                {known_archetype
                                    ? ` adapt a ${analysis.inputs.archetype.name} `
                                    : " select the appropriate archetype, and adapt it "}
                                at EasyXDI
                            </a>
                            , in the <em>Adaptations</em> tab.
                        </p>
                        <p>
                            If you need access to EasyXDI, please contact{" "}
                            <a href="mailto:info@xdi.systems">info@xdi.systems</a>.
                        </p>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <div className="text-block">
                    <h3>Asset Adaptation:</h3>
                    <p>
                        On this page future adaptations can be applied to the asset to plan for
                        future risk. Use this tool to find the adaptation combinations that are most
                        effective for the asset and the cost of adaptation.
                    </p>
                    <p>
                        <b>Step 1: </b>Add an adaptation pathway. Each pathway represents a timeline
                        for the asset. Up to three options can be added to each pathway.
                    </p>
                    <p>
                        <b>Step 2: </b>Add a second adaptation pathway with different options to
                        compare the results. All results will be shown on analysis charts.
                    </p>
                    <p>
                        <b>Step 3: </b>Edit your adaptation options to compare results.
                    </p>
                </div>

                {!analysis ? (
                    <Loading />
                ) : (
                    <Adaptations
                        materials={materials}
                        analysis={this.state.analysis /* modifiable analysis */}
                        analysisBaseline={this.state.analysisBaseline}
                        currentID={analysis._id}
                        valueUsedToTriggerChildAgain={this.state.valueUsedToTriggerChildAgain}
                        // savedAnalysis={adaptState}
                        currentValues={analysis.inputs.asset.properties}
                        submitText="Update Adaptation Analysis"
                        onSubmit={this.ADAPT_runChangedParameters}
                        onSaveAdaptation={this.props.handleSaveAdaptation}
                        onDeleteAdaptation={this.props.handleDeleteAdaptation}
                        displayCharts={this.props.displayCharts}
                    />
                )}
            </React.Fragment>
        )
    }
}
export default AdaptationWrapper
