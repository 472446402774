import React from "react"
import autoBind from "react-autobind"
import { observer } from "mobx-react"
import PropTypes from 'prop-types'

import ResultModal from "../ResultModal/ResultModal.jsx"
import StackSelectModal from '../StackSelectModal/StackSelectModal.jsx'
import PDFReport from '../../CR-Components/Reports/PdfReportComponents/PDFReport.jsx'
import { Toolbar } from "../Toolbar/Toolbar.jsx"

import "./App.scss"
import { openAnalysisModal } from "../../utils.js"
import { Cartesian3 } from "cesium/Cesium"
import { scene } from "../../scene.js"
import { IncorrectOrganisationModal } from "../IncorrectOrganisationModal/IncorrectOrganisationModal.jsx"
import { adjustRCP, preValidateAnalysis } from '../AdjustRCP/adjustRCP'

@observer
class App extends React.Component {
    static propTypes ={
        appState: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            mismatchedOrganisation: null,
            showReportProcessingModal: false,
            filename: null,
            rcpValue: "8.5",
            rcpError: null
        }

        autoBind(this)

        const queries = {}
        for (const entry of new URLSearchParams(window.location.search)) {
            queries[entry[0]] = entry[1]
        }
        
        
        if (queries.asset) {
            if (queries.organisation !== window.user_organisation) {
                this.state.mismatchedOrganisation = queries.organisation;
                return;
            }

            openAnalysisModal(queries.asset, "query")
                .then(async (data) => {
                    if (!data || !data.length) {
                        return
                    }

                    const asset = data[0];
                    const [longitude, latitude] = asset.geometry.coordinates;

                    const onPreUpdate = () => {
                        const current = scene.viewer.camera.position;
                        const destination = Cartesian3.fromDegrees(longitude, latitude, 5000);
                        if (current.x === destination.x && current.y === destination.y) {
                            scene.viewer.scene.preUpdate.removeEventListener(onPreUpdate);
                            scene.viewer.scene.postUpdate.removeEventListener(onPostUpdate);
                        }
                    };

                    const onPostUpdate = () => {
                        const destination = Cartesian3.fromDegrees(longitude, latitude, 5000);
                        scene.viewer.camera.setView({ destination });
                    };

                    scene.viewer.scene.preUpdate.addEventListener(onPreUpdate);
                    scene.viewer.scene.postUpdate.addEventListener(onPostUpdate);
                })
        }
    }

    handleDownload() {
        console.log("handleDownload")
        const analysis = this.props.appState.current_analysis;

        var illegalRe = /[\/\?<>\\:\*\|":]/g;
        var controlRe = /[\x00-\x1f\x80-\x9f]/g;
        var reservedRe = /^\.+$/;
        var windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
        var windowsTrailingRe = /[\. ]+$/;

        const filename = (
            analysis.properties.address +
            ".pdf"
        )
            .replace(illegalRe, "_")
            .replace(controlRe, "_")
            .replace(reservedRe, "_")
            .replace(windowsReservedRe, "_")
            .replace(windowsTrailingRe, "_");

        // expects coordinates, fieldDefaultValues not used in Globe, Mocked
        this.props.appState.fieldDefaultValues = {
            latitude: analysis.geometry.coordinates[1],
            longitude: analysis.geometry.coordinates[0],
            elevation: analysis.totalHeight - analysis.heightAboveGround,
            ...analysis.inputs.asset.properties,
        }

        // Elevation property expected on Easy reports, filled if missing
        this.props.appState.current_analysis.inputs.asset.properties = {
            elevation: analysis.totalHeight - analysis.heightAboveGround,
            ...analysis.inputs.asset.properties
        }

        this.setState({ showReportProcessingModal: true, filename: filename });
    }

    onDownload() {
        this.setState({ showReportProcessingModal: false });
    }

    handleClose() {
        this.props.appState.showResultModal = false
    }

    handleCloseStackSelectModal() {
        this.props.appState.showStackSelectModal = false
    }


    handleRCPChange(fromRCP, toRCP) {
        console.log(`RCP changed from ${fromRCP} to ${toRCP}`)

        try {
            this.setState({ rcpValue: toRCP })
            if (toRCP === '8.5') {
                this.props.appState.current_analysis = this.props.appState.original_analysis
            } else {
                const analysis = adjustRCP(this.props.appState.original_analysis, toRCP)
                this.props.appState.current_analysis = analysis
            }
        } catch(e) {
            this.setState({ rcpValue: fromRCP, rcpError: e })
        }
    }

    handlePreValidateAnalysis(analysis) {
        try {
            preValidateAnalysis(analysis)
        } catch(e) {
            this.setState({ rcpError: e })
        }
    }

    handleClearRCPState() {
        this.setState({ rcpValue: '8.5', rcpError: null })
    }

    render() {
        const {
            showResultModal,
            analysesList,
            showStackSelectModal,
            current_analysis,
            current_analysis_source,
            current_dependencies,
            materials,
            account
        } = this.props.appState

        const {
            mismatchedOrganisation,
            showReportProcessingModal,
            filename,
        } = this.state

        return (
            <div id="App">
                <Toolbar />
                {mismatchedOrganisation && <IncorrectOrganisationModal
                    expected={mismatchedOrganisation}
                    given={window.user_organisation}
                    onHide={() => {
                        this.setState({ mismatchedOrganisation: null });
                    }}
                />}

                {showReportProcessingModal && (
                    <PDFReport
                        isPurchased={() => true}
                        analysisGroup={null}
                        analysis={current_analysis}
                        fieldDefaultValues={{
                            ...current_analysis.inputs.asset.properties,
                            latitude: current_analysis.geometry.coordinates[1],
                            longitude: current_analysis.geometry.coordinates[0]
                        }}
                        account={account}
                        onDownload={this.onDownload}
                        filename={filename}
                        materials={materials.length ? materials : current_analysis.inputs.asset.properties.elementMaterials}
                    />
                )}

                {showResultModal && current_analysis && !showReportProcessingModal && (
                    <ResultModal
                        analysis={current_analysis}
                        analysisSource={current_analysis_source}
                        dependencies={current_dependencies}
                        handleClose={this.handleClose}
                        handleDownload={this.handleDownload}
                        handleRCPChange={this.handleRCPChange}
                        rcpValue={this.state.rcpValue}
                        rcpError={this.state.rcpError}
                        handlePreValidateAnalysis={this.handlePreValidateAnalysis}
                        handleClearRCPState={this.handleClearRCPState}
                    />
                )}

                {showStackSelectModal && (
                    <StackSelectModal
                        assetList={analysesList}
                        handleClose={this.handleCloseStackSelectModal} />
                )}
            </div>
        )
    }
}

export default App
