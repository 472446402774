import React, { useEffect } from "react"
import autoBind from "react-autobind"
import { Modal, Tabs, Tab, Form, Table, Button, Alert, Dropdown } from "react-bootstrap"
import { observer } from "mobx-react"
import PropTypes from "prop-types"
import ReactGA from "react-ga"

import { checkNested, showHazard } from "../../CR-Components/utils"

import { getColorFromRisk, getColorFromFail, getTermForRisk, formatValue } from "../../utils"

import StreetView from "../../CR-Components/Results/LocationView.jsx"
import AdaptationWrapper from "../../CR-Components/Adaptation/AdaptationWrapper.jsx"
import MultiAdaptationForm from "../../CR-Components/Adaptation/MultiAdaptationForm/MultiAdaptationForm.jsx"
import Scenario from "../../CR-Components/Results/Scenarios.jsx"
import HazardBreakdown from "../../CR-Components/Results/HazardBreakdown.jsx"
import Vulnerability from "../../CR-Components/Results/Vulnerability.jsx"
import { KPIChart } from "../KPIChart/KPIChart.jsx"
import { DependencyTab } from "../DependencyTab/DependencyTab.jsx"
import DependencyMap from "../DependencyTab/DependencyMap.jsx"
import ValueAtRisk from "../../CR-Components/Results/ValueAtRisk.jsx"

import "./ResultModal.scss"
import { ControlledAccordion } from "../ControlledAccordion/ControlledAccordion.jsx"
import { hazard_icons, hazard_labels } from '../../CR-Components/constants.js'

@observer
class ResultModal extends React.Component {
    static propTypes = {
        analysis: PropTypes.object,
        analysisSource: PropTypes.string,
        dependencies: PropTypes.array,
        handleClose: PropTypes.func,
        handleDownload: PropTypes.func,
        handleRCPChange: PropTypes.func,
        handleClearRCPState: PropTypes.func,
        handlePreValidateAnalysis: PropTypes.func,
        rcpValue: PropTypes.string,
        rcpError: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.state = {
            selectedTab: "flag",
        }

        autoBind(this)
    }

    handleTabSelect(key) {
        this.setState({ selectedTab: key })
    }

    handleTabSelectBox() {
        const option = document.getElementById("Select_Tab").value
        this.setState({ selectedTab: option })
    }

    handleRCPSelectBox(toRCP) {
        this.props.handleRCPChange(this.props.rcpValue, toRCP)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (crConstants.production) ReactGA.modalview("/analysis-result")
        this.props.handleClearRCPState()
        this.props.handlePreValidateAnalysis(this.props.analysis)
    }

    render() {
        const { analysis, analysisSource, dependencies, handleDownload } = this.props
        const aProp = analysis.properties
        const { selectedTab } = this.state

        // TODO: Move this user check to Auth Provider.
        const show_adaptations = this.props.rcpValue == "8.5" && [
            "test",
            "demo",
            "qas",
            "qfes",
            "qld-corrections",
            "qld-education",
            "qld-emergency",
            "qld-energy",
            "qld-health",
            "qld-ports",
            "qld-powerlink",
            "qld-public",
            "qld-rail",
            "qld-roads",
            "qps",
            "xdinsw",
            "vanc_edu",
            "vanc-cs",
            "vanc-health",
            "vanc_public",
            "stanwell",
            "gpt",
            "whai_rawa",
            "ey-optus",
            "bankofamerica",
            "bluescope",
            "bchealth",
            "fha",
            "vch",
            "moh",
            "iha",
            "nha",
            "viha",
            "phsa",
            "marsh_lp",
            "morgan-stanley",
            "credit-suisse",
            "nbn_national",
            "sw",
            "optus",
            "optus_national",
            "xdi-california",
            "singtel",
            "dhhs",
            "ntr",
            "sime_darby",
            "goldman_sachs",
            "woolworths",
            "argoip",
            "adl_test",
            "barings",
            "singpost",
            "ibm",
            "cppib",
            "watershed",
            "bmo",
            "demo_apac",
            "demo_adaptation",
            "knb",
            "scre",
            "hunterwater",
            "csiro_bega",
            "stockland",
            "westpac_nz",
            "gic",
            "capitaland",
            "mapletree",
            "gt_capital",
	        "icd",
            "scc",
        ].includes(window.user_organisation.toLowerCase())

        // TODO: Move this user check to Auth Provider
        const show_dependency_map = [
            "test",
            "demo",
            "nbn_national",
            "qld-energy",
            "qas",
            "qfes",
            "qld-corrections",
            "qld-education",
            "qld-emergency",
            "qps",
            "qld-roads",
            "xdinsw",
            "vanc_public",
            "vanc-cs",
            "vanc-health",
            "vanc_edu",
            "bchealth",
            "fha",
            "vch",
            "moh",
            "iha",
            "nha",
            "viha",
            "phsa",
            "water", // test org
            "sw",
            "optus",
            "optus_national",
            "xdi-california",
            "singtel",
            "dhhs",
            "sawater",
        ].includes(window.user_organisation.toLowerCase())

        // TODO: Move this user check to Auth Provider
        const show_easy_reports = [
            "test",
            "fpc",
            "cubeenergy",
            "savills",
        ].includes(window.user_organisation.toLowerCase())

        // TODO: Move this user check to Auth Provider
        const rcp_values = ["2.6", "4.5", "6.0", "8.5"]

        const show_rcp_orgs = {
            "test": ["2.6", "4.5", "6.0", "8.5"],
            "demo": ["2.6", "4.5", "6.0", "8.5"],
            "sw": ["2.6", "4.5", "6.0", "8.5"],
            "adl_test": ["2.6", "4.5", "6.0", "8.5"],
            "barings": ["4.5", "8.5"],
            "cppib": ["2.6", "8.5"],
            "wow": ["2.6", "4.5", "8.5"],
            "nomura": ["2.6", "8.5"],
            "uob_singapore2": ["2.6", "8.5"],
            "bmo": ["2.6", "8.5"],
            "demo_apac": ["2.6", "8.5"],
            "ns": ["2.6", "8.5"],
            "sustana": ["2.6", "4.5", "8.5"],
            "demo_adaptation": ["2.6", "4.5", "6.0", "8.5"],
            "babcock": ["2.6", "8.5"],
            "capital": ["2.6", "8.5"],
            "knb": ["4.5", "8.5"],
            "unity_water": ["4.5", "8.5"],
            "ctbc": ["2.6", "8.5"],
            "hunterwater": ["2.6", "8.5"],
            "csiro_bega": ["4.5", "8.5"],
            "stockland": ["4.5", "8.5"],
            "gt_capital": ["4.5", "8.5"],
            "westpac_nz": ["2.6", "8.5"],
            "icd": ["2.6", "8.5"],
            "scc": ["2.6", "4.5", "8.5"],
        }

        const show_change_rcp = Object.keys(show_rcp_orgs).includes(window.user_organisation.toLowerCase())

        const show_kpi_tab = [
            "test",
        ].includes(window.user_organisation.toLowerCase())

        let permalink = window.location.origin;
        if (window.location.pathname !== "/") {
            permalink += window.location.pathname;
        }
        permalink += `?asset=${analysis._id}&organisation=${window.user_organisation}`

        let fiftyIndex = 30;
        if (aProp.riskHazards.length < 50) {
            fiftyIndex = 12;
            console.log("TODO: Determine 2050 index more robustly");
        }

        const isRCPDisabled = (rcp) => {
            if (rcp === "8.5") return false
            if (!show_change_rcp) return true

            return !show_rcp_orgs[window.user_organisation].includes(rcp)
        }

        return (
            <Modal show={true} id="modalResult" onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <div className="headerItems" style={{ width: '100%' }}>
                        <div className="header-container">
                            <h5 className="modal-title" id="dialog-title">
                                {aProp.name} {aProp.address}
                            </h5>

                            {show_change_rcp && (
                                <div className="header-right">
                                    {this.props.rcpError &&
                                        <p className="text-danger error">{this.props.rcpError.message}</p>
                                    }
                                    <Dropdown onSelect={this.handleRCPSelectBox}>
                                        <Dropdown.Toggle variant="outline-primary" id="select-scenario" size="sm" disabled={this.props.rcpError !== null}>
                                            RCP {this.props.rcpValue}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {rcp_values.map(v => (
                                                <Dropdown.Item key={v} eventKey={v} disabled={isRCPDisabled(v)}>RCP {v}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            )}
                        </div>
                        {analysisSource !== "asset-click" && <Alert className="availability-note" variant="warning">
                            NOTE: This asset may not be available in your chosen Asset View
                        </Alert>}
                    </div>

                </Modal.Header>
                <Modal.Body>

                    <Form className="Tab_mobile-select" onChange={this.handleTabSelectBox}>
                        <Form.Group controlId="Select_Tab">
                            {/* <Form.Label></Form.Label> */}
                            <Form.Control as="select" custom size="lg">
                                <option value={"flag"}>Summary</option>
                                <option value={"overview"}>Value at Risk</option>
                                <option value={"hazard"}>Hazard Breakdown</option>
                                <option value={"vulnerability"}>Vulnerability</option>
                                {show_kpi_tab && <option value={"kpi"}>KPI</option>}
                                <option value={"location"}>Location</option>
                                <option value={"detail"}>Detail</option>
                                <option value={"dependency"}>Dependency</option>
                                {show_adaptations && <>
                                    <option value={"adaptation"}>Adaptation</option>
                                    <option value={"multiAdapt"}>Multi-Adaptation</option>
                                </>}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                    <Tabs activeKey={selectedTab} onSelect={this.handleTabSelect}>
                        <Tab
                            eventKey="flag"
                            title={
                                <span>
                                    {"Summary "}
                                    <span className="badge badge-info" id="badge">
                                        {aProp.riskHazards[fiftyIndex].reduce(
                                            (a, b) => a + (b > 0.002 ? 1 : 0),
                                            0
                                        )}
                                    </span>
                                </span>
                            }>
                            {selectedTab === "flag" ? <SummaryTab analysis={analysis} /> : ""}
                        </Tab>

                        <Tab eventKey="overview" title="Overview">
                            {selectedTab === "overview" ? <ValueAtRisk analysis={analysis} /> : ""}
                        </Tab>

                        <Tab eventKey="hazard" title="Hazard">
                            {selectedTab === "hazard" ? (
                                <HazardBreakdown
                                    analysis={analysis}
                                    startYear={
                                        Object.prototype.hasOwnProperty.call(analysis, "scenario")
                                            ? analysis.scenario.startYear
                                            : analysis.inputs.scenario.startYear
                                    }
                                    endYear={
                                        Object.prototype.hasOwnProperty.call(analysis, "scenario")
                                            ? analysis.scenario.endYear
                                            : analysis.inputs.scenario.endYear
                                    }
                                    purchased={true}
                                />
                            ) : (
                                ""
                            )}
                        </Tab>

                        {/* filters out globe analyses that don't contain inputs */}
                        {
                            checkNested(
                                analysis,
                                "inputs",
                                "asset",
                                "properties",
                                "elementMaterials"
                            ) && (
                                <Tab eventKey="vulnerability" title="Vulnerability">
                                    {selectedTab === "vulnerability" ? (
                                        <Vulnerability analysis={analysis} />
                                    ) : (
                                        ""
                                    )}
                                </Tab>
                            )
                        }
                        {show_kpi_tab && 
                            <Tab eventKey="kpi" title="KPI">
                                {selectedTab === "kpi" ? <KPIChart analysis={analysis} /> : ""}
                            </Tab>
                        }

                        <Tab eventKey="location" title="Location">
                            {selectedTab == "location" ? <StreetView analysis={analysis} /> : ""}
                        </Tab>

                        <Tab eventKey="detail" title="Detail">
                            {selectedTab === "detail"
                                ? <DetailTab
                                    analysis={analysis}
                                    archetype={aProp.archetype}
                                    rcp={this.props.rcpValue}
                                />
                                : ""}
                        </Tab>

                        <Tab
                            eventKey="dependency"
                            title={
                                <span>
                                    {"Dependency "}
                                    <span className="badge badge-info" id="depflag">
                                        {Object.keys(dependencies).length}
                                    </span>
                                </span>
                            }>
                            {show_dependency_map ? (
                                <React.Fragment>
                                    {selectedTab == "dependency" ? (
                                        <DependencyMap
                                            analysis={this.props.analysis}
                                            dependencies={dependencies}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </React.Fragment>
                            ) : (
                                ""
                            )}
                            {selectedTab == "dependency" ? (
                                <DependencyTab dependencies={dependencies} />
                            ) : (
                                ""
                            )}
                        </Tab>

                        {show_adaptations ? (
                            <Tab eventKey="adaptation" title="Adaptation">
                                <div className="center">
                                    {selectedTab == "adaptation" ? (
                                        <AdaptationWrapper analysis={analysis} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Tab>
                        ) : (
                            ""
                        )}
                        {show_adaptations ? (
                            <Tab eventKey="multiAdapt" title="Multi-Adaptation">
                                <MultiAdaptationForm />
                            </Tab>
                        ) : (
                            ""
                        )}

                        {show_easy_reports ? (
                            <Tab eventKey="easyReports" title="Easy Reports">
                                {selectedTab === "easyReports" ? (
                                    <>
                                        {/* TODO: add pdf report */}
                                        <Button
                                            variant="secondary"
                                            onClick={() => handleDownload()}
                                            title="Download this report">
                                            Download PDF Report
                                        </Button>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Tab>
                        ) : (
                            ""
                        )}
                    </Tabs>
                    <div className="permalink">
                        <div>
                            <b>Permalink</b>
                        </div>
                        <input
                            defaultValue={permalink}
                            disabled
                            style={{ width: "100%" }}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

const SummaryTab = (props) => {
    useEffect(() => {
        if (crConstants.production) ReactGA.modalview("/analysis-result/summary-tab")
    }, [])

    const aProp = props.analysis.properties

    let fiftyIndex = 30;
    if (aProp.riskHazards.length < 50) {
        fiftyIndex = 12;
        console.log("TODO: Determine 2050 index more robustly");
    }

    const totalRisk = aProp.totalRiskCost[fiftyIndex] / aProp.replacementCost
    const riskColors = aProp.riskHazards[fiftyIndex].map((v) => getColorFromRisk(v))
    const failColors = aProp.failureHazards[fiftyIndex].map((v) => getColorFromFail(v * 100))

    const drawIcons = (colors) => {
        const hazards = props.analysis.inputs.scenario.hazards

        return (
            <div id="summary-cards" >
                {hazards.map((hazard, index) => (
                    <div className="text-center card" key={index}>
                        <div className="card-img-top">
                            <i className="material-icons-large" id="flood" style={{ color: colors[index] }}>
                                {hazard_icons[hazard]}
                            </i>
                        </div>
                        <h4 className="text-center text-muted" style={{fontSize: 20}}>{hazard_labels[hazard]}</h4>
                    </div>
                ))}
            </div>
        )
    }

    const LegendItem = ({ color, risk, description }) => {
        return (
            <div className="legend-item">
                <div className="risk-wrapper">
                    <span className="square" style={{ background: color }} />
                    <span className="risk">{risk}</span>
                </div>
                <div>{description}</div>
            </div>
        )
    }

    const riskLegend = [
        ["green", "Low Risk", "May be insurable at reasonable cost (%VAR < 0.2)"],
        ["orange", "Moderate Risk", "May lead to higher insurance costs (0.2 < %VAR < 1)"],
        ["red", "High Risk", "Insurance may be high cost or unavailable unless adaptation actions are undertaken (%VAR > 1)"],
    ]

    const failLegend = [
        ["green", "Low Risk", "May be insurable at reasonable cost (Failure Probability % < 0.75)"],
        ["orange", "Moderate Risk", "May lead to higher insurance costs (0.75 < Failure Probability % < 3)"],
        ["red", "High Risk", "Insurance may be high cost or unavailable unless adaptation actions are undertaken (Failure Probability % > 3)"],
    ]

    // TODO: Move Asset Information section to a CR-components class, merge with Easy
    return (
        <div id="flags">
            <div id="summary-tab" className="container">
                <h2 id="summary-title" className="text-center col" style={{ marginTop: "24px", marginBottom: 0}}>
                    2050 Summary
                </h2>
                <div className="summary-wrapper">
                    <h2 id="summary-title" className="text-center col">
                        This Asset Has{" "}
                        <span id="insurance" style={{ color: getColorFromRisk(totalRisk) }}>
                            <strong>
                                <span id="word">{getTermForRisk(totalRisk)}</span>
                            </strong>
                        </span>{" "}
                        Climate Change Risk
                    </h2>

                    {drawIcons(riskColors)}

                    <div className="legend">
                        {riskLegend.map((i) => <LegendItem key={i[0]} color={i[0]} risk={i[1]} description={i[2]} /> )}
                    </div>
                </div>

                <div className="summary-wrapper" style={{ margin: "32px 0" }}>
                    <h2 id="summary-title" className="text-center col">
                        Failure Probability
                    </h2>

                    {drawIcons(failColors)}

                    <div className="legend">
                        {failLegend.map((i) => <LegendItem key={i[0]} color={i[0]} risk={i[1]} description={i[2]} /> )}
                    </div>
                </div>
            </div>
        </div>
    )
}
SummaryTab.propTypes = {
    analysis: PropTypes.object,
    rcp: PropTypes.string
}

// TODO: change to functional component
class DetailTab extends React.Component {
    static propTypes = {
        analysis: PropTypes.object,
        archetype: PropTypes.string,
        rcp: PropTypes.string
    }

    componentDidMount() {
        if (crConstants.production) ReactGA.modalview("/analysis-result/detail-tab")
    }

    render() {
        const { analysis, archetype, rcp } = this.props
        const aProp = analysis.inputs.asset.properties
        const showMaterials = checkNested(
            analysis,
            "inputs",
            "asset",
            "properties",
            "elementMaterials"
        )

        let elevation = "not available"
        let heightAboveGround = "not available"
        if (checkNested(analysis, "inputs", "asset")) {
            heightAboveGround = aProp.heightAboveGround
            if (checkNested(analysis.inputs, "asset", "geometry", "elevation")) {
                elevation = analysis.inputs.asset.geometry.elevation
            } else {
                elevation = aProp.totalHeight - heightAboveGround
            }
        }

        return (
            <div id="detail-tab" className="container">
                <ControlledAccordion>
                    {[
                        {
                            title: "Asset Information",
                            body: <div id="detail-asset-info" className="detail-block">
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <b>PID </b>
                                    </div>
                                    <div className="col-6" id="pid">
                                        {analysis._id}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <b>Address</b>
                                    </div>
                                    <div className="col-6" id="address">
                                        {formatValue(aProp.address)}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <b>Name</b>
                                    </div>
                                    <div className="col-6" id="name">
                                        {formatValue(aProp.name)}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <b>Archetype</b>
                                    </div>
                                    <div className="col-6" id="arch">
                                        {formatValue(analysis.inputs.archetype.name)}
                                    </div>
                                </div>
                                <hr />
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <b>Market Value</b>
                                    </div>
                                    <div className="col-6" id="market-value">
                                        {formatValue(aProp.marketValue, true)}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <b>Replacement Cost</b>
                                    </div>
                                    <div className="col-6" id="replacement-cost">
                                        {formatValue(aProp.replacementCost, true)}
                                    </div>
                                </div>
                                <hr />
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <b>Latitude</b>
                                    </div>
                                    <div className="col-6" id="latitude">
                                        {formatValue(analysis.geometry.coordinates[1])}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <b>Longitude</b>
                                    </div>
                                    <div className="col-6" id="longitude">
                                        {formatValue(analysis.geometry.coordinates[0])}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <b>Elevation (m above AHD)</b>
                                    </div>
                                    <div className="col-6" id="elevation">
                                        {formatValue(elevation, true)}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <b>Height above ground (m)</b>
                                    </div>
                                    <div className="col-6" id="height-above-ground">
                                        {formatValue(heightAboveGround, true)}
                                    </div>
                                </div>
                            </div>
                        },
                        showMaterials && {
                            title: "Element Materials",
                            body: <div className="elements">
                                <Table striped bordered hover size="sm" className="elements_detail-table">
                                    <thead className="hazard-metadata-header">
                                        <tr>
                                            <th>Element</th>
                                            <th>Material name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(analysis.inputs.asset.properties.elementMaterials).map(
                                            (el) => {
                                                if (typeof el === "string") {
                                                    return (
                                                        <tr key={el}>
                                                            <td>
                                                                {el.substring(0, 1) +
                                                                    el
                                                                        .substring(1)
                                                                        .replace(/([A-Z])/g, " $1")
                                                                        .toLowerCase()}
                                                            </td>
                                                            <td>
                                                                {
                                                                    analysis.inputs.asset.properties
                                                                        .elementMaterials[el].name
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            }
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        },
                        {
                            title: "Scenario Information",
                            body: <div id="detail-scenario-info" className="detail-block">
                                <Scenario analysis={analysis} rcp={rcp} />
                            </div>
                        },
                        {
                            title: "Archetype",
                            body: <div className="center">
                                <img
                                    className="img-thumbnail center"
                                    src={`/static/images/Archetypes/${archetype}.png`}
                                />
                            </div>
                        }
                    ]}
                </ControlledAccordion>

                <div id="detail-download-buttons" className="detail-block">
                    {/* <a id="download-button-csv" href="" download="" className="btn btn-primary">Download as CSV</a> */}
                    <a
                        id="download-button-json"
                        className="btn btn-primary"
                        href={
                            "data:text/plain;charset=UTF-8," +
                            encodeURIComponent(JSON.stringify(analysis))
                        }
                        download={analysis._id + ".geojson"}>
                        Download as GeoJSON
                    </a>
                </div>
            </div>
        )
    }
}

export default ResultModal
