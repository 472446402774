import React from "react"
import PropTypes from "prop-types"

import Highcharts from "highcharts"
import ReactHighcharts from "react-highcharts"
import HighchartsMore from "highcharts-more"
import HighchartsExporting from "highcharts-exporting"
import { toJS } from "mobx"

import { checkNested } from "../utils"
import { DrawAnnualVARBreakdown, DrawAnnualFPBreakdown } from "../Charts/Breakdown-Charts.jsx"
import adaptState from "./adaptState"

// Initialize highcharts exporting
// Highcharts more
HighchartsMore(ReactHighcharts.Highcharts)

// Highcharts exporting add
HighchartsExporting(ReactHighcharts.Highcharts)

class AdaptationTIPChart extends React.Component {
    static propTypes = {
        analysisBaseline: PropTypes.shape({
            _id: PropTypes.string,
            geometry: PropTypes.object,
            metadata: PropTypes.object,
            properties: PropTypes.object, // TODO: Delete this. Not in Risk V2
            results: PropTypes.object,
            inputs: PropTypes.object,
            meta: PropTypes.object, // TODO: Delete this? Globe specific
        }),
        assetReplacementCost: PropTypes.number,
        // This is false initially, true after an adaptation is submitted.
        boolScenario: PropTypes.bool,
        oldData: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]), // TODO: What is this?
        currentID: PropTypes.string, // ID of the analysisBaseline being used as the baseline?
        years: PropTypes.array
    }

    constructor(props) {
        super(props)
    }

    drawHighCharts(values) {
        let highChartsConfig = {
            chart: {
                height: "500px",
                zoomType: "xy",
                type: "line",
            },
            credits: {
                enabled: false
            },
            title: {
                text: values.title,
            },
            xAxis: {
                title: {
                    text: "Year",
                },
            },
            plotOptions: {
                series: {
                    pointStart: this.props.analysisBaseline.inputs.scenario.startYear
                    || this.props.years[0]
                    || 1990,
                    pointInterval: (this.props.years[1] - this.props.years[0]) || 1
                },
            },
            tooltip: {
                pointFormatter: function () {
                    return (
                        this.series.name + "</b>: $" + Highcharts.numberFormat(this.y, 0) + "</b>"
                    )
                },
            },
            yAxis: {
                min: 0,
                minRange : 0.001,
                title: {
                    text: "Technical Insurance Premium ($)",
                },
                softMax: 0.25,
                minTickInterval: 0.01,
            },
            navigation: {
                buttonOptions: {
                    enabled: true,
                },
            },
            series: values.series,
        }

        return highChartsConfig
    }

    render() {
        const {
            analysisBaseline,
            assetReplacementCost,
            boolScenario,
            oldData,
            currentID,
        } = this.props
        const series = []

        const chartLabels = ["Baseline", "Pathway - 1", "Pathway - 2", "Pathway - 3"]

        const n_years = analysisBaseline.properties.totalRisk.length

        const startYear = analysisBaseline.inputs.scenario.startYear
            || this.props.years[0]
            || 1990

        let caseForDefaultCase = oldData
        if (this.props.currentID && caseForDefaultCase !== undefined) {
            caseForDefaultCase = caseForDefaultCase[currentID]
        }

        // get riskfraction not risk to date
        const getTIP = function () {
            let riskFraction = analysisBaseline.properties.totalRisk.map(
                (v) => v * assetReplacementCost
            )
            return riskFraction
        }

        series[0] = {
            name: chartLabels[0],
            data: getTIP(),
            marker: {
                enabled: false,
            },
        }

        // First 1
        let pathways = ["pathway1", "pathway2", "pathway3"]
        let options = ["option1", "option2", "option3"]
        let CombinedOptionsOfPathway = []
        let CombinedRiskHazards = []
        let CombinedFailureHazards = []

        // TODO: Better name for this variable. What does it represent?
        //
        // From Fahad:
        // 'case1' will contain data coming from the 'saved adaptations' if
        // 'boolScenerio' is false.
        // It will have data of 'updated adaptations' if 'boolScenerio' is true.
        // Default value of 'boolScenerio' is false.
        // It'll be true if user update any option against any pathway.
        // We are then using case1 to merge 'saved adaptations' and 'updated
        // adaptations'.
        // Final value of case1 will have merged results (saved
        // & updated adaptations).
        var case1 = adaptState.adaptation[adaptState.currentAnalysisId]
        var caseForUpdateCase = adaptState.adaptation[adaptState.currentAnalysisId]
        if (boolScenario == false) {
            case1 = caseForDefaultCase
        } else {
            case1 = caseForUpdateCase
        }

        // TODO: Replace ALL of this with a simple break-point calculation, and
        // splicing of totalRisk/riskHazrd values. This looping is unneccesary.
        try {
            for (let path of pathways) {
                for (let opt of options) {
                    // TODO: Turn this into a function? Called what?
                    if (caseForUpdateCase !== undefined && caseForUpdateCase[path] !== undefined) {
                        if (caseForUpdateCase[path][opt] !== undefined) {
                            if (case1[path] !== undefined) {
                                if (case1[path][opt] !== undefined) {
                                    case1[path][opt] = caseForUpdateCase[path][opt]
                                } else {
                                    case1[path] = {
                                        ...case1[path],
                                        [opt]: caseForUpdateCase[path][opt],
                                    }
                                }
                            } else {
                                let level0 = { [opt]: caseForUpdateCase[path][opt] }
                                case1 = { ...case1, [path]: level0 }
                            }
                        }
                    } else if (
                        caseForDefaultCase !== undefined &&
                        caseForDefaultCase[path] !== undefined
                    ) {
                        if (caseForDefaultCase[path][opt] !== undefined) {
                            if (case1[path] !== undefined) {
                                if (case1[path][opt] !== undefined) {
                                    case1[path][opt] = caseForDefaultCase[path][opt]
                                } else {
                                    // TODO: Doesn't this do exactly the same thing as the line above?
                                    case1[path] = {
                                        ...case1[path],
                                        [opt]: caseForDefaultCase[path][opt],
                                    }
                                }
                            } else {
                                let level0 = { [opt]: caseForDefaultCase[path][opt] }
                                case1 = { ...case1, [path]: level0 }
                            }
                        }
                    }
                }
            }
        } catch (e) {
            // TODO: This should not be empty, needs explainer
        }

        // TODO: split some of this up into functions? Logic needs comments
        // This section does the combining of options for each pathway, I think.
        for (const [index, p] of pathways.entries()) {
            // TODO: Explain how case1 would be undefined here. Does this if need to exist?
            if (checkNested(case1, p)) {
                // Converts total risk to total risk cost
                // TODO: is this necessary? see below.
                CombinedOptionsOfPathway[index] = analysisBaseline.properties.totalRisk.map(
                    (v) => v * assetReplacementCost
                )


                CombinedRiskHazards[index] = analysisBaseline.properties.riskHazards.slice(0)
                CombinedFailureHazards[index] = analysisBaseline.properties.failureHazards.slice(0)

                // This part does the actual merge of all options for the pathway. I think.
                for (let opt of options) {
                    let changedArray = []
                    let changedRiskHazardsArray = []
                    let changedFailureHazardsArray = []
                    if (checkNested(case1[p], opt)) {
                        // Year of adaptation option
                        const adaptationYear = adaptState.year[p][opt]
                        const startYearIndex = this.props.years.indexOf(startYear)
                        const adaptationYearIndex = this.props.years.indexOf(adaptationYear)

                        for (let z = adaptationYearIndex - startYearIndex; z < n_years; z++) {
                            // convert totalRisk to totalRiskCost
                            changedArray.push(
                                case1[p][opt].properties.totalRisk[z] * assetReplacementCost
                            )
                            //
                            // Merge adaptation option results
                            changedRiskHazardsArray.push(case1[p][opt].properties.riskHazards[z])
                            changedFailureHazardsArray.push(case1[p][opt].properties.failureHazards[z])
                        }

                        // TODO: I have no idea what this is doing.
                        if (changedArray.length > 1) {
                            var y = 0
                            for (let z = 0; z < CombinedOptionsOfPathway[index].length; z++) {
                                if (z >= adaptationYearIndex - startYearIndex) {
                                    CombinedOptionsOfPathway[index][z] = changedArray[y]
                                    CombinedRiskHazards[index][z] = changedRiskHazardsArray[y]
                                    CombinedFailureHazards[index][z] = changedFailureHazardsArray[y]

                                    y++
                                }
                            }
                        } // no idea
                    }
                } // Option
            }
        } // Pathway

        // Saves results to adaptState
        CombinedOptionsOfPathway.forEach((combinedPath, key) => {
            adaptState.path[key + 1]["newData"] = CombinedOptionsOfPathway[key].map(
                (v) => v / assetReplacementCost
            )
            adaptState.path[key + 1]["newRiskHazards"] = toJS(CombinedRiskHazards[key])
            adaptState.path[key + 1]["newFailureHazards"] = toJS(CombinedFailureHazards[key])
        })

        try {
            series[1] = {
                name: chartLabels[1],
                data: CombinedOptionsOfPathway[0],
                marker: {
                    enabled: false,
                },
            }
        } catch (e) {
            // console.log("I've not yet received all options of Pathway 1")
        }
        try {
            series[2] = {
                name: chartLabels[2],
                data: CombinedOptionsOfPathway[1],
                marker: {
                    enabled: false,
                },
            }
        } catch (e) {
            // console.log("I've not yet received all options of Pathway 2")
        }
        try {
            series[3] = {
                name: chartLabels[3],
                data: CombinedOptionsOfPathway[2],
                marker: {
                    enabled: false,
                },
            }
        } catch (e) {
            // console.log("I've not yet received all options of Pathway 3")
        }

        return (
            <div className="col-md-6 col-xl-6 col-12 col-sm-12 col-lg-6">
                <div className="chartContainer">
                    <ReactHighcharts
                        config={this.drawHighCharts({
                            title: "Technical Insurance Premiums",
                            series: series,
                        })}
                        className="VulnerabilityChart"
                    />
                </div>
            </div>
        )
    }
}

class AdaptationNPVChart extends React.Component {
    constructor(props) {
        super(props)
    }

    // Generate the NPV chart
    drawColumnChart(values) {
        let highChartsConfig = {
            chart: {
                height: "500px",
                zoomType: "xy",
                type: "column",
            },
            credits: {
                enabled: false
            },
            title: {
                text: values.title,
            },
            tooltip: {
                // This change is for removing the tooltip from the NPV Graph
                enabled: false,
            },
            xAxis: {
                title: {
                    text: "Adaptation Pathways",
                },
            },
            yAxis: {
                min: 0,
                minRange : 0.001,
                title: {
                    text: "Net Present Value (NPV)",
                },
            },
            marker: {
                enabled: false,
            },
            navigation: {
                buttonOptions: {
                    enabled: true,
                },
            },
            series: values.series,
        }
        return highChartsConfig
    }

    render() {
        const { NPVBaseline, NPVSecGraph01, NPVSecGraph02, NPVSecGraph03 } = this.props

        //Following Code is of NPV Graph
        // Fill the series data

        let seriesNPV = [
            {
                name: "Baseline",
                pointWidth: 45,
                data: [NPVBaseline],
                selected: true,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        Highcharts.setOptions({
                            lang: {
                                thousandsSep: ",",
                            },
                        })
                        return "$" + Highcharts.numberFormat(this.y, 0)
                    },
                },
            },
        ]
        // add another pathway only if its greater than 0
        if (NPVSecGraph01 > 0) {
            seriesNPV.push({
                name: "Pathway - 1",
                pointWidth: 45,
                data: [NPVSecGraph01],
                selected: true,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        Highcharts.setOptions({
                            lang: {
                                thousandsSep: ",",
                            },
                        })
                        return "$" + Highcharts.numberFormat(this.y, 0)
                    },
                },
            })
        }
        // add another pathway only if its greater than 0
        if (NPVSecGraph02 > 0) {
            seriesNPV.push({
                name: "Pathway - 2",
                pointWidth: 45,
                data: [NPVSecGraph02],
                selected: true,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        Highcharts.setOptions({
                            lang: {
                                thousandsSep: ",",
                            },
                        })
                        return "$" + Highcharts.numberFormat(this.y, 0)
                    },
                },
            })
        }
        // add another pathway only if its greater than 0
        if (NPVSecGraph03 > 0) {
            seriesNPV.push({
                name: "Pathway - 3",
                pointWidth: 45,
                data: [NPVSecGraph03],
                selected: true,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        Highcharts.setOptions({
                            lang: {
                                thousandsSep: ",",
                            },
                        })
                        return "$" + Highcharts.numberFormat(this.y, 0)
                    },
                },
            })
        }

        return (
            <div className="col-md-6 col-xl-6 col-12 col-sm-12 col-lg-6">
                <div className="chartContainer">
                    <ReactHighcharts
                        config={this.drawColumnChart({
                            title:
                                "Net Present Value of Technical Insurance Premiums " +
                                "and Capital Expenditures",
                            series: seriesNPV,
                        })}
                        className="VulnerabilityChart"
                    />
                </div>
            </div>
        )
    }
}

class AdaptHazardsChart extends React.Component {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        riskHazards: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        hazards: PropTypes.array,
        hazardLabels: PropTypes.array,
        pathway: PropTypes.string, // pathway name
        years: PropTypes.array.isRequired,
    }

    // TODO: retrieve these from scenario
    static defaultProps = {
        hazards: ["flood_riverine", "inundation", "heat", "forest_fire", "wind", "soil_movement"],
    }

    render() {
        const { riskHazards, hazards, hazardLabels, pathway, years } = this.props

        return (
            <div className="col-md-6 col-xl-6 col-12 col-sm-12 col-lg-6">
                <div className="chartContainer">
                    <p className="text-center">{pathway}</p>
                    <DrawAnnualVARBreakdown
                        riskHazards={riskHazards}
                        hazards={hazards}
                        hazardLabels={hazardLabels}
                        exportChart={true}
                        years={years}
                    />
                </div>
            </div>
        )
    }
}

class AdaptFailureChart extends React.Component {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        failureHazards: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        pathway: PropTypes.string, // pathway name
        years: PropTypes.array.isRequired,
    }

    render() {
        const { failureHazards, pathway, years } = this.props

        return (
            <div className="col-md-6 col-xl-6 col-12 col-sm-12 col-lg-6">
                <div className="chartContainer">
                    <p className="text-center">{pathway}</p>
                    <DrawAnnualFPBreakdown
                        failureHazards={failureHazards}
                        exportChart={true}
                        years={years}
                    />
                </div>
            </div>
        )
    }
}

export { AdaptationTIPChart, AdaptationNPVChart, AdaptHazardsChart, AdaptFailureChart }
