export const AREA_OPTIONS = [
    {
        id: "extrude",
        title: "Select a parameter for the height of the areas.",
        options: [
            { value: "2D", text: "--Extrude By--" },
            { value: "averageRiskCost", text: "Risk Cost (avg)" },
        ],
    },
    {
        id: "opacity",
        title: "Select the level of area opacity.",
        options: [
            { value: ".8", text: "--Opacity--" },
            { value: "1.0", text: "100%" },
            { value: ".9", text: "90%" },
            { value: ".8", text: "80%" },
            { value: ".7", text: "70%" },
            { value: ".6", text: "60%" },
            { value: ".5", text: "50%" },
            { value: ".4", text: "40%" },
            { value: ".3", text: "30%" },
            { value: ".2", text: "20%" },
            { value: ".1", text: "10%" },
        ],
    },
    {
        id: "year",
        title: "Select the Year of the results.",
        options: [
            { value: "0", text: "--Year--" },
            { value: "1", text: "2020" },
            { value: "2", text: "2030" },
            { value: "3", text: "2040" },
            { value: "4", text: "2050" },
            { value: "5", text: "2100" },
        ],
    },
    {
        id: "color",
        title: "Select a parameter for the colour of the areas.",
        options: [
            { value: "proportionalTotalRisk", text: "--Colour By--" },
            { value: "proportionalTotalRisk", text: "Annual Average Loss", title: "Annual Average Loss expressed as a percentage of the replacement costs" },
            { value: "assetsWithHighRisk", text: "Proportion at High Risk", title: "The proportion of the assets in the polygon that have an Annual Average Loss greater than 1% of their replacement cost" },
            { value: "averageRiskHazards", text: "Hazards Risk Fraction (avg)" },
            ...function () {
                const list = [];

                switch(window.user_organisation) {
                case "ces":
                    list.push({ value: "averageKPIs", text: "KPI's" });
                    // Intentional fallthrough
                case "gic":
                    list.unshift({ value: "averageFailureHazards", text: "Hazards Failure (avg)"});
                }

                return list;
            }()
        ],
        callback: function (value) {
            window.scene.viewSelector.hazardLayout(value);
        }
    },
    {
        id: "hazard",
        title: "Select the hazard to display.",
        options: [
            { text: "--Hazard--", title: "Please select a Hazard from the list." },
            { value: "0", text: "Flood" },
            { value: "1", text: "Inundation" },
            { value: "2", text: "Heat" },
            { value: "3", text: "Forest Fire" },
            { value: "4", text: "Wind" },
            { value: "5", text: "Soil" },
            ...function () {
                return window.user_locality === "ca"
                    ? [{ value: "6", text: "Freeze-Thaw" }]
                    : [];
            }()
        ]
    },
    {
        id: "kpi",
        title: "Select the KPI to display.",
        options: [
            { text: "--KPI--", title: "Please select a KPI from the list." },
            { value: "0", text: "Heat Stress" },
        ]
    },
];

export const BUTTON_LIST = [
    {
        icon: "exit_to_app",
        title: "Logout",
        callback: () => {
            window.location.href = "/logout";
        }
    },
    {
        icon: "person",
        title: "Profile",
        callback: () => {
            window.location.href = "https://account.xdi.systems/profile";
        }
    },
    {
        icon: "help",
        title: "Instructions",
        callback: () => {
            window.open('https://training.climaterisk.com.au/','_blank');
        }
    },
    {
        icon: "feedback",
        title: "Send feedback",
        callback: () => {
            window.$('#toolbar-message').toggle('300');
        }
    }
];

export const SELECTORS = [
    {
        buttonTitle: "View asset list",
        selectId: "collection-point",
        selectText: "Asset View",
        selectTitle: "Select one or more Asset Classes to view individual asset results.",
        options: window.collections.Point.map((point) => point.collection),
    },
    {
        buttonTitle: "View area view list",
        selectId: "collection-polygon",
        selectText: "Area View",
        selectTitle: "Select the Asset Class to view its collated results.",
        options: window.collections.Polygon.map((polygon) => polygon.collection),
    }
];