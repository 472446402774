const hazard_labels_map = new Map()
hazard_labels_map.set("flood_riverine", "Flood (Riverine)")
hazard_labels_map.set("inundation", "Coastal Inundation")
hazard_labels_map.set("heat", "Heat")
hazard_labels_map.set("forest_fire", "Forest Fire")
hazard_labels_map.set("wind", "Wind Damage")
hazard_labels_map.set("soil_movement", "Soil Movement")
hazard_labels_map.set("freeze-thaw", "Freeze-Thaw")
hazard_labels_map.set("flood_surfacewater", "Flood (Surface Water)")
hazard_labels_map.set("cyclone_wind", "Tropical Cyclone Wind")

export const hazard_labels = Object.fromEntries(hazard_labels_map.entries())
export const hazard_labels_array = Array.from(hazard_labels_map.values())


export const hazard_colours = new Map()
hazard_colours.set("flood_riverine", "#7cb5ec")
hazard_colours.set("inundation", "#434348")
hazard_colours.set("heat", "#90ed7d")
hazard_colours.set("forest_fire", "#f7a35c")
hazard_colours.set("wind", "#8085e9")
hazard_colours.set("soil_movement", "#f15c80")
hazard_colours.set("freeze-thaw", "#e4d354")
hazard_colours.set("flood_surfacewater", "#2b908f")
hazard_colours.set("cyclone_wind", "#1d4ed8")

export const hazard_colours_array = Array.from(hazard_colours.values())


export const hazard_icons = {
  flood_riverine: "opacity",
  inundation: "waves",
  heat: "whatshot",
  forest_fire: "wb_sunny",
  wind: "air",
  soil_movement: "broken_image",
  "freeze-thaw": "ac_unit",
  flood_surfacewater: "pool",
  cyclone_wind: "cyclone",
}