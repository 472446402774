import {UISettings} from "./conf";
import {scene, removeLayers} from "./scene";

const dataLoader = {
    status: UISettings.AUTOLOAD_DATA,
    get: function () {
        if (!this.dom) {
            this.dom = document.getElementById("autoload-data");
        }
        return this.dom;
    },
    getLoader: function () {
        if (!this.domLoader) {
            this.domLoader = $("#loader-small");
        }
        return this.domLoader;
    },
    onLoad: function () {
        if (this.status) {
            this.status = false;
            this.get().classList.remove("buttonActive");
            infoBox.show("Autoload has been disabled.");
        } else {
            this.status = true;
            this.get().classList.add("buttonActive");
            scene.onCameraMoved();
            infoBox.hide();
        }
    },
    show: function () {
        this.getLoader().fadeIn(500);
    },
    hide: function () {
        this.getLoader().fadeOut(500);
    }
};

window.dataLoader = dataLoader;

const viewSelector = {
    viewType: "",
    getContainer: function () {
        if (!this.domContainer) {
            this.domContainer = document.getElementById("toolbar-area-options");
        }
        return this.domContainer;
    },
    getViewType: function () {
        return this.viewType;
    },
    getYearSelector: function () {
        if (!this.domYearSelector) {
            this.domYearSelector = document.getElementById("parameter-year");
        }
        return this.domYearSelector;
    },
    getYearSelectorValue: function () {
        return parseInt(this.getYearSelector().value);
    },
    getHazardSelector: function () {
        if (!this.domHazardSelector) {
            this.domHazardSelector = document.getElementById("parameter-hazard");
        }
        return this.domHazardSelector;
    },
    getHazardSelectorValue: function () {
        return parseInt(this.getHazardSelector().value);
    },
    getKPISelector: function () {
        if (!this.domKPISelector) {
            this.domKPISelector = document.getElementById("parameter-kpi");
        }
        return this.domKPISelector;
    },
    getKPISelectorValue: function () {
        return parseInt(this.getKPISelector().value);
    },
    getCollection: function () {
        if (this.viewType === "Point") {
            this.domCollectionSelPoint = document.getElementById("collection-point");
            return this.domCollectionSelPoint;
        }

        this.domCollectionSelPolygon = document.getElementById("collection-polygon");
        return this.domCollectionSelPolygon;
    },
    getCollectionList: function () {
        const result = [];
        const options = this.getCollection() ? this.getCollection().options : [];
        for (let i = 0, iLen = options.length; i < iLen; i++) {
            if (options[i].selected) {
                result.push(options[i].value || options[i].text);
            }
        }
        return result;
    },
    getColorValue: function () {
        if (!this.domColor) {
            this.domColor = document.getElementById("parameter-color");
        }
        return this.domColor.value;
    },
    getExtrudeValue: function () {
        if (!this.domExtrude) {
            this.domExtrude = document.getElementById("parameter-extrude");
        }
        return this.domExtrude.value;
    },
    getOpacityValue: function () {
        if (!this.domOpacity) {
            this.domOpacity = document.getElementById("parameter-opacity");
        }
        return parseFloat(this.domOpacity.value);
    },
    hazardLayout: function (value) {
        if (value === "averageRiskHazards") {
            this.getHazardSelector().style.display = "block";
        } else if (value === "averageFailureHazards") {
            this.getHazardSelector().style.display = "block";
        } else {
            this.getHazardSelector().style.display = "none";
        }
        if (value === "averageKPIs") {
            this.getKPISelector().style.display = "block";
        } else {
            this.getKPISelector().style.display = "none";
        }
    },
    onSelect: function (viewOpt) {
        if (viewOpt === "asset") {
            document.getElementById("collection-point").style.display = 'block';
            document.getElementById("collection-polygon").style.display = 'none';
            // document.getElementById("area-view").disabled = false;
            // document.getElementById("asset-view").disabled = true;
        } else {
            document.getElementById("collection-point").style.display = 'none';
            document.getElementById("collection-polygon").style.display = 'block';
            // document.getElementById("area-view").disabled = true;
            // document.getElementById("asset-view").disabled = false;
        }
    },
    onChange: function (type, assets, zoom) {
        viewSelector.viewType = type;
        removeLayers()
        if (type === "Point") {
            scene.cameraHeightThreshold = UISettings.CAMERA_THRESHOLD;
            viewSelector.getContainer().style.display = 'none';

            if (assets && zoom) {
                scene.flyToExtents(assets);
            }
        } else {
            scene.cameraHeightThreshold = UISettings.CAMERA_THRESHOLD_AGGREGATE;
            viewSelector.getContainer().style.display = 'block';
            // document.getElementById("collection-polygon").style.display = 'none';
            // assetSelect = document.getElementById("collection-point")
            // assetSelect.style.display = "none";
        }
        scene.onCameraMoved();
    }
};

const infoBox = {
// Test if this is working (JL 28th March)
    get: function () {
        if (!this.dom) {
            this.dom = $('#toolbar-info-alert');
        }
        return this.dom;
    },
    show: function (message) {
        this.get().html(message);
        this.get().fadeIn(500);
    },
    hide: function () {
        this.get().fadeOut(500);
    }
};

export {dataLoader, viewSelector, infoBox};
