import React from "react"
import { observer } from "mobx-react"
import PropTypes from "prop-types";
import ReactGA from 'react-ga'

import { archetypeNameString } from './DependencyUtils.jsx'
import Map from "ol/Map"
import View from "ol/View"
import Feature from "ol/Feature"
import { fromLonLat, transform } from "ol/proj"
import GeoJSON from "ol/format/GeoJSON"
import LineString from "ol/geom/LineString"
import Point from "ol/geom/Point"
import Overlay from "ol/Overlay"
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer"
import { OSM, Vector as VectorSource } from "ol/source"
import { Stroke, Style, Icon } from "ol/style"

import { getRGB } from "../../scene.js"

// import "ol/ol.css"
import "./DependencyMap.scss"

let line = {} // make line global so it can be deleted from map
let map = {} // Make map var global
let vector = {} // make marker layer global for clicking

/**
 * Sets the style and color of marker pins. Color relevant to risk
 *
 * @param {object} feature - GeoJSON asset object
 * @returns {object} - Returns ol Style object
 */
const setStyle = function (feature) {
    const totalRisk = feature.get("totalRisk")
    let color = "blue"

    if (totalRisk) {
        color = getRGB(totalRisk)
    }

    const retStyle = new Style({
        image: new Icon({
            color: color,
            anchor: [0.5, 0.9],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            src: "/static/images/place_lg.png",
        }),
    })
    return retStyle
}

/**
 * 
 * Sets line colors and adds arrow to end
 * 
 * @param {object} feature - object for style is applied to
 * @returns {object} - style object
 */
const styleFunction = function (feature) {
    const geometry = feature.getGeometry()
    const styles = [
        // linestring
        new Style({
            stroke: new Stroke({
                color: "rgba(64, 64, 64,0.7)",
                width: 2,
            }),
        }),
    ]

    geometry.forEachSegment(function (start, end) {
        const dx = end[0] - start[0]
        const dy = end[1] - start[1]
        let rotation = Math.atan2(dy, dx)
        // arrows
        styles.push(
            new Style({
                geometry: new Point(end),
                image: new Icon({
                    color: "rgba(64, 64, 64,0.7)",
                    src: "/static/images/arrow.png",
                    anchor: [0.75, 0.5],
                    rotateWithView: true,
                    rotation: -rotation,
                }),
            })
        )
    })

    return styles
}

@observer
class DependencyMap extends React.Component {

    static propTypes = {
        analysis: PropTypes.object,
        dependencies: PropTypes.array
    }
    constructor(props) {
        super(props)

    }

    componentDidUpdate() {
        this.renderMaps()
        this.drawDependencyVectors()
        this.drawMarkers()
        this.addPopup()
    }

    componentDidMount() {
        this.renderMaps()
        this.drawDependencyVectors()
        this.drawMarkers()
        this.addPopup()
        if (crConstants.production) ReactGA.modalview("/analysis-result/dependency_map-tab")
    }

    componentWillUnmount() {
        let element = document.getElementById("popup")
        $(element).popover("dispose")
    }

    /**
     * 
     * Takes analysis coordinates and dependency coordinates and converts
     * to GeoJSON for use in OpenLayers
     * 
     * @returns {object} GeoJSON object with point data
     * 
     */
    getPointData() {
        const { coordinates } = this.props.analysis.geometry
        const { analysis, dependencies } = this.props
        const { archetype } = this.props.analysis.properties

        //start of GeoJSON creation
        let geoData = { "type": "FeatureCollection", "features": [] }

        // Add dependent assets
        if (dependencies) {
            dependencies.forEach(function (child) {
                let distance = true
                if (child["metadata"]) {
                    distance = (child.metadata.total_distance / 1000) < 25
                }

                if (child["geometry"] && distance) {
                    geoData.features.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": child["geometry"]["coordinates"],
                        },
                        "properties": {
                            "ID": child["_id"],
                            "Archetype": child["properties"]["archetype"],
                            "totalRisk": child["properties"]["totalRisk"]["2050"],
                        },
                    })
                }
            })
        }

        // Parent asset, add last so its on top
        geoData["features"].push({
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": coordinates,
            },
            "properties": {
                "ID": analysis["_id"],
                "Archetype": archetype,
            },
        })


        return geoData
    }

    /**
     * Draws vectors from parent asset to dependencies
     * 
     */
    drawDependencyVectors() {
        const { coordinates } = this.props.analysis.geometry
        const { dependencies } = this.props

        let lineIncrement = 0


        dependencies.forEach((child) => {
            let distance = true
            if (child["metadata"]) {
                distance = (child.metadata.total_distance / 1000) < 25
            }
            if (child["geometry"] && distance) {
                line[lineIncrement] = new VectorLayer({
                    source: new VectorSource({
                        features: [
                            new Feature({
                                geometry: new LineString([
                                    fromLonLat(coordinates),
                                    fromLonLat(child["geometry"]["coordinates"]),
                                ]),
                            }),
                        ],
                    }),
                    style: styleFunction,
                })

                // line[lineIncrement].setStyle(lineStyle) //set style of line
                map.addLayer(line[lineIncrement]) // add layer to map

                lineIncrement++ //increment layer to next number
            } else {
                console.warn("FIXME: Dependencies missing geometry data")
            }
        })

    }

    /**
     * Draws on markers only map, adds "vector" layer
     * This should happen last so the markers are "on top"
     */
    drawMarkers() {
        let source = new VectorSource({
            features: new GeoJSON().readFeatures(this.getPointData(), {
                featureProjection: "EPSG:3857",
            }),
        })

        vector = new VectorLayer({
            source: source,
            style: setStyle,
        })

        map.addLayer(vector)
    }

    /**
     * Adds popover/tooltip overlay to map
     * Clicking on markers will display tooltip
     */
    addPopup() {
        let element = document.getElementById("popup")

        let popup = new Overlay({
            element: element,
            stopEvent: false,
        })

        map.addOverlay(popup)

        map.on("pointermove", function (evt) {
            let feature = map.forEachFeatureAtPixel(evt.pixel, function (ft) {
                return ft;
            })

            if (feature) {
                if (feature.get("Archetype")) {
                    $(element).popover("dispose")
                    let geometry = feature.getGeometry()
                    let coord = geometry.getCoordinates()
                    let lonLat = transform(coord, 'EPSG:3857', 'EPSG:4326');
                    popup.setPosition(coord)
                    console.info(feature.getProperties())
                    element.title = archetypeNameString(feature.get("Archetype"))
                    $(element).popover({
                        "placement": "top",
                        "html": true,
                        "content": `<p>ID: ${feature.get("ID")}</p>
                            <p>${lonLat[0]}, ${lonLat[1]}</p>`,
                    })
                    $(element).popover("show")
                } else {
                    $(element).popover("dispose")
                }
            } else {
                $(element).popover("dispose")
            }
        })

        map.on("pointermove", function (evt) {
            let hit = this.forEachFeatureAtPixel(evt.pixel, function () {
                return true
            })
            if (hit) {
                this.getTargetElement().style.cursor = "pointer"
            } else {
                this.getTargetElement().style.cursor = ""
            }
        })
    }

    /**
     * Renders OpenLayers map
     */
    renderMaps() {
        const { coordinates } = this.props.analysis.geometry

        let raster = new TileLayer({
            source: new OSM(),
        })

        map = new Map({
            layers: [
                raster,
                // vector,
                // new TileLayer({
                //     source: new OSM(),
                // }),
            ],
            target: "map",
            view: new View({
                center: fromLonLat(coordinates),
                zoom: 15,
            }),
        })
    }

    render() {
        return (
            <React.Fragment>
                <div id="map">
                    <div id="popup"></div>
                </div>
            </React.Fragment>
        )
    }
}

export default DependencyMap
