import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";

export const IncorrectOrganisationModal = ({ expected, given, onHide}) => {
    return <>
        <Modal show={true} id="modalIncorrectOrganisation" onHide={onHide}>
            <Modal.Header closeButton>
                Incorrect Organisation
            </Modal.Header>
            <Modal.Body>
                <p>
                    You have attempted to open an individual asset. This has failed because the organisation setting on your account does not match the organisation the asset belongs to.
                </p>
                <ul>
                    <li>
                        <b>Current organisation: </b>{ given.toUpperCase() }
                    </li>
                    <li>
                        <b>Expected organisation: </b>{ expected.toUpperCase() }
                    </li>
                </ul>
                <p>
                    Please <a href={ `${crConstants.accountHost}/profile` }>go to your Account Settings</a> and set your organisation to &quot;{ expected.toUpperCase() }&quot;, or contact the administrator.
                </p>
            </Modal.Body>
        </Modal>
    </>;
}
IncorrectOrganisationModal.propTypes = {
    expected: PropTypes.string.isRequired,
    given: PropTypes.string.isRequired,
}