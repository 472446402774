import React, { useEffect } from "react";
import {observer} from "mobx-react"
import Highcharts from 'highcharts';
import ReactHighcharts from 'react-highcharts';
import ReactGA from 'react-ga'

import { checkNested } from '../../CR-Components/utils';

// TODO: change to functional component
@observer
class KPIChart extends React.Component {
    kpis = {
        kpiHeatStress: "Heat Stress",
        kpiSeniors: "Seniors",
        kpiChild: "Children",
        kpiPhysicallyDisabled: "Physically Disabled",
        kpiMentallyDisabled: "Mentally Disabled",
        kpiResidentialCustomerImpact: "Residential Customers",
        kpiCommercialCustomerImpact: "Commercial Customers",
        kpiIndustrialCustomerImpact: "Industrial Customers",
        kpiCriticalCustomerImpact: "Critical Customers",
        kpiOtherCustomerImpact: "Other Customers",
    };

    componentDidMount() {
        if (crConstants.production) ReactGA.modalview("/analysis-result/kpi-tab")
    }

    getSeries(aprop) {
        const series = [];
        for (var key in this.kpis) {
            if (aprop[key]) {
                series.push({
                    name: this.kpis[key],
                    data: aprop[key],
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        pointFormatter: function () {
                            return "<b>" + this.series.name + "</b>: " + Highcharts.numberFormat(this.y, 2, ".") + "<br/>";
                        }
                    }
                });
            }
        }

        return series;
    }

    chart_config() {
        // TODO: Move this graph into CR-components, and only pass it the props it needs.
        const {analysis} = this.props;
        const aprop = analysis.properties;

        let startYear = 2020;
        if (checkNested(analysis, "inputs", "scenario", "years")) {
            startYear = +analysis.inputs.scenario.years.split(",")[0];
        } else if (checkNested(analysis, "inputs", "scenario", "startYear")) {
            startYear = analysis.inputs.scenario.startYear
        } else if (checkNested(analysis, "scenario", "startYear")) {
            console.log("Analysis scenario is in the wrong place. Needs update!")
            startYear = analysis.scenario.startYear
        } else {
            console.warn("Start year not available! guessing 2020")
        }


        var series = this.getSeries(aprop);
        if (series.length > 0) {
            return ({
                title: {
                    text: 'Key Performance Indicator'
                },
                xAxis: {
                    title: {
                        text: 'Year'
                    },
                },
                yAxis: {
                    title: {
                        text: 'People Affected'
                    },
                    minRange: 0.0002,
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
                tooltip: {
                    shared: true
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false
                        },
                        // TODO: This will move to analysis.input.scenario
                        pointStart: startYear,
                    }
                },
                series: series,
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }
            });
        }
    }

    render() {
        const config = this.chart_config();

        if (config == undefined) {
            return(
                <div id="kpi-chart">No KPI information included in this analysis.</div>
            )
        } else {
            return(
                <ReactHighcharts config={config} id="kpi-chart" />
            );
        }
    }
}


export {KPIChart};
