import appState from './CR-Components/CoreFiles/state';
import { munge_analysis } from './CR-Components/analyses';

// TODO: Get rid of this function and replace with jQuery AJAX calls everywhere.
// We don't need async calls yet.
function loadJSON(url, method, key, callback, data, ...callback_args) {
    try {
        const http = new XMLHttpRequest();
        http.open(method, url, true);
        http.setRequestHeader("Content-Type", "application/json");
        http.setRequestHeader("Authorization", key);
        http.onreadystatechange = function () {
            if (http.readyState == 4) {
                callback(JSON.parse(http.responseText), ...callback_args);
            };

        };
        http.send(data);
    } catch(e) {
        console.log("No data in response from ", url)
        return null
    }
};


/**
 * This function is supposed to send a request to the owner of a dependency, to
 * request permission. It doesn't work yet.
 */
async function Submit(orgId, user_email, user_organisation, assetIndex) {
    const mailButton = document.getElementById(`contact-owner-${assetIndex}`);
    mailButton.innerHTML = 'Request Sent!';
    mailButton.className = "btn btn-sm btn-outline-secondary text-center disabled";


    try {
        console.log('Email sent');
        const data = {
            user_email,
            user_organisation,
            label: 'Request Permision\n/label request-permission',
        };

        const response = await jQuery.ajax({
            method: 'POST',
            url: `${AUTH_HOST}/v1/request`,
            headers: {
                owningOrgId: orgId,
            },
            data: {
                data: JSON.stringify(data, null, 2),
                mail: JSON.stringify({
                    app: "globe",
                    template: "request",
                    labels:"general"
                }),
            },
            dataType: 'json',
        });
    }
    catch (e) {
        const err = e.responseJSON && e.responseJSON.error && e.responseJSON.error.message || e.message  || e.statusText || ""+e;
        console.log('error', err);
    }
}


// TODO: Get rid of this.
const requestHelper = {
    getRepoDatasetsURL: function (collection, id) {
        return REPO_HOST + "/v1/datasets/" + collection + "/" + id;
    },

    getRepoGeometryURL: function () {
        return REPO_HOST + "/v1/geometry/";
    },

    // Gets the URL for the extents API, either for a whole group check, or for an asset set.
    // The first call is not chached, so might tkae a while.
    getRepoExtentsURL: function (set) {
        if (set) {
            return REPO_HOST + "/v1/extents?set=" + set;
        } else {
            return REPO_HOST + "/v1/extents";
        }
    },

    getAuthOrgPermURL: function (id) {
        return AUTH_HOST + "/v1/account/organisations/" + id + "/";
    },

    getOrgProjectURL: function (id) {
        return AUTH_HOST + "/v1/organisation/project/" + id + "/";
    }
};


/* Functions to retrieve UI colours/text from risk values */
function getTermForRisk(value, threshold_low=0.002, threshold_high=0.01) {
    if (value > threshold_high) {
        return "HIGH";
    } else if (value > threshold_low) {
        return "MODERATE";
    } else {
        return "LOW";
    }
}

/**
 * Return color representing the VAR%
 *
 * @param {number} value - VAR%
 * @param {number} threshold_low - low end of orange color scale
 * @param {number} threshold_high - high end of orange color scale
 *
 * @returns {string} - name of color
 */
function getColorFromRisk(value, threshold_low=0.002, threshold_high=0.01) {
    if (typeof value != 'number') {
        // light grey, for missing values
        return "#BDBDBD";
    }
    if (value > threshold_high) {
        return "red";
    } else if (value > threshold_low) {
        return "orange";
    } else {
        return "green";
    }
}

/**
 * Return color representing the Failure Probability %
 *
 * @param {number} value - Failure Probability %
 * @param {number} threshold_low - low end of orange color scale
 * @param {number} threshold_high - high end of orange color scale
 *
 * @returns {string} - name of color
 */
function getColorFromFail(value, threshold_low=0.75, threshold_high=3) {
    return getColorFromRisk(value, threshold_low, threshold_high);
}

/**
 * Return red if true
 *
 * @param {boolean} value - Bool
 *
 * @returns {string} - "red" if true, else "green"
 */
function getColorFromBool(value) {
    if (value) {
        return "red";
    }
    return "green";
}

function formatMoney(val) {
    // TODO: replace with Intl.NumberFormat? currently ~95% browser support.
    return(Number(val.toFixed(2)).toLocaleString())
}

function formatValue(val, currency) {
    if (typeof(val) == "number" && currency) {
        return(formatMoney(val));
    } else if (val) {
        return(val);
    } else {
        return("N/A");
    }
}

async function openAnalysisModal(id, source = "asset-click") {
    let response;
    window.dataLoader.show();
    // Create the modal from the selected asset and depedencies
    await $.post({
        url: `${REPO_HOST}/v1/analysis/`,
        data: JSON.stringify({
            "_id": id,
            "sections": ["all"],
            "dependencies": {
                result_years: [2050]
            }}),
        headers: {
            "Content-Type": "application/json",
            "Authorization": API_KEY},
        dataType: 'json',
        async: true,
        success: (data) => {
            response = data

            const analysis = munge_analysis(data[0]);
            appState.current_dependencies = analysis.dependencies;
            appState.current_analysis = analysis;
            appState.original_analysis = analysis;
            appState.current_analysis_source = source;
        },
        error: (data) => {
            console.log("failed to get data: ", data)
        }
    }).promise();

    window.dataLoader.hide();

    appState.showResultModal = true;

    return response;
}


export {
    loadJSON, Submit, requestHelper,
    getTermForRisk, getColorFromRisk, getColorFromFail, getColorFromBool,
    formatValue, formatMoney, openAnalysisModal}
