import React from 'react'
import { toJS } from 'mobx'

import appState from '../../CR-Components/CoreFiles/state.js'

/**
 * Get archetype path names
 * 
 * @param {string} archetype - name of archetype to reverse search
 * 
 * @returns {object} - react span including names
 */
const archetypeName = (archetype) => {
    const archetypePaths = toJS(appState.archetypePaths)
    if (archetype in archetypePaths) {
        let keyNum = 0
        return archetypePaths[archetype].map((name, index) => {
            keyNum++
            return (
                <span key={`${name}-${keyNum}`} style={{ color: "grey" }}>
                    {name}
                    {index != archetypePaths[archetype].length - 1 ? ": " : ""}
                </span>
            )
        })
    }
}

/**
 * Get archetype path names
 * 
 * @param {string} archetype - name of archetype to reverse search
 * 
 * @returns {string} - string including names and archetype
 */
const archetypeNameString = (archetype) => {
    const archetypePaths = toJS(appState.archetypePaths)

    if (archetype in archetypePaths) {
        let fullPath = [...archetypePaths[archetype]]
        fullPath.push(archetype)
        return fullPath.join(": ")
    }

    return archetype
}

export { archetypeName, archetypeNameString }