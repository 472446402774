import PropTypes from "prop-types";
import React, { useEffect } from "react";
import ReactGA from "react-ga";

import DrawVulnerabilityCharts from "../../CR-Components/Charts/Vulnerability-Charts.jsx";

// import "./AnalysisResult.scss";

const Vulnerability = (props) => {
    let { analysis, purchased = true } = props

    useEffect(() => {
        if (crConstants.production) ReactGA.modalview("/analysis-result/vulnerability-tab")
    }, [])


    if (!analysis.properties.riskHazardsElements) {
        return <div>No vulnerability information included in this analysis</div>
    }

    let startYear = analysis.inputs.scenario.startYear
    let endYear = analysis.inputs.scenario.endYear
    let hazards = analysis.inputs.scenario.hazards

    const yearsFn = (years) => Array.isArray(years) ? years : years.split(",").map(i => +i);
    if (!startYear && analysis.inputs.scenario.years) {
        const years = yearsFn(analysis.inputs.scenario.years);
        startYear = years[0];
        endYear = years[years.length - 1];
    }

    let middleYear = startYear + 60

    if (!purchased) {
        // TODO: Replace results with demo values
        // (don't change the analysis object provided in props!)
        let len = analysis.properties.riskHazardsElements[0][0].length
        analysis = {
            ...analysis,
            properties: {
                ...analysis.properties,
                riskHazardsElements: [],
            },
        }
        for (let yi = 0; yi <= endYear - startYear; yi++) {
            let hazVals = []
            for (let hi = 0; hi < hazards.length; hi++) {
                const yearVals = []
                for (let ei = 0; ei < len; ei++) {
                    yearVals.push(Math.pow(0.05 + hi * 0.002 + ei * 0.02 + yi / 100, 2) / 1000)
                }
                hazVals.push(yearVals)
            }

            analysis.properties.riskHazardsElements.push(hazVals)
        }
    }

    return (
        <React.Fragment>
            <div className="tab-content">
                <header>
                    <h2>Element Vulnerability</h2>
                </header>
                <p>
                    This section helps to understand which material elements of the asset archetype
                    are failing and why. The graphs show the relative impacts of each hazard on each
                    of the construction elements in the archetype for three time periods.
                </p>

                <p>
                    The Y Axis lists all of the construction elements analysed for hazard impacts.
                    The X Axis quantifies each hazard{"'"}s contribution to the total impacts on
                    each of the elements.
                </p>

                <p>
                    Diagnosing vulnerability helps decision makers to fine tune maintenance,
                    schedule upgrades, or plan for adaptation.
                </p>

                <p>
                    <strong>Note:</strong> X Axes change for different time periods due to the
                    visualisation requirements. Refer carefully to values on X axis when comparing
                    time periods.{" "}
                </p>
            </div>

            <div className="chartContainer">
                {!purchased && <div className="demo-hazard-indicator">Example only</div>}

                <DrawVulnerabilityCharts
                    analysis={analysis}
                    year={startYear}
                />
            </div>

            <div className="chartContainer">
                {!purchased && <div className="demo-hazard-indicator">Example only</div>}

                <DrawVulnerabilityCharts
                    analysis={analysis}
                    year={middleYear}
                />
            </div>

            <div className="chartContainer">
                {!purchased && <div className="demo-hazard-indicator">Example only</div>}

                <DrawVulnerabilityCharts
                    analysis={analysis}
                    year={endYear}
                />
            </div>
        </React.Fragment>
    )
}
Vulnerability.propTypes = {
    analysis: PropTypes.object,
    purchased: PropTypes.bool,
}

export default Vulnerability
