/**
* Author: Francesk Xhaferri
*/

var UISettings = {
    FULLSCREEN_BUTTON: true,
    ANIMATIONS: false,
    VR_BUTTON: false,
    TIMELINE: false,
    SHADOWS: false,
    INFO_BOX: false,
    CREDIT_CONTAINER: "credits",
    FOG_DENSITY: 1.0,
    FOG_ERROR_FACTOR: 10.0,
    FOG_STATUS: false,
    CAMERA_THRESHOLD: 1000.0, // value in km
    CAMERA_THRESHOLD_AGGREGATE: 8000.0, // value in km
    CAMERA_PITCH_THRESHOLD: -0.5,
    MAX_3D_ENTITIES: 700,
    THRESHOLD_3D_ENTITIES: 0.0,  // cameraheight in km
    AUTOLOAD_DATA: true,
    CLUSTERING: true,
    SCENE_MODE_PICKER: false,
    STREET_VIEW_DELAY: 500, // delay in ms
};

export {UISettings};
