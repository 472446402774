import React, { useEffect } from "react"
import PropTypes from "prop-types"
import ReactGA from 'react-ga'

import { showHazard } from "../../CR-Components/utils.js"
import { getColorFromRisk, getColorFromFail } from "../../utils"
import { archetypeName } from './DependencyUtils.jsx'

import "./DependencyTab.scss"

const DependencyTab = (props) => {
    const { dependencies } = props

    useEffect(() => {
        if (crConstants.production) ReactGA.modalview("/analysis-result/dependency-tab")
    }, [])

    let main
    if (dependencies.length == 0) {
        main = (
            <center>
                <i className="material-icons">error</i>
                No dependency information available for this asset.
            </center>
        )
    } else {
        let keyNum = 0
        main = dependencies.map((d, i) => {
            let distance = 0
            // keyNum fixes duplicate keys caused by "unknown"
            keyNum++
            if (d.metadata) {
                distance = (d.metadata.total_distance / 1000).toFixed(1)
            }

            if ("error" in d || distance > 25) {
                return <DependencyErrorBlock analysis={d} key={`${i}-${keyNum}`} distance={true} />
            } else {
                return <DependencyBlock analysis={d} key={`${i}-${keyNum}`} distance={distance} />
            }
        })
    }

    return (
        <div id="dependency-tab" className="row">
            <div id="description-column" className="body col-4">
                <p id="description">
                    These assets have been identified as assets that you may be dependent on. If our
                    analysis has identified any risk it will be displayed under the asset title.
                </p>

                <div className="alert alert-secondary">
                    <h4>Hazard Risk Levels</h4>

                    <p className="legend-item">
                        <span className="color green">Green: </span>
                        Indicates there is Low risk (Less than 0.2% Risk Fraction)
                    </p>

                    <p className="legend-item">
                        <span className="color orange">Orange: </span>
                        Indicates there is a Moderate level of risk. (0.2% to 1% Risk Fraction)
                    </p>

                    <p className="legend-item">
                        <span className="color red">Red: </span>
                        Indicates there is a High level of risk. (Risk Fraction greater than 1%)
                    </p>

                    <p className="legend-item">
                        <span className="color black">Grey: </span>
                        Asset owner must grant permission to view these results.
                    </p>
                </div>
            </div>
            <div className="container col-8 text-center">{main}</div>
        </div>
    )
}
DependencyTab.propTypes = {
    dependencies: PropTypes.array,
}

const DependencyBlock = (props) => {
    const { analysis, distance } = props
    const risk = analysis.properties.riskHazards[2050]
    const failure = analysis.properties.failureHazards[2050].map(
        (failureHazard) => failureHazard * 100
    )
    const archetype = analysis.properties.archetype

    // TODO: should include hazards directly in dependency. is it possible for a discrepancy?
    const freezeThawIndex = showHazard(analysis, "freeze-thaw")
    const surfWaterIndex = showHazard(analysis, "flood_surfacewater")

    const drawIcons = (values, colorFunc) => (
        <div className="risk-hazard-symbols">
            <div className="icon-box card">
                <i
                    className="material-icons card-img col-sm-2"
                    title="Flood"
                    style={{ color: colorFunc(values[0]) }}>
                    opacity
                </i>
            </div>
            <div className="icon-box card">
                <i
                    className="material-icons card-img col-sm-2"
                    title="Inundation"
                    style={{ color: colorFunc(values[1]) }}>
                    waves
                </i>
            </div>
            <div className="icon-box card">
                <i
                    className="material-icons card-img col-sm-2"
                    title="Heat"
                    style={{ color: colorFunc(values[2]) }}>
                    wb_sunny
                </i>
            </div>
            <div className="icon-box card">
                <i
                    className="material-icons card-img col-sm-2"
                    title="Fire"
                    style={{ color: colorFunc(values[3]) }}>
                    whatshot
                </i>
            </div>
            <div className="icon-box card">
                <i
                    className="material-icons card-img col-sm-2"
                    title="Wind"
                    style={{ color: colorFunc(values[4]) }}>
                    air
                </i>
            </div>
            <div className="icon-box card">
                <i
                    className="material-icons card-img col-sm-2"
                    title="Soil Cracking"
                    style={{ color: colorFunc(values[5]) }}>
                    broken_image
                </i>
            </div>
            {freezeThawIndex && (
                <div className="icon-box card">
                    <i
                        className="material-icons card-img col-sm-2"
                        id="Freeze Thaw"
                        style={{ color: colorFunc(values[freezeThawIndex]) }}>
                        ac_unit
                    </i>
                </div>
            )}

            {surfWaterIndex && (
                <div className="icon-box card">
                    <i
                        className="material-icons card-img col-sm-2"
                        id="Surface Water"
                        style={{ color: colorFunc(values[surfWaterIndex]) }}>
                        pool
                    </i>
                </div>
            )}
        </div>
    )

    // TODO: Add collection to archetype name?
    return (
        <div className="dependency-block">
            <div className="asset-id alert alert-secondary text-center">
                {archetypeName(archetype)}
                <h3>{archetype}</h3>
                {!!distance && <div>Total Distance: {distance}km</div>}
            </div>
            <div className="av-risk-frac" style={{ fontSize: "1.25rem" }}>
                Average Risk Fraction: {(analysis.properties.totalRisk[2050] * 100).toFixed(2)}% in
                2050
            </div>
            Risk Fraction by Hazard in 2050:
            {drawIcons(risk, getColorFromRisk)}
            Failure Risk by Hazard in 2050:
            {drawIcons(failure, getColorFromFail)}
        </div>
    )
}
DependencyBlock.propTypes = {
    analysis: PropTypes.object,
    distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
DependencyBlock.defaultProps = {
    distance: 0,
}

const DependencyErrorBlock = (props) => {
    const { analysis, distance } = props
    let { error } = analysis

    let archetype = "Unknown"
    if (!error && analysis.properties) {
        if (analysis.properties.archetype != undefined) {
            archetype = analysis.properties.archetype
        }
    }

    if (distance) {
        error = "No dependency found within suitable distance"
    }

    let request_button
    if (analysis.owner != undefined) {
        // TODO: We need to finish implementing the request API in AUTH.
        // Until then, this doesn't work. We also need the owner from Repo.
        // Can't test this yet, this is probably all kinds of broken.
        const submit_call = `Submit('${analysis.owner}', '${user_email}', '${user_organisation}', '${analysis._id}');`
        request_button = (
            <a
                id="contact-owner-{{asset._id}}"
                className="btn-sm btn-primary text-center"
                onMouseOver=""
                style="cursor: pointer;"
                onClick="{{submit_call}}">
                Request more info
            </a>
        )
    } else {
        request_button = (
            <a id="contact-owner" className="btn btn-sm btn-outline-secondary text-center disabled">
                Request more info
            </a>
        )
    }

    return (
        <div className="dependency-block">
            
            <div className="asset-id alert alert-secondary text-center">
                {archetypeName(archetype)}
                <h3>{archetype}</h3>
            </div>
            <div className="average-risk-fraction">Average Risk Fraction: Not Available</div>
            <div className="risk-hazard-symbls">
                {/* TODO: update this to include all available hazards? */}
                <i
                    className="material-icons card-img col-sm-2"
                    title="Flood"
                    style={{ color: getColorFromRisk() }}>
                    opacity
                </i>
                <i
                    className="material-icons card-img col-sm-2"
                    title="Inundation"
                    style={{ color: getColorFromRisk() }}>
                    waves
                </i>
                <i
                    className="material-icons card-img col-sm-2"
                    title="Heat"
                    style={{ color: getColorFromRisk() }}>
                    wb_sunny
                </i>
                <i
                    className="material-icons card-img col-sm-2"
                    title="Fire"
                    style={{ color: getColorFromRisk() }}>
                    whatshot
                </i>
                <i
                    className="material-icons card-img col-sm-2"
                    title="Wind"
                    style={{ color: getColorFromRisk() }}>
                    air
                </i>
                <i
                    className="material-icons card-img col-sm-2"
                    title="Soil Cracking"
                    style={{ color: getColorFromRisk() }}>
                    broken_image
                </i>
            </div>
            <div className="dependency-more-info text-center">
                <p>Details not available: {error}</p>
                {request_button}
            </div>
        </div>
    )
}
DependencyErrorBlock.propTypes = {
    analysis: PropTypes.object,
    distance: PropTypes.bool,
}

export { DependencyTab }
