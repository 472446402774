/**
 * This is the main entry point for Globe JS.
 *
 * This file sets up the Cesium scene, manages the map UI, and creates the results modal.
 *
 */
import _ from "lodash";

import {dataLoader, infoBox, viewSelector} from './toolbar';
import {UISettings} from './conf';
import {loadJSON, requestHelper, formatMoney} from './utils';
import appState from './CR-Components/CoreFiles/state'
import {openAnalysisModal} from './utils.js';
import {
    Ion,
    Cartographic,
    Cartesian3,
    GeoJsonDataSource,
    EntityCluster,
    CustomDataSource,
    Math as CesiumMath,
    ScreenSpaceEventType,
    ScreenSpaceEventHandler,
    Color,
    Rectangle,
    Fog,
    defined,
    VertexFormat,
    Camera,
    Viewer
} from "cesium/Cesium"

Ion.defaultAccessToken = CESIUM_ION_ACCESS_TOKEN;

const scene = {};

scene.viewSelector = viewSelector;

scene.viewer = new Viewer('cesiumContainer', {
    fullscreenButton: UISettings.FULLSCREEN_BUTTON,
    animation: UISettings.ANIMATIONS,
    vrButton: UISettings.VR_BUTTON,
    timeline: UISettings.TIMELINE,
    shadows: UISettings.SHADOWS,
    infoBox: UISettings.INFO_BOX,
    creditContainer: UISettings.CREDIT_CONTAINER,
    sceneModePicker: UISettings.SCENE_MODE_PICKER,

    // Decreases memory usage
    requestRenderMode: true,
    maximumRenderTimeChange: Infinity,

    // Further Decrease memory usage
    maximumScreenSpaceError : 4.0,
    preloadSiblings : true, // loads neighboring tiles
    navigationHelpButton : false,
    // terrainProvider: createWorldTerrain(),
});
scene.worker = new Worker("/static/js/worker.js"); // create web worker
scene.cartographic = new Cartographic();
scene.rectangle = new Rectangle();
scene.initScene = function () {
    scene.cameraHeightThreshold = UISettings.CAMERA_THRESHOLD;
    // viewer options
    scene.viewer.scene.globe.enableLighting = false;
    scene.viewer.scene.fog = new Fog({
        density: UISettings.FOG_DENSITY,
        screenSpaceErrorFactor: UISettings.FOG_ERROR_FACTOR,
        enabled: UISettings.FOG_STATUS
    });
    scene.worker.onmessage = this.onDataLoaded;

    // HTML overlay for showing feature name on mouse over
    scene.nameOverlay = document.createElement("span");
    scene.viewer.container.appendChild(scene.nameOverlay);
    scene.nameOverlay.className = "badge badge-light";
    scene.nameOverlay.style.display = "none";
    scene.nameOverlay.style.position = "absolute";
    scene.nameOverlay.style.bottom = "0";
    scene.nameOverlay.style.left = "0";
    scene.nameOverlay.style["pointer-events"] = "none";

    // mouse movement listener
    scene.handler = new ScreenSpaceEventHandler(
        scene.viewer.scene.canvas);
    scene.handler.setInputAction(
        scene.onSceneClick, ScreenSpaceEventType.LEFT_CLICK);
    scene.viewer.screenSpaceEventHandler.setInputAction(
        scene.onMouseMove, ScreenSpaceEventType.MOUSE_MOVE);
    // camera movement listener
    scene.viewer.camera.moveStart.addEventListener(scene.onCameraMoveStart);
    scene.viewer.camera.moveEnd.addEventListener(scene.onCameraMoveEnd);
    // scene.viewer.entities.add({
    //     name : 'Orange line with black outline at height and following the surface',
    //     polyline : {
    //         positions : Cartesian3.fromDegreesArrayHeights([-14, 32, 0,
    //                                                                -125, 39, 0]),
    //         width : 5,
    //         material : new PolylineOutlineMaterialProperty({
    //             color : Color.ORANGE,
    //             outlineWidth : 2,
    //             outlineColor : Color.BLACK
    //         })
    //     }
    // });
}

// Make this global so it doesnt need to be created and destroyed
scene.dataSource = new CustomDataSource();

scene.onClusterChange = function(entities, cluster) {
    if (entities) {
        const size = Math.max(16, Math.min(34, 16 + entities.length));
        const worst_risk = Math.max(...entities.map((e) => e._total_risk));
        const color = entity.percToMaterial(worst_risk);

        cluster.label.show = false;
        cluster.point.show = true;
        cluster.point.name = entities.length.toLocaleString() + " assets";
        cluster.point.outline = true;
        cluster.point.outlineColor = Color.BLACK;
        cluster.point.outlineWidth = 4;
        cluster.point.pixelSize = size;
        cluster.point.color = color;
        cluster.point.array = entities;
    }
}


scene.onMouseMove = function onMouseMove(movement) {
    var pickedFeature = scene.viewer.scene.pick(movement.endPosition);
    if (pickedFeature) {
        scene.nameOverlay.style.display = "block";
        scene.nameOverlay.style.bottom = scene.viewer.canvas.clientHeight - movement.endPosition.y + "px";
        scene.nameOverlay.style.left = movement.endPosition.x + "px";
        var text = "N/A";
        if (pickedFeature.primitive.name) {
            text = pickedFeature.primitive.name;
        }
        if (pickedFeature.id && pickedFeature.id._id) {
            text = pickedFeature.id._id;
        }
        if (pickedFeature.id && pickedFeature.id._name) {
            text = pickedFeature.id._name +"_"+ pickedFeature.id._address;
        }
        if (pickedFeature.id && pickedFeature.id.properties) {
            // get UI options
            var year = viewSelector.getYearSelectorValue();
            var yearName = viewSelector.getYearSelector();
            var hazard = viewSelector.getHazardSelectorValue();
            var hazardName = viewSelector.getHazardSelector();
            var kpi = viewSelector.getKPISelectorValue();
            var kpiName = viewSelector.getKPISelector();
            var color = viewSelector.getColorValue();
            var name = pickedFeature.id.properties.name._value;
            var numAssets = pickedFeature.id.properties.numAsset._value;
            var avgCost = formatMoney(pickedFeature.id.properties.averageRiskCost._value[0]);
            var totalRiskCost = formatMoney(pickedFeature.id.properties.totalRiskCost._value[0]);
            text = "<table class=\"table\">" +
                    "<tbody>" +
                    "<tr><td scope=\"row\">Area Name</td><td>" + name + "</td></tr>" +
                    "<tr><td scope=\"row\">Asset Count</td><td>" + numAssets + "</td></tr>" +
                    "<tr><td scope=\"row\">Risk Cost (Average) in 2100</td><td>" + avgCost + "</td></tr>" +
                    "<tr><td scope=\"row\">Risk Cost (Total) in 2100</td><td>" + totalRiskCost + "</td></tr>";

            if (color == "averageRiskHazards") {
                text = text + "<tr><td scope=\"row\">" + hazardName[hazard + 1].innerHTML + " in "+ yearName[year].innerHTML +"</td><td>" + (pickedFeature.id.properties._averageRiskHazards._value[year][hazard]*100).toFixed(2) + "%</td></tr>"
            }
            else if (color == "averageFailureHazards") {
                text = text + "<tr><td scope=\"row\">" + hazardName[hazard + 1].innerHTML + " in "+ yearName[year].innerHTML +"</td><td>" + (pickedFeature.id.properties._averageFailureHazards._value[year][hazard]*100).toFixed(2) + "%</td></tr>"
            }
            else if (color == "averageKPIs")  {
                text = text + "<tr><td scope=\"row\">" + kpiName[kpi + 1].innerHTML + " in "+ yearName[year].innerHTML +"</td><td>" + (pickedFeature.id.properties._averageKPIs._value[year][kpi]).toLocaleString(undefined, {maximumFractionDigits: 0}) + "</td></tr>"
            }

            text = text + "</tbody></table>"
        }
        scene.nameOverlay.innerHTML = text;
    } else {
        scene.nameOverlay.style.display = "none";
    }
}


scene.onCameraMoveStart = function () {
    clearTimeout(scene.timeout);
}
scene.onCameraMoveEnd = function () {
    if (viewSelector.getViewType() === "Point") {
        // update directly to provide faster interaction
        scene.onCameraMoved();
    } else {
        // set 1s delay if polygon view
        scene.timeout = setTimeout(scene.onCameraMoved, 1000);
    }
}

scene.onCameraMoved = function () {
    if (!(viewSelector.getViewType() === "Point" || viewSelector.getViewType() === "Polygon")) {
        infoBox.show("Select a view to load results in this area.");
        return;
    }

    scene.viewer.scene.mapProjection.ellipsoid.cartesianToCartographic(
        scene.viewer.scene.camera.positionWC, scene.cartographic);
    scene.currentCameraHeight = (scene.cartographic.height * 0.001).toFixed(1);

    if (scene.currentCameraHeight > scene.cameraHeightThreshold) {
        infoBox.show("Zoom in to load the results in this area.");
        scene.viewer.dataSources.remove(scene.dataSource);
        return
    }

    if (scene.viewer.scene.camera.pitch > UISettings.CAMERA_PITCH_THRESHOLD) {
        infoBox.show("Decrease the level of pitch.");
        return
    }

    infoBox.hide();
    var box = scene.viewer.camera.computeViewRectangle(
        scene.viewer.scene.globe.ellipsoid, scene.rectangle);
    scene.currentBounds = [
        parseFloat(CesiumMath.toDegrees(box.west).toFixed(6)), // minLng
        parseFloat(CesiumMath.toDegrees(box.east).toFixed(6)), // maxLng
        parseFloat(CesiumMath.toDegrees(box.south).toFixed(6)), // minLat
        parseFloat(CesiumMath.toDegrees(box.north).toFixed(6)), // maxLat
    ];
    scene.renderEntities();
}

/**
 * Takes array of cluster assets and zooms to extents
 *
 * @param {array} assetList - array of asset objects
 */
const zoomToExtents = (assetList) => {
    // const assets = _.cloneDeep(assetList)
    let assetCart = assetList.map((asset) => {
        return asset._position._value
    })
    // get rectangle that includes all assests in array
    const rectangle = Rectangle.fromCartesianArray(assetCart)
    // get camera location to view rectangle
    const cart3 = scene.viewer.scene.camera.getRectangleCameraCoordinates(rectangle)
    // get cartographic (lon, lat, height) from cartesian3
    const cart = Cartographic.fromCartesian(cart3)
    // add altitude to cartographic to zoom out from rectangle
    cart.height += 400
    // convert back to cart3 and flyto
    scene.viewer.scene.camera.flyTo({
        destination: Cartographic.toCartesian(cart)
    })
}

/**
 * Controls what happens when the map is clicked
 *
 * @param {object} event - click data
 */
scene.onSceneClick = function (event) {
    // check if the clicked item is an polygon
    var pickedObject = scene.viewer.scene.pick(event.position);

    // if the click is made on an entity
    if (defined(pickedObject) && viewSelector.getViewType() === "Point") {
        // if click is on a combined entity
        const assetList = pickedObject.primitive.array
        if (assetList) {
            // If assets in combine have multiple addresses
            if (assetList.some((indvAsset) => !(_.isEqual(indvAsset._position._value, assetList[0]._position._value)))) {
                zoomToExtents(assetList)
                // Open results modal if not multiple
            } else {
                appState.analysesList = assetList
                appState.showStackSelectModal = true
            }
        }
        if (pickedObject.id && (pickedObject.id._id || pickedObject.id.properties._id._value)) {
            var id = pickedObject.id._id;
            var collection = pickedObject.id._collection;
            if (pickedObject.id.properties) {
                id = pickedObject.id.properties._id._value;
            }
            if (id && collection) {
                openAnalysisModal(id)
            }
        }
    }
}


scene.onDataLoaded = function (event){
    scene.currentEntitiesCount = event.data.count;
    if (viewSelector.getViewType() === "Point") {
        scene.processPoints(event.data);
        dataLoader.hide();
    } else {
        scene.processPolygons(event.data);
    }

    scene.viewer.scene.requestRender();
}
scene.renderEntities = function () {
    if (dataLoader.status) {
        dataLoader.show();
        // load new geometries
        scene.worker.postMessage({
            key: API_KEY,
            collections: viewSelector.getCollectionList(),
            url: requestHelper.getRepoGeometryURL(),
            type: viewSelector.getViewType(),
            bounds: scene.currentBounds
        });
    }
}
scene.processPolygons = function (data) {
    GeoJsonDataSource.load(data)
        .then(process_polygons);
}

/**
 * return element in array only in parent
 *
 * @param {Array} otherArray - array to compare against
 * @returns {boolean} - if ID match return true
 */
function comparerNew(otherArray){
    return function(current){
        return otherArray.filter(function(other){
            return other.id == current._id
        }).length == 0;
    }
}

/**
 * return element in array only in parent
 *
 * @param {Array} otherArray - array to compare against
 * @returns {boolean} - if ID match return true
 */
function comparerOld(otherArray){
    return function(current){
        return otherArray.filter(function(other){
            return other._id == current.id
        }).length == 0;
    }
}

/**
 * Handles point clustering and adding and removing of points
 *
 * @param {object} data - request asset data
 */
scene.processPoints = function(data) {
    scene.dataSource.clustering = new EntityCluster({
        enabled: true,
        pixelRange: 15,
        minimumClusterSize: 3,
        clusterPoints: true
    });
    scene.dataSource.clustering.clusterEvent.addEventListener(scene.onClusterChange);

    // convert data.features to entity objects
    let newFeatures = data.features.map((asset) => {
        return entity.getFromFeature(asset)
    })

    // filters asset (entity) arrays
    const onlyInExisting = (scene.dataSource.entities.values).filter(comparerNew(newFeatures));
    const onlyInNew = newFeatures.filter(comparerOld(scene.dataSource.entities.values));

    // add new entities
    onlyInNew.forEach((newEntity) => {
        scene.dataSource.entities.add(newEntity)
    })

    // Remove out of frame entities
    onlyInExisting.forEach((remEntity) => {
        scene.dataSource.entities.removeById(remEntity._id)
    })

    // add new data source
    if (scene.viewer.dataSources.length === 0) {
        scene.viewer.dataSources.add(scene.dataSource);
    }
}

// TODO: work out what this does
/**
 *
 * @param {object} dataSource
 */
function process_polygons(dataSource) {
    // get UI options
    var year = viewSelector.getYearSelectorValue();
    var hazard = viewSelector.getHazardSelectorValue();
    var kpi = viewSelector.getKPISelectorValue();
    var extrude = "_" + viewSelector.getExtrudeValue();
    var color = "_" + viewSelector.getColorValue();
    var opacity = viewSelector.getOpacityValue();
    //Get the array of entities
    //100 divided by the maximum value (red) = * value
    //e.g. 100/1.5% = 6666
    var entities = dataSource.entities.values;
    for (var i = 0; i < entities.length; i++) {
        var value = entities[i].properties[color]._value[year] * 6666;
        if (color === "_proportionalTotalRisk") {
            value = entities[i].properties[color]._value[year] * 6666;
        }
        if (color === "_averageRiskHazards") {
            value = entities[i].properties[color]._value[year][hazard] * 6666;
        }
        if (color === "_averageFailureHazards") {
            value = entities[i].properties[color]._value[year][hazard] * 200;
        }
        if (color === "_averageKPIs") {
            value = entities[i].properties[color]._value[year][kpi] * 6.666;
        }
        var material = entity.percToMaterial(value, opacity);
        entities[i].polygon.material = material;
        entities[i].polygon.outlineColor = material;
        entities[i].vertexFormat = VertexFormat.POSITION_AND_COLOR;
        if (extrude !== "_2D") {
            var extrudeHeight = entities[i].properties[extrude]._value[year];
            if (extrudeHeight == 0) {
                extrudeHeight = 1.0
            }
            entities[i].polygon.extrudedHeight = extrudeHeight;
            entities[i].polygon.outline = false;
        }
    }
    // scene.viewer.dataSources.removeAll()
    removeLayers()
    // add new data source
    scene.dataSource = dataSource;

    // add new data source
    if (scene.viewer.dataSources.length === 0) {
        scene.viewer.dataSources.add(scene.dataSource);
    }
    dataLoader.hide();
}

/**
 * Clears all points and polygons from Cesium map
 */
const removeLayers = () =>{
    console.log(scene.viewer.dataSources)
    const dataSource = scene.viewer.dataSources.get(0)
    scene.viewer.dataSources.remove(dataSource)
}


/**
 * Map entity generator (points, boxes)
 */
var entity = {};

/**
 * Converts an analysis data point into a cesium map feature
 *
 * @param {object} feature - feature object
 *
 * @returns {object} - cesium rectangle
 */
entity.getFromFeature = function (feature) {
    var totalRisk = feature.properties.totalRiskCost[feature.properties.totalRiskCost.length - 1];
    const spec = {
        id: feature._id,
        name: feature.properties.name == undefined ? "NA" : feature.properties.name,
        address: feature.properties.address,
        collection: feature.meta.collection,
        vertexFormat: VertexFormat.POSITION_AND_COLOR,
        position: Cartesian3.fromDegrees(
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1]),
        // We add risk color at 2100(?) for cluster colouring (max)
        total_risk: (totalRisk / feature.properties.replacementCost) * 6666,
    }
    const material = entity.percToMaterial(
        (totalRisk / feature.properties.replacementCost) * 6666
    )


    if (scene.currentEntitiesCount <= UISettings.MAX_3D_ENTITIES &&
        scene.currentCameraHeight <= UISettings.THRESHOLD_3D_ENTITIES) {
        // Assets are represented as 3D objects when zoomed in.
        spec.box = {
            dimensions: new Cartesian3(12.0, 12.0, 20.0),
            material: material,
        };
        return spec
    } else {
        // Zoomed out, we use flat points.
        spec.point = {
            pixelSize: 14,
            outlineWidth: 1,
            outline: true,
            color: material,
        };
        return spec;
    }
}

// TODO: remove these two functions?
entity.getColor = function (feature) {
    return Color.DEEPSKYBLUE;
}

entity.getBox = function (feature) {
    return Color.DARKGREEN;
}

/**
 * Converts totalRisk to a color value for use on the map
 *
 * @param {number} totalRisk - total risk of asset (2050 typ)
 *
 * @returns {Array} - [r, g, b]
 */
const getRGB = function (totalRisk) {
    let r,
        g,
        b = 0
    // if "risk number sent" is less than 0.00001, below is 0.00001*6666.
    // then we go with pale yellow
    if (totalRisk < 0.0001) {
        r = 127
        g = 169
        b = 204
        // totalRisk of 0.01 represents 0.00001 risk fraction or 0.001% Risk
    } else {
        // when totalRisk = 100 g and b = 0 r = 255 if totalRisk is greater than 100 same result.
        // for totalRisk less then 100
        // we have a linear range on g and b
        r = 249 + totalRisk
        g = Math.round(255 - 2.55 * totalRisk)
        b = Math.round(124 - 1.24 * totalRisk)
        if (r > 255) r = 255
        if (g < 0) g = 0
        if (b < 0) b = 0
    }

    // returns RGB value
    return [r,g,b]
}

/**
 * Calulates Cesium usable color for points on map
 *
 * @param {number} totalRisk - total risk of asset (2050 typ)
 * @param {number} opacity - Opacity of color desired (0-1)
 * @returns {object} - Cesium ready color (rgba values of 0-1)
 */
entity.percToMaterial = function (totalRisk, opacity) {

    const RGBArrray = getRGB(totalRisk)

    let r = (RGBArrray[0] / 255);
    let g = (RGBArrray[1] / 255);
    let b = (RGBArrray[2] / 255);
    return new Color(r, g, b, opacity);
}


scene.initScene();


loadJSON(requestHelper.getOrgProjectURL(user_organisation), "GET", API_KEY, function (data) {
    setProject(data)
})

/**
 * Sets project appState and flies to project extents
 *
 * @param {string} data - current project name
 */
const setProject = function (data) {
    appState.current_project = data

    $.get({
        // Get extents for whole organisation
        url: requestHelper.getRepoExtentsURL(),
        headers: {"Authorization": API_KEY},
        success: (data) => {
            let [x0, x1, y0, y1] = data
            scene.viewer.camera.flyTo({
                destination: Rectangle.fromDegrees(x0, y0, x1, y1)
            });
        },
        error: (data) => {
            console.log("failed to get extents data: ", data)
        }
    });
}

/**
 * Sets project appState and flies to project extents
 *
 * @param {string} id - current project name
 */
scene.flyToExtents = function (id) {
    appState.current_project = id
    window.$.get({
        // Get extents for whole organisation
        url: requestHelper.getRepoExtentsURL(id),
        headers: {"Authorization": window.API_KEY},
        success: (data) => {
            let [x0, x1, y0, y1] = data

            // Ensure that we have -some- range
            if (x0 === x1 && y0 === y1) {
                x0 -= .001;
                x1 += .001;
                y0 -= .001;
                y1 += .001;
            }

            scene.viewer.camera.flyTo({
                destination: Rectangle.fromDegrees(x0, y0, x1, y1)
            });
        },
        error: (data) => {
            console.log("failed to get extents data: ", data)
        }
    });
}

Camera.DEFAULT_VIEW_RECTANGLE = Rectangle.fromDegrees(90.4555, -54.6570, 176.4938, 8.7097);
Camera.DEFAULT_VIEW_FACTOR = 0;

export { scene, getRGB, removeLayers }
